import React from "react";
import Lottie from 'react-lottie';
import NotFoundAnimation from '../Components/Lottie/NotFoundAnimation.json';
import { Link } from 'react-router-dom';
const MyLottieAnimation = () => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: NotFoundAnimation,
    };
  
    return (
      <Lottie options={defaultOptions} />
    );
  };
function NotFound() {
  return (
    <div className="bg-white flex flex-col lg:flex-row w-full h-full p-4 justify-center items-center">
      <div className='w-full h-[40vh] lg:h-[90vh] lg:w-[40%]'>
       <MyLottieAnimation/>
      </div>
      <div className="text-lg">
        <h2 className="text-xl font-bold my-6">I have bad news for you</h2>
        <p className="my-6 font-medium">
          The page you are looking for might be removed or is temporarily
          unavailable
        </p>
        <Link to='/products' className="text-center">
          <button className="rounded-xl w-full text-white bg-gray-600 md:w-2/5 px-4 py-2 shadow-md hover:text-blue-600 hover:bg-white transition duration-200 ease-in">
            back to homepage
          </button>
          </Link>
      </div>
    </div>
  );
}

export default NotFound;
