import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Lottie from "react-lottie";
import AddAvailableProduct from "../Components/Popups/AddAvailableProduct";
import NoDataFound from "../Components/Lottie/NoDataFound.json";
import { ArrowBack, ArrowForward, Edit } from "@mui/icons-material";
const AllAvailableProducts = ({
  products,
  currentUser,
  availableProducts,
  getAvailableProducts,
  notify,
  formatDate,
}) => {
  const perPage = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState(availableProducts);

  useEffect(() => {
    setData(availableProducts);
  }, [availableProducts]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };
  const MyLottieAnimation = () => {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: NoDataFound,
    };

    return <Lottie options={defaultOptions} />;
  };

  const offset = currentPage * perPage;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const currentPageData = data?.slice(offset, offset + perPage);

  return (
    <div className="relative flex flex-col h-full">
      <div className="flex flex-col sm:flex-row relative">
        <h1 className="font-bold sm:mb-8 text-xl">Available Products</h1>
        {currentUser?.RoleId === 1 && (
          <button
            onClick={() => {
              setSelectedProduct(null);
              setIsOpen(true);
            }}
            className="my-2 sm:my-0 max-w-[11rem] sm:absolute rounded-2xl right-0 flex text-white bg-blue-600 p-2 items-center justify-center shadow-md hover:text-blue-600 hover:bg-white transition duration-200 ease-in">
            <span>+ Available Product</span>
          </button>
        )}
      </div>
      {/* Table */}
      <div className="overflow-x-auto text-[17px]">
        <table className="w-full bg-white rounded border-collapse shadow-lg">
          <thead className="border-b-2 border-b-gray-200">
            <tr>
              <th
                className="w-1/3 text-gray-500 font-medium p-2"
                style={{ verticalAlign: "middle" }}>
                Product Name
              </th>
              <th
                className="w-1/3 text-gray-500 font-medium"
                style={{ verticalAlign: "middle" }}>
                Available Quantity
              </th>
              <th
                className="w-1/3 text-gray-500 font-medium p-2"
                style={{ verticalAlign: "middle" }}>
                Modified At
              </th>
              {currentUser?.RoleId === 1 && (
                <th className="p-2" style={{ verticalAlign: "middle" }}></th>
              )}
            </tr>
          </thead>
          <tbody>
            {currentPageData?.length > 0 ? (
              currentPageData?.map((product) => (
                <tr key={product.AvailableProductsId}>
                  <td className=" text-blue-500 underline py-2 pl-[8%]">
                    {
                      products?.find(
                        (prod) => prod?.ProductId === product?.ProductId
                      )?.ProductName
                    }{" "}
                    /{" "}
                    {
                      products?.find(
                        (prod) => prod?.ProductId === product?.ProductId
                      )?.ProductNameArabic
                    }
                  </td>
                  <td className="text-center py-2">{product.Quantity}</td>
                  <td className="text-center p-2">{product.ModifiedAt}</td>
                  {currentUser?.RoleId === 1 && (
                    <td className="text-center p-2">
                      <button
                        onClick={() => {
                          setSelectedProduct(product);
                          setIsOpen(true);
                        }}
                        className="text-left pr-2 text-blue-400 text-xs hover:cursor-pointer hover:text-blue-700">
                        <Edit />
                        Edit
                        {isOpen && (
                          <AddAvailableProduct
                            availableProducts={availableProducts}
                            currentUser={currentUser}
                            selectedProduct={selectedProduct}
                            getAvailableProducts={getAvailableProducts}
                            notify={notify}
                            products={products}
                            isOpen={isOpen}
                            formatDate={formatDate}
                            setIsOpen={setIsOpen}
                          />
                        )}
                      </button>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={3} className="text-center py-4">
                  <div className="h-28 pb-1">
                    <MyLottieAnimation />
                  </div>
                  <span>No products found</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* Pagination component */}
      <div className="flex justify-center flex-row">
        <ReactPaginate
          previousLabel={<ArrowBack className="p-1 -mt-1" />}
          nextLabel={<ArrowForward className="p-1 -mt-1" />}
          breakLabel={"..."}
          pageCount={Math.ceil(availableProducts?.length / perPage)}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName="flex flex-row my-6"
          activeLinkClassName="!bg-white text-blue-500 w-8 h-[1.7rem] flex justify-center items-center rounded-full p-2 mx-1 cursor-pointer"
          pageClassName={
            "bg-gray-500 text-white w-6 h-6 flex justify-center items-center rounded-full p-2 mx-1 cursor-pointer"
          }
          previousClassName={
            "bg-blue-500 text-white rounded-full w-6 h-6 flex justify-center items-center p-2 mx-1 cursor-pointer"
          }
          nextClassName={
            "bg-blue-500 text-white rounded-full w-6 h-6 flex justify-center items-center p-2 mx-1 cursor-pointer"
          }
          //breakClassName={'bg-gray-500 text-white rounded-full w-6 h-6 flex justify-center items-center p-2 mx-1 cursor-pointer'}
          disabledClassName={"opacity-50 cursor-not-allowed"}
        />
      </div>
      {isOpen && (
        <AddAvailableProduct
          availableProducts={availableProducts}
          currentUser={currentUser}
          selectedProduct={selectedProduct}
          getAvailableProducts={getAvailableProducts}
          notify={notify}
          products={products}
          isOpen={isOpen}
          formatDate={formatDate}
          setIsOpen={setIsOpen}
        />
      )}
    </div>
  );
};

export default AllAvailableProducts;
