import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import {
  ArrowBack,
  ArrowForward,
  Edit,
} from "@mui/icons-material";
import Lottie from 'react-lottie';
import NoDataFound from '../Components/Lottie/NoDataFound.json';
const Shipments = ({availableShipments, shippingCo, destination, currentUser }) => {
  const perPage = 5;
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage?.selected);
  };

  const offset = currentPage * perPage;
  const MyLottieAnimation = () => {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: NoDataFound,
    };
  
    return (
      <Lottie options={defaultOptions} />
    );
  };

  const [data, setData] = useState(availableShipments);
  useEffect (() => {
    setData(availableShipments);
  }, [availableShipments]);

  const currentPageData = data?.slice(offset, offset + perPage);
  return (
    <div className="relative flex flex-col h-full">
          <div className="flex flex-col sm:flex-row relative">
            <h1 className="font-bold sm:mb-8 text-xl">Shipments Available</h1>
            {
              currentUser?.RoleId === 1 && (
                <Link to="/add-shipment" state={{shipment: null}}>
                  <button className="my-2 sm:my-0 max-w-[14rem] sm:absolute rounded-2xl right-0 flex text-base text-white bg-blue-600 p-2 items-center justify-center shadow-md hover:text-blue-600 hover:bg-white transition duration-200 ease-in">
                    <span>+ Available Shipment</span>
                  </button>
                </Link>
              )
            }
            
          </div>
          {/* Table */}
          <div className="overflow-x-auto text-[17px]">
            <table className="w-full bg-white rounded border-collapse shadow-lg">
              <thead className="border-b-2 border-b-gray-200">
                <tr>
                  <th className={`text-gray-500 font-medium p-2 ${currentUser?.RoleId === 1 ? 'w-1/5' : 'w-1/4'}`} >Shipping Company</th>
                  <th className={`text-gray-500 font-medium p-2 ${currentUser?.RoleId === 1 ? 'w-1/5' : 'w-1/4'}`} >Vessel Name</th>
                  <th className={`text-gray-500 font-medium p-2 ${currentUser?.RoleId === 1 ? 'w-1/5' : 'w-1/4'}`} >Scheduled Date</th>
                  <th className={`text-gray-500 font-medium p-2 ${currentUser?.RoleId === 1 ? 'w-1/5' : 'w-1/4'}`} >
                    Destination
                  </th>
                  {
                    currentUser?.RoleId === 1 && (
                       <th className="w-1/5"></th>
                    )
                  }
                 
                </tr>
              </thead>
              <tbody>
                {
                  currentPageData?.length > 0 
                  ? currentPageData?.map((product) => (
                  <tr key={product.ShipmentsAvId}>
                    <td className="text-blue-500 underline py-2 pl-[6%] sm:pl-[8%]">
                      {shippingCo?.find((shipCo) => shipCo?.CompanyId === product?.CompanyId)?.CompanyName}
                    </td>
                    <td className="py-2 pl-[6.5%]">{product.VesselName}</td>
                    <td className="py-2 text-center">{product.ScheduleDate}</td>
                    <td className="py-2 pl-[6.5%]">{destination?.find((des) => des?.DestinationId === product?.Destination)?.Country}  /  {destination?.find((des) => des?.DestinationId === product?.Destination)?.Port}</td>
                    {
                      currentUser?.RoleId === 1 && (
                        <td className="text-left py-2 pr-1 sm:pl-[6%] lg:pl-[11%] text-blue-400 text-xs hover:cursor-pointer hover:text-blue-700"><Link to='/add-shipment' state={{ shipment: product }}><Edit className="h-4 w-4"/> Edit</Link></td>
                      )
                    }
                      </tr>
                ))
                : <td colSpan={4} className="text-center py-4">
            <div className="h-28 pb-1"><MyLottieAnimation /></div>
            <span>No shipments found</span>
          </td>}
              </tbody>
            </table>
          </div>
          {/* Pagination component */}
          <div className="flex justify-center flex-row">
            <ReactPaginate
              previousLabel={<ArrowBack className="p-1 -mt-1" />}
              nextLabel={<ArrowForward className="p-1 -mt-1" />}
              breakLabel={"..."}
              pageCount={Math.ceil(data?.length / perPage)}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName="flex flex-row my-6"
              activeLinkClassName="!bg-white text-blue-500 w-8 h-[1.7rem] flex justify-center items-center rounded-full p-2 mx-1 cursor-pointer"
              pageClassName={
                "bg-gray-500 text-white w-6 h-6 flex justify-center items-center rounded-full p-2 mx-1 cursor-pointer"
              }
              previousClassName={
                "bg-blue-500 text-white rounded-full w-6 h-6 flex justify-center items-center p-2 mx-1 cursor-pointer"
              }
              nextClassName={
                "bg-blue-500 text-white rounded-full w-6 h-6 flex justify-center items-center p-2 mx-1 cursor-pointer"
              }
              //breakClassName={'bg-gray-500 text-white rounded-full w-6 h-6 flex justify-center items-center p-2 mx-1 cursor-pointer'}
              disabledClassName={"opacity-50 cursor-not-allowed"}
            />
          </div>
        </div>
  );
};

export default Shipments;
