import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

import { ArrowBack, ArrowForward, Edit } from "@mui/icons-material";
import Lottie from "react-lottie";
import NoDataFound from "../Components/Lottie/NoDataFound.json";

const AllUsers = ({ getUsers, notify, users, roles, root, currentUser }) => {
  const perPage = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const offset = currentPage * perPage;
  const [data, setData] = useState(users);

  useEffect(() => {
    setData(users);
  }, [users]);

  const MyLottieAnimation = () => {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: NoDataFound,
    };

    return <Lottie options={defaultOptions} />;
  };

  const currentPageData = data?.slice(offset, offset + perPage);
  return (
    <div className="relative flex flex-col h-full">
      <div className="flex flex-col sm:flex-row relative sm:items-center">
        <h1 className="font-bold sm:mb-10 text-xl">Users</h1>
        {
          currentUser?.RoleId === 1 && (
            <Link to="/add-user" state={{ user: null }}>
              <button className="sm:absolute rounded-2xl my-2 sm:my-0 sm:right-0 sm:top-0 max-w-[5rem] flex text-white bg-blue-600 p-2 items-center justify-center shadow-md hover:text-blue-600 hover:bg-white transition duration-200 ease-in">
                <span>+ User</span>
              </button>
            </Link>
          )
        }
        
      </div>
      {/* Table */}
      <div className="overflow-x-auto text-[17px]">
        <table className="w-full bg-white rounded border-collapse shadow-lg">
          <thead className="border-b-2 border-b-gray-200 hidden sm:table-header-group">
            <tr>
              <th
                className={`${currentUser?.RoleId === 1 ? 'w-1/5' : 'w-1/4'} text-gray-500 font-medium p-2`}
                style={{ verticalAlign: "middle" }}>
                Name
              </th>
              <th
                className={`${currentUser?.RoleId === 1 ? 'w-1/5' : 'w-1/4'} text-gray-500 font-medium p-2`}
                style={{ verticalAlign: "middle" }}>
                Email
              </th>
              <th
                className={`${currentUser?.RoleId === 1 ? 'w-1/5' : 'w-1/4'} text-gray-500 font-medium p-2`}
                style={{ verticalAlign: "middle" }}>
                Access
              </th>
              <th
                className={`${currentUser?.RoleId === 1 ? 'w-1/5' : 'w-1/4'} text-gray-500 font-medium p-2`}
                style={{ verticalAlign: "middle" }}>
                Status
              </th>
              <th className={`${currentUser?.RoleId === 1 ? 'w-1/5' : 'w-1/4'} p-2 `}></th>
            </tr>
          </thead>
          <tbody>
            {currentPageData?.length > 0 ? (
              currentPageData?.map((user) => (
                <tr key={user.UserId} className="border-b">
                  <td className={`${currentUser?.RoleId === 1 ? 'sm:w-1/5' : 'sm:w-1/4'} py-2 block sm:table-cell`}>
                    <div className="relative flex flex-col pl-[3%] md:pl-[12%] sm:flex-row md:items-center">
                    <div className="w-10 h-10 mx-2 rounded-full bg-blue-100 flex items-center justify-center">{user?.FirstName?.substring(0,1)}{user?.LastName?.substring(0,1)}</div>
                    {" "}
                    <span>{user?.FirstName}{" "}{user?.LastName}</span>
                    {
                      currentUser?.RoleId === 1 && (
                        <Link
                            className="absolute sm:hidden text-blue-400 text-xs right-3 top-3 hover:cursor-pointer hover:text-blue-700"
                          to="/add-user"
                          state={{ user: user }}>
                          <Edit className="h-4 w-4" /> Edit
                        </Link>
                      )
                    }
                    
                    </div>
                  </td>
                  <td className={`${currentUser?.RoleId === 1 ? 'w-1/5' : 'w-1/4'} py-2 pl-[3%] lg:pl-[4%] block sm:table-cell`}>{user?.Email}</td>
                  <td className={`${currentUser?.RoleId === 1 ? 'w-1/5' : 'w-1/4'} py-2 pl-[3%] md:pl-[7.4%] block sm:table-cell`}>{roles?.find((role)=>role?.RoleId === user?.RoleId)?.RoleName}</td>
                  <td className={`${currentUser?.RoleId === 1 ? 'w-1/5' : 'w-1/4'} py-2 pl-[3%] md:pl-[6.4%] block sm:table-cell`}>{user?.StatusId === 1 ? (
                <div className="flex w-24 text-green-700 bg-green-100 rounded-full items-center justify-center">
                  <div className="bg-green-500 rounded-full w-2 h-2"></div> <span className="ml-1.5">Active</span>
                </div>
              ) : (
                <div className="flex w-24 text-red-700 bg-red-100 rounded-full items-center justify-center">
                  <div className="bg-red-500 rounded-full w-2 h-2"></div> <span className="ml-1.5 text-center">Inactive</span>
                </div>
              )}</td>
                  {
                    currentUser?.RoleId === 1 && (
                      <td className="w-1/5 relative text-left py-2 text-blue-400 text-xs block sm:table-cell">
                        <Link
                            className="hidden sm:block absolute sm:right-3 md:right-12 bottom-3 hover:cursor-pointer hover:text-blue-700"
                          to="/add-user"
                          state={{ user: user }}>
                          <Edit className="h-4 w-4" /> Edit
                        </Link>
                      </td>    
                    )
                  }
                  
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="text-center py-4">
                  <div className="h-28 pb-1">
                    <MyLottieAnimation />
                  </div>
                  <span>No users found</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* Pagination component */}
      <div className="flex justify-center flex-row">
        <ReactPaginate
          previousLabel={<ArrowBack className="p-1 -mt-1" />}
          nextLabel={<ArrowForward className="p-1 -mt-1" />}
          breakLabel={"..."}
          pageCount={Math.ceil(data?.length / perPage)}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName="flex flex-row my-6"
          activeLinkClassName="!bg-white text-blue-500 w-8 h-[1.7rem] flex justify-center items-center rounded-full p-2 mx-1 cursor-pointer"
          pageClassName={
            "bg-gray-500 text-white w-6 h-6 flex justify-center items-center rounded-full p-2 mx-1 cursor-pointer"
          }
          previousClassName={
            "bg-blue-500 text-white rounded-full w-6 h-6 flex justify-center items-center p-2 mx-1 cursor-pointer"
          }
          nextClassName={
            "bg-blue-500 text-white rounded-full w-6 h-6 flex justify-center items-center p-2 mx-1 cursor-pointer"
          }
          //breakClassName={'bg-gray-500 text-white rounded-full w-6 h-6 flex justify-center items-center p-2 mx-1 cursor-pointer'}
          disabledClassName={"opacity-50 cursor-not-allowed"}
        />
      </div>
    </div>
  );
};

export default AllUsers;