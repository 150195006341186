import React, { useEffect, useState, useRef } from "react";
import bgImage from "../assets/images/bgImage.avif";
import { RemoveRedEyeOutlined, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
export default function Login({
  setToken,
  root,
  setIsLoadingParent,
  setCurrentUser,
}) {
  const [email, setEmail] = useState("");
  const [forgotPassEmail, setForgotPassEmail] = useState("");
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [pass, setPass] = useState("");
  const [isPassFocused, setIsPassFocused] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [pinNo, setPinNo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [forgotPass, setForgotPass] = useState(false);
  const [enterPin, setEnterPin] = useState(false);
  const [enterRecoveryMail, setEnterRecoveryMail] = useState(false);
  const [newPass, setNewPass] = useState("");
  const [enterNewPass, setEnterNewPass] = useState(false);
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState("");
  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  useEffect(() => {
    // Check if the email input has a value
    if (emailRef.current) {
      if (emailRef.current.value !== "") {
        handleInputEmailBlur();
        handleInputEmailFocus();
      }
    }

    // Check if the password input has a value
    if (passwordRef.current) {
      if (passwordRef.current.value !== "") {
        handleInputPassBlur();
        handleInputPassFocus();
      }
    }
  }, [email, pass, passwordRef, emailRef]);

  const handleSubmit = () => {
    setIsLoading(true);
    setErrMsg("");
    if (email === "" || pass === "") {
      setErrMsg("All fields are required");
      setIsLoading(false);
    } else {
      // Encrypt the password using SHA256
      const encryptedPassword = CryptoJS.SHA256(pass).toString();
      var formData = {
        email: email,
        password: encryptedPassword,
      };

      axios
        .post(`${root}/auth/signIn`, formData)
        .then((response) => {
          const formData = {
            UserId: response?.data?.user?.UserId,
          };
          // generate user token
          axios
            .post(`${root}/auth/token`, formData)
            .then((response) => {
              setIsLoading(false);
              setCurrentUser(response.data?.user);
              Cookies.set("token", response.data?.token, {
                expires: 1, // 1 day expiration
                path: "/", // Set the path to '/' to make the cookie available to the entire site
                secure: true, // Set the secure flag to true to only send the cookie over HTTPS
              });
              // console.log(
              //   "process.env.REACT_APP_SECRET_KEY",
              //   process.env.REACT_APP_SECRET_KEY
              // );
              Cookies.set(
                "uid",
                CryptoJS.AES.encrypt(
                  response.data?.user?.UserId.toString(),
                  process.env.REACT_APP_SECRET_KEY
                ).toString(),
                {
                  expires: 1, // 1 day expiration
                  path: "/", // Set the path to '/' to make the cookie available to the entire site
                  secure: true, // Set the secure flag to true to only send the cookie over HTTPS
                }
              );
              setToken(response.data?.token);
              setIsLoading(false);
              navigate("/products");
            })
            .catch((error) => {
              setErrMsg(`${error?.response?.data?.error}`);
              setIsLoading(false);
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
          setErrMsg(`${error?.response?.data?.error}`);
          setIsLoading(false);
        });
    }
  };

  function sendResetPassEmail() {
    setIsLoading(true);
    // should send forgotPassEmail
    var pin = Math.floor(100000 + Math.random() * 900000).toString();
    var data = {
      email: forgotPassEmail,
      pinNo: pin,
    };
    axios
      .post(`${root}/auth/change-pin`, data)
      .then((response) => {
        var formData = {
          subject: "Password Reset",
          body: `Enter this pin number in order to reset your password: ${pin}`,
          receivers: [
            "mira13.mc@gmail.com, mirac@nicgroup.co,",
          ],
          host: process.env.REACT_APP_HOST,
          port: process.env.REACT_APP_PORT,
          user: process.env.REACT_APP_EMAIL_ADDRESS,
          password: process.env.REACT_APP_PASSWORD,
        };
        axios
          .post(`${root}/auth/send-email`, formData)
          .then((response) => {
            setEnterRecoveryMail(false);
            setEnterPin(true);
          })
          .catch((error) => {
            console.log(error.response);
          });
      })
      .catch((error) => {
        console.log(error.response);
      });
    setIsLoading(false);
  }

  function checkPin() {
    setIsLoading(true);
    var formData = {
      email: forgotPassEmail,
      pinNo: pinNo,
    };
    axios
      .post(`${root}/auth/check-pin`, formData)
      .then((response) => {
        setEnterPin(false);
        setEnterNewPass(true);
      })
      .catch((error) => {
        console.log(error.response);
        setErrMsg(`${error?.response?.data?.error}`);
      });
    setIsLoading(false);
  }

  function changeUserPass() {
    setIsLoading(true);
    const encryptedPassword = CryptoJS.SHA256(newPass).toString();
    var formData = {
      email: forgotPassEmail,
      newPassword: encryptedPassword,
    };
    axios
      .post(`${root}/auth/change-pass`, formData)
      .then((response) => {
        setEnterNewPass(false);
        setForgotPass(false);
      })
      .catch((error) => {
        console.log(error.response);
        setErrMsg(`${error?.response?.data?.error}`);
      });
    setIsLoading(false);
  }

  const handleInputEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleInputEmailFocus = () => {
    setIsEmailFocused(true);
  };

  const handleInputEmailBlur = () => {
    setIsEmailFocused(false);
  };
  const handleInputPassChange = (e) => {
    setPass(e.target.value);
  };

  const handleInputPassFocus = () => {
    setIsPassFocused(true);
  };

  const handleInputPassBlur = () => {
    setIsPassFocused(false);
  };

  const handleClickSubmitBtn = () => {
    setErrMsg("");
    if (enterRecoveryMail) {
      // should send forgotPassEmail
      if(forgotPassEmail === "" || forgotPassEmail === null){
        setErrMsg("Please Enter Email");
      }else{
        setErrMsg("");
        sendResetPassEmail();
      }
      
    }
    if (enterPin) {
      // should send forgotPassEmail
      if(pinNo === "" || pinNo === null){
        setErrMsg("Please Enter Pin");
      }else{
        setErrMsg("");
        checkPin();
      }
    }
    if (enterNewPass) {
      // should send forgotPassEmail
      if(newPass === "" || newPass === null){
        setErrMsg("Please Enter Password");
      }else{
        setErrMsg("");
        changeUserPass();
      }
    }
  };

  const makeAnimationStartHandler = (stateSetter) => (e) => {
    const autofilled = !!e.target?.matches("*:-webkit-autofill");
    if (e.animationName === "mui-auto-fill") {
      stateSetter(autofilled);
    }

    if (e.animationName === "mui-auto-fill-cancel") {
      stateSetter(autofilled);
    }
  };

  useEffect(() => {
    const handleAutocomplete = (e) => {
      if (e.target.hasAttribute('autocompleted')) {
        setIsEmailFocused(true);
        setIsPassFocused(true);
        e.preventDefault(); // prevent autocomplete
      } else {
        setIsEmailFocused(false);
        setIsPassFocused(false);
      }
    };

    document.addEventListener('onautocomplete', handleAutocomplete);

    return () => {
      document.removeEventListener('onautocomplete', handleAutocomplete);
    };
  }, []);

  return (
    <div className="w-screen h-screen">
      <div
        className="w-screen h-screen bg-center"
        style={{ backgroundImage: `url(${bgImage})` }}>
        <div
          className=" w-full h-full flex items-center justify-center"
          style={{
            backdropFilter: "blur(4px)",
            WebkitBackdropFilter: "blur(4px)",
          }}>
          <div
            className="w-4/5 md:w-[50%] lg:w-[30%] z-20 bg-[#7d7668] rounded-2xl shadow-2xl duration-500 ease-in-out py-6 px-10 xl:px-18"
            style={{
              transform: forgotPass ? "rotateY(180deg)" : "rotateY(0deg)",
            }}>
            {forgotPass ? (
              <div
                className="h-[40vh] flex flex-col justify-between py-2"
                style={{ transform: "rotateY(180deg)" }}>
                <span className="text-white text-xl">
                  Forgot your Password ?
                </span>
                {enterRecoveryMail && (
                  <RecoveryEmail setForgotPassEmail={setForgotPassEmail} errMsg={errMsg} />
                )}
                {enterPin && <PinInput setPinNo={setPinNo} errMsg={errMsg} />}
                {enterNewPass && (
                  <ForgotPassword setNewPass={setNewPass} errMsg={errMsg} />
                )}
                <div className="flex justify-end">
                  <button
                    className="rounded-2xl mr-2 w-full text-white bg-blue-600 md:w-2/5 px-4 py-2 shadow-md hover:text-blue-600 hover:bg-white transition duration-200 ease-in"
                    onClick={() => {
                      setForgotPass(false);
                      setEnterRecoveryMail(false);
                      setEnterPin(false);
                      setEnterNewPass(false);
                      setErrMsg("");
                    }}>
                    Cancel
                  </button>

                  <button
                    disabled={isLoading}
                    onClick={() => {
                      handleClickSubmitBtn();
                    }}
                    className="rounded-2xl w-full text-white bg-blue-600 md:w-2/5 px-4 py-2 shadow-md hover:text-blue-600 hover:bg-white transition duration-200 ease-in">
                    {isLoading ? (
                      <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status">
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                          Loading...
                        </span>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </div>
            ) : (
              <>
                <span className="text-white my-6">Log in to your account</span>
                {/* Inputs */}
                <div className="flex flex-col mt-8">
                  <div className="relative flex flex-col">
                    <input
                      type="email"
                      id={"email"}
                      onChange={handleInputEmailChange}
                      autoComplete="on"
                      autoFocus={true}
                      onFocus={handleInputEmailFocus}
                      required={true}
                      onAnimationStart={() =>
                        makeAnimationStartHandler(setIsEmailFocused)
                      }
                      name="email"
                      ref={emailRef}
                      //onBlur={handleInputEmailBlur}
                      className={`w-full text-white px-3 py-2 bg-transparent border-b-2 focus:outline-none ${
                        isEmailFocused ? "border-gray-100" : "border-gray-300"
                      }`}
                      placeholder={email ? email : ""}
                    />
                    <label
                      className={`absolute left-3 transition-all pointer-events-none ${
                        isEmailFocused || email 
                          ? "-top-2 text-xs text-gray-600"
                          : "top-2  text-white text-base"
                      }`}>
                      Email address <span className="text-red-500">*</span>
                    </label>
                  </div>

                  <div className="relative my-4 flex flex-col">
                    <input
                      id="password"
                      name="password"
                      type={showPass ? "text" : "password"}
                      onChange={handleInputPassChange}
                      onFocus={handleInputPassFocus}
                      autoComplete="on"
                      autoFocus={true}
                      onAnimationStart={() =>
                        makeAnimationStartHandler(setIsPassFocused)
                      }
                      ref={passwordRef}
                      onBlur={handleInputPassBlur}
                      required={true}
                      className={`relative w-full px-3 text-white py-2 bg-transparent border-b-2 focus:outline-none ${
                        isPassFocused ? "border-gray-100" : "border-gray-300"
                      }`}
                      placeholder={pass ? pass : ""}
                    />
                    {showPass ? (
                      <button onClick={() => setShowPass(false)}>
                        <RemoveRedEyeOutlined className="absolute right-3 top-3 w-4 h-4 text-gray-300" />
                      </button>
                    ) : (
                      <button onClick={() => setShowPass(true)}>
                        <VisibilityOff className="absolute right-3 top-3 w-4 h-4 text-gray-300" />
                      </button>
                    )}
                    <label
                      className={`absolute left-3 transition-all pointer-events-none ${
                        isPassFocused || pass
                          ? "-top-2 text-xs text-gray-600"
                          : "top-2 text-white text-base"
                      }`}>
                      Password <span className="text-red-500">*</span>
                    </label>
                  </div>

                  <div className="flex flex-col xl:flex-row text-base justify-between xl:items-center pt-1 pb-6">
                    <div>
                      {errMsg !== "" && (
                        <p className="text-orange-900 font-bold text-base">
                          {errMsg} !
                        </p>
                      )}
                    </div>
                    <span
                      onClick={() => {
                        setEnterRecoveryMail(true);
                        setForgotPass(true);
                      }}
                      className="italic hover:cursor-pointer hover:text-blue-600">
                      forgot password?
                    </span>
                  </div>
                  <button
                    disabled={isLoading}
                    onClick={() => handleSubmit()}
                    className="rounded-2xl w-full text-white bg-blue-600 md:w-2/5 px-4 py-2 shadow-md hover:text-blue-600 hover:bg-white transition duration-200 ease-in">
                    {isLoading ? (
                      <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status">
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                          Loading...
                        </span>
                      </div>
                    ) : (
                      "Sign In"
                    )}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export function ForgotPassword({ setNewPass, errMsg }) {
  return (
    <>
    <span className=" italic mt-2 text-gray-300">
        Enter the new password
      </span>
      <label className="text-white" htmlFor="forgotEmail">
        Enter Password <span className="text-red-500">*</span>
      </label>
      <input
        type="text"
        id={"forgotEmail"}
        onChange={(e) => setNewPass(e.target.value)}
        required={true}
        className={`w-full -mt-4 text-white px-3 py-2 bg-transparent border-b-2 focus:outline-none border-gray-100
                        }`}
        placeholder="Enter new password"
      />
      {errMsg !== "" && (
        <span className="text-red-900 italic font-semibold -mt-2">{errMsg}</span>
      )}
    </>
  );
}

export function PinInput({ setPinNo, errMsg }) {
  return (
    <>
      <span className=" italic mt-2 text-gray-300">
        Enter the pin sent to your email
      </span>
      <label className="text-white" htmlFor="forgotEmail">
        Enter Pin <span className="text-red-500">*</span>
      </label>
      <input
        type="text"
        id={"forgotEmail"}
        onChange={(e) => setPinNo(e.target.value)}
        required={true}
        className={`w-full --mt-4 text-white px-3 py-2 bg-transparent border-b-2 focus:outline-none border-gray-100
                        }`}
        placeholder="Enter pin"
      />
      {errMsg !== "" && (
        <span className="text-red-900 italic font-semibold -mt-2">{errMsg}</span>
      )}
    </>
  );
}

export function RecoveryEmail({ setForgotPassEmail, errMsg }) {
  return (
    <>
      <span className=" italic mt-2 text-gray-300">
        Enter your email address so we'll send you a recovery pin
      </span>
      <label className="text-white" htmlFor="forgotEmail">
        Email <span className="text-red-500">*</span>
      </label>
      <input
        type="email"
        id={"forgotEmail"}
        onChange={(e) => setForgotPassEmail(e.target.value)}
        required
        className={`w-full -mt-4 px-3 py-2 bg-transparent border-b-2 focus:outline-none border-gray-100
        text-white placeholder:text-gray-100 placeholder:italic `}
        placeholder="Email to send recovery pin"
      />
      <span className="text-red-900 italic font-semibold -mt-2">{errMsg}</span>
    </>
  );
}
