import React, { useEffect, useState, useRef } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import {
  CheckCircle,
  CancelRounded,
  ArrowBack,
  ArrowForward,
  FileDownload,
  FileUpload,
  Edit,
} from "@mui/icons-material";
import axios from "axios";
import Lottie from "react-lottie";
import NoDataFound from "../Components/Lottie/NoDataFound.json";
const OrderHistory = ({
  salesOrders,
  products,
  currentUser,
  root,
  notify,
  getSaleOrders,
  containerTypes,
  customers,
  destination,
}) => {
  const perPage = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const [data, setData] = useState(salesOrders);
  const [isLoading, setIsLoading] = useState(false);
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const offset = currentPage * perPage;
  const fileInputRef = useRef(null);
  useEffect(() => {
    setData(salesOrders);
  }, [salesOrders]);

  const currentPageData = data?.slice(offset, offset + perPage);
  const MyLottieAnimation = () => {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: NoDataFound,
    };

    return <Lottie options={defaultOptions} />;
  };

  const downloadFile = async () => {
    try {
      const response = await axios.get(
        `${root}/salesOrders/download-template`,
        {
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });

      const downloadUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "order-template.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const handleUploadFile = async (e) => {
    setIsLoading(true);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    if (file) {
      axios
        .post(`${root}/salesOrders/handle-upload-order`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          getSaleOrders();
          notify("Added order successfully", 1);
          setIsLoading(false);
        })
        .catch((error) => {
          notify("Error adding order", 2);
          console.log(error);
          setIsLoading(false);
        });
    } else {
      notify("No file selected", 3);
    }
  };
  return (
    <div className="relative flex flex-col h-full">
      <div className="flex relative flex-col md:flex-row">
        <h1 className="font-bold md:mb-10 text-xl">Sales Orders</h1>
        <input
          type="file"
          className="hidden"
          ref={fileInputRef}
          onChange={handleUploadFile}
        />
        {currentUser?.RoleId === 1 && (
          <div className="md:absolute md:right-0 flex flex-col md:flex-row my-2">
            <button
              onClick={() => fileInputRef.current.click()}
              className="rounded-2xl w-44 flex text-white bg-green-800 p-2 items-center justify-center shadow-md hover:bg-opacity-50 transition duration-200 ease-in">
              <div className="text-white w-1 h-1 flex items-center justify-center ml-2 mr-3">
                <FileUpload className="p-1" />
              </div>
              <span>Upload Excel</span>
            </button>
            <button
              onClick={() => downloadFile()}
              className="my-2 md:my-0 md:mx-4 w-44 rounded-2xl flex text-white bg-gray-600 p-2 items-center justify-center shadow-md hover:bg-opacity-50 transition duration-200 ease-in">
              <div className="text-white w-1 h-1 flex items-center justify-center ml-2 mr-3">
                <FileDownload className="p-1" />
              </div>
              <span>Excel template</span>
            </button>
            <Link to="/add-order" state={{ order: null }}>
              <button className="rounded-2xl w-44 flex text-white bg-blue-600 p-2 items-center justify-center shadow-md hover:text-blue-600 hover:bg-white transition duration-200 ease-in">
                <span>+ Order</span>
              </button>
            </Link>
          </div>
        )}
      </div>
      {/* Table */}
      <div className="overflow-x-auto text-[17px]">
        <table className="w-full bg-white rounded border-collapse shadow-lg">
          <thead className="border-b-2 border-b-gray-200 px-2">
            <tr>
              <th
                className={`text-gray-500 font-medium p-2 ${
                  currentUser?.RoleId === 1 ? "w-[1/11]" : "w-[1/11]"
                }`}
                style={{ verticalAlign: "middle" }}>
                Order No
              </th>
              <th
                className={`text-gray-500 font-medium p-2 ${
                  currentUser?.RoleId === 1 ? "w-[1/11]" : "w-[1/11]"
                }`}
                style={{ verticalAlign: "middle" }}>
                Product
              </th>
              <th
                className={`text-gray-500 font-medium p-2 ${
                  currentUser?.RoleId === 1 ? "w-[1/11]" : "w-[1/11]"
                }`}
                style={{ verticalAlign: "middle" }}>
                Product Quantity
              </th>
              <th
                className={`text-gray-500 font-medium p-2 ${
                  currentUser?.RoleId === 1 ? "w-[1/11]" : "w-[1/11]"
                }`}
                style={{ verticalAlign: "middle" }}>
                Quantity Left
              </th>
              <th
                className={`text-gray-500 font-medium p-2 ${
                  currentUser?.RoleId === 1 ? "w-[1/11]" : "w-[1/11]"
                }`}
                style={{ verticalAlign: "middle" }}>
                Customer Name
              </th>
              <th
                className={`text-gray-500 font-medium p-2 ${
                  currentUser?.RoleId === 1 ? "w-[1/11]" : "w-[1/11]"
                }`}
                style={{ verticalAlign: "middle" }}>
                Order Date
              </th>
              <th
                className={`text-gray-500 font-medium p-2 ${
                  currentUser?.RoleId === 1 ? "w-[1/11]" : "w-[1/11]"
                }`}
                style={{ verticalAlign: "middle" }}>
                Price
              </th>
              <th
                className={`text-gray-500 font-medium p-2 ${
                  currentUser?.RoleId === 1 ? "w-[1/11]" : "w-[1/11]"
                }`}
                style={{ verticalAlign: "middle" }}>
                Destination
              </th>
              <th
                className={`text-gray-500 font-medium p-2 ${
                  currentUser?.RoleId === 1 ? "w-[1/11]" : "w-[1/11]"
                }`}
                style={{ verticalAlign: "middle" }}>
                Container Type
              </th>
              <th
                className={`text-gray-500 font-medium p-2 ${
                  currentUser?.RoleId === 1 ? "w-[1/11]" : "w-[1/11]"
                }`}
                style={{ verticalAlign: "middle" }}>
                Order Status
              </th>
              {currentUser?.RoleId === 1 && <th className="py"></th>}
            </tr>
          </thead>
          <tbody className="px-2">
            {currentPageData?.length > 0 ? (
              currentPageData?.map((product) => (
                <tr key={product.id}>
                  <td className="pl-2 text-center text-blue-500 underline">
                    SO{product.OrderNo}
                  </td>
                  <td className="py-2 pl-3 md:pl-6 lg:pl-10">
                    {
                      products?.find(
                        (prod) => prod?.ProductId === product?.ProductId
                      )?.ProductName
                    }
                  </td>
                  <td className="py-2 pl-8">{product.Quantity} kg</td>
                  <td className="py-2 pl-8">{product.QuantityLeft} kg</td>
                  <td className="p-2 pl-4">
                    {
                      customers?.find(
                        (customer) =>
                          customer?.CustomerId === product?.CustomerId
                      )?.CustomerName
                    }
                  </td>
                  <td className="py-2 pl-3">{product.OrderDate}</td>
                  <td className="py-2 pl-3">{product.Price ? product.Price : 'N/A'}</td>
                  <td className="py-2 pl-[3%]">
                    <div>
                      {
                        destination?.find(
                          (des) => des?.DestinationId === product?.Destination
                        )?.Country
                      }
                    </div>
                    <div>
                      {
                        destination?.find(
                          (des) => des?.DestinationId === product?.Destination
                        )?.Port
                      }
                    </div>
                  </td>
                  <td className="py-2 pl-[1%]">
                    {
                      containerTypes?.find(
                        (price) =>
                          price?.ContainerTypeId === product?.ContainerType
                      )?.ContainerTypeName
                    }
                  </td>
                  <td className="py-2 pr-4">
                    {product.OrderStatusId === 2 ? (
                      <div className="flex items-center py-1 justify-center">
                        <CheckCircle className="mr-2 text-green-500" />{" "}
                        <span className="w-[40%] text-center">Shipped</span>
                      </div>
                    ) : (
                      <div className="flex items-center py-1 justify-center">
                        <CancelRounded className="mr-2 text-red-500" />{" "}
                        <span className="w-[40%] text-center">Pending</span>
                      </div>
                    )}
                  </td>
                  {currentUser?.RoleId === 1 && (
                    <td className="text-left py-2 pr-2 text-blue-400 text-xs hover:cursor-pointer hover:text-blue-700">
                      <Link to="/add-order" state={{ order: product }}>
                        <Edit className="h-4 w-4" /> Edit
                      </Link>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={11} className="text-center py-4">
                  <div className="h-28 pb-1">
                    <MyLottieAnimation />
                  </div>
                  <span>No orders found</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* Pagination component */}
      <div className="flex justify-center flex-row">
        <ReactPaginate
          previousLabel={<ArrowBack className="p-1 -mt-1" />}
          nextLabel={<ArrowForward className="p-1 -mt-1" />}
          breakLabel={"..."}
          pageCount={Math.ceil(data?.length / perPage)}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName="flex flex-row my-6"
          activeLinkClassName="!bg-white text-blue-500 w-8 h-[1.7rem] flex justify-center items-center rounded-full p-2 mx-1 cursor-pointer"
          pageClassName={
            "bg-gray-500 text-white w-6 h-6 flex justify-center items-center rounded-full p-2 mx-1 cursor-pointer"
          }
          previousClassName={
            "bg-blue-500 text-white rounded-full w-6 h-6 flex justify-center items-center p-2 mx-1 cursor-pointer"
          }
          nextClassName={
            "bg-blue-500 text-white rounded-full w-6 h-6 flex justify-center items-center p-2 mx-1 cursor-pointer"
          }
          //breakClassName={'bg-gray-500 text-white rounded-full w-6 h-6 flex justify-center items-center p-2 mx-1 cursor-pointer'}
          disabledClassName={"opacity-50 cursor-not-allowed"}
        />
      </div>
    </div>
  );
};

export default OrderHistory;
