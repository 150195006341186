import React, { useState } from "react";
import { ArrowBack } from "@mui/icons-material";
const ReadyContainers = ({
  products,
  salesOrders,
  customers,
  shippingCo,
  shipmentPrices,
  containerTypes,
  setReadyContainers,
  readyToShip,
}) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedShippingOption, setSelectedShippingOption] = useState(null);
  const [filteredOrders, setFilteredOrders] = useState(salesOrders);
  const [formData, setFormData] = useState({});

  const handleProductClick = (product) => {
    setSelectedProduct(product);
    setFormData({
      ...formData,
      ProductId: product?.productId,
    });
    const selectedProductOrders = salesOrders?.filter(
      (order) =>
        order?.ProductId === product?.productId &&
        order?.Quantity <= product?.quantity
    );
    setFilteredOrders(selectedProductOrders);
  };

  const handleOrderSelect = (order) => {
    setSelectedOrder(order);
    setFormData({
      ...formData,
      OrderId: order?.OrderId,
      Quantity: order?.Quantity,
      CustomerId: order?.CustomerId,
    });
  };

  const handleShippingOptionSelect = (shippingOption) => {
    setSelectedShippingOption(shippingOption);
    setFormData({
      ...formData,
      ShippingCoId: shippingOption?.CustomerId,
      Destination: shipmentPrices?.find(
        (shipPrice) => shipPrice?.CustomerId === shippingOption?.CustomerId
      )?.Destination,
      Price: shipmentPrices?.find(
        (shipPrice) => shipPrice?.CustomerId === shippingOption?.CustomerId
      )?.Price,
    });
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [currentOrderPage, setCurrentOrderPage] = useState(1);
  const [currentShippingCoPage, setCurrentShippingCo] = useState(1);
  const [ordersPerPage] = useState(3);
  const [shippingCoPerPage] = useState(3);
  const [productsPerPage] = useState(8);

  // Calculate the index of the last product on the current page
  const indexOfLastProduct = currentPage * productsPerPage;
  // Calculate the index of the first product on the current page
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  // Get the products on the current page
  const currentProducts = products?.slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  // Pagination logic
  const totalPages = Math.ceil(products?.length / productsPerPage);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate the index of the last order on the current page
  const indexOfLastOrder = currentOrderPage * ordersPerPage;
  // Calculate the index of the first order on the current page
  const indexOfFirstOrder = indexOfLastOrder - ordersPerPage;
  // Get the products on the current page
  const currentOrders = filteredOrders?.slice(
    indexOfFirstOrder,
    indexOfLastOrder
  );

  // Pagination logic
  const totalOrdersPages = Math.ceil(filteredOrders?.length / ordersPerPage);
  const handleOrderPageChange = (pageNumber) => {
    setCurrentOrderPage(pageNumber);
  };

  // Calculate the index of the last shipping co on the current page
  const indexOfLastShippingCo = currentShippingCoPage * shippingCoPerPage;
  // Calculate the index of the first shipping co on the current page
  const indexOfFirstShippingCo = indexOfLastShippingCo - shippingCoPerPage;
  // Get the products on the current page
  const currentShippingCos = shippingCo?.slice(
    indexOfFirstShippingCo,
    indexOfLastShippingCo
  );

  // Pagination logic
  const totalShippingCoPages = Math.ceil(
    shippingCo?.length / shippingCoPerPage
  );
  const handleShippingCoPageChange = (pageNumber) => {
    setCurrentShippingCo(pageNumber);
  };

  const placeOrder = () => {
    //setReadyToShip([...readyToShip, formData]);
    window.history.back();
  }

  return (
    <div className="bg-gray-100 py-6 rounded-lg">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <button
          className="text-gray-600 hover:text-blue-500 mb-4"
          onClick={() => {
            if(selectedProduct!=null){
                setFormData({});
                setSelectedProduct(null);
            }else{
                window.history.back();
            }
            
          }}>
          <ArrowBack className="mr-2" />
          Back
        </button>
        {/* Product Categories */}
        {selectedProduct ? (
          <div className="mt-8">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">
              Orders that Match{" "}
              <span className="text-blue-600">
                {selectedProduct?.productName}
              </span>
            </h2>
            <div className="bg-white shadow-md rounded-lg p-6">
              {currentOrders?.map((order) => (
                <div
                  key={order.OrderId}
                  className="border-b border-gray-200 py-4 hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleOrderSelect(order)}>
                  <p className="text-gray-800 font-bold">
                    Order #{order.OrderNo} - {order.Quantity} units
                  </p>
                  <p className="text-gray-600 line-clamp-2">
                    Destination: {order.Destination}
                  </p>
                  <p className="text-gray-600 line-clamp-2">
                    Customer:{" "}
                    {
                      customers?.find(
                        (customer) => customer?.CustomerId === order?.CustomerId
                      )?.CustomerName
                    }
                  </p>
                </div>
              ))}
              <div className="flex justify-center mt-8">
                <nav>
                  <ul className="inline-flex items-center -space-x-px">
                    {Array.from(
                      { length: totalOrdersPages },
                      (_, index) => index + 1
                    ).map((pageNumber) => (
                      <li key={pageNumber}>
                        <button
                          className={`px-3 py-2 leading-tight border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ${
                            currentOrderPage === pageNumber
                              ? "bg-blue-500 text-white"
                              : "bg-white text-gray-500"
                          }`}
                          onClick={() => handleOrderPageChange(pageNumber)}>
                          {pageNumber}
                        </button>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            </div>
            {selectedOrder && (
              <div className="mt-8">
                <h2 className="text-2xl font-bold text-gray-800 mb-4">
                  Shipping Options for Order #{selectedOrder?.OrderNo}
                </h2>
                <div className="bg-white shadow-md rounded-lg p-6">
                  {currentShippingCos?.map((option) => (
                    <div
                      key={option.CompanyId}
                      className="border-b border-gray-200 py-4 hover:bg-gray-100 cursor-pointer"
                      onClick={() => handleShippingOptionSelect(option)}>
                      <p className="text-gray-800 font-bold">
                        {option.CustomerName}
                      </p>
                      <p className="text-gray-600">
                        Price: $
                        {
                          shipmentPrices?.find(
                            (shipPrice) =>
                              shipPrice?.CustomerId === option?.CustomerId
                          )?.Price
                        }{" "}
                        | Destination:{" "}
                        {
                          shipmentPrices?.find(
                            (shipPrice) =>
                              shipPrice?.CustomerId === option?.CustomerId
                          )?.Destination
                        }
                      </p>
                    </div>
                  ))}
                  <div className="flex justify-center mt-8">
                    <nav>
                      <ul className="inline-flex items-center -space-x-px">
                        {Array.from(
                          { length: totalShippingCoPages },
                          (_, index) => index + 1
                        ).map((pageNumber) => (
                          <li key={pageNumber}>
                            <button
                              className={`px-3 py-2 leading-tight border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ${
                                currentShippingCoPage === pageNumber
                                  ? "bg-blue-500 text-white"
                                  : "bg-white text-gray-500"
                              }`}
                              onClick={() =>
                                handleShippingCoPageChange(pageNumber)
                              }>
                              {pageNumber}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                </div>
                {selectedShippingOption && (
                  <div className="mt-8">
                    <h2 className="text-2xl font-bold text-gray-800 mb-4">
                      Order Summary
                    </h2>
                    <div className="bg-white shadow-md rounded-lg p-6">
                      <p className="text-gray-800 font-bold">
                        Product: {selectedProduct.productName}
                      </p>
                      <p className="text-gray-600">
                        Order Quantity: {selectedOrder.Quantity}
                      </p>
                      <p className="text-gray-600">
                        Shipping Provider: {selectedShippingOption.CustomerName}
                      </p>
                      <p className="text-gray-600">
                        Shipping Cost: $
                        {
                          shipmentPrices?.find(
                            (shipPrice) =>
                              shipPrice?.CompanyId ===
                              selectedShippingOption?.CompanyId
                          )?.Price
                        }
                      </p>
                      <p className="text-gray-600">
                        Destination:{" "}
                        {
                          shipmentPrices?.find(
                            (shipPrice) =>
                              shipPrice?.CustomerId ===
                              selectedShippingOption?.CustomerId
                          )?.Destination
                        }
                      </p>
                      <button onClick={placeOrder} className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg mt-4">
                        Place Order
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        ) : (
          <div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
              {currentProducts.map((product) => (
                <div
                  key={product.readyContainerId}
                  className="bg-white shadow-md rounded-lg p-6 cursor-pointer"
                  onClick={() => handleProductClick(product)}>
                  <h3 className="text-xl font-bold text-gray-800 mb-2">
                    {product.ProductName}
                  </h3>
                  <div className="flex items-center mb-4">
                    <span className="text-gray-600 font-semibold">
                      Quantity
                    </span>
                    <span className="text-white rounded-full px-2 py-1 ml-2 bg-green-500">
                      {product.Quantity}
                    </span>
                  </div>
                  <p className="text-gray-600 line-clamp-2">
                    {product.Description}
                  </p>
                  <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg mt-4">
                    Select Product
                  </button>
                </div>
              ))}
            </div>
            <div className="flex justify-center mt-8">
              <nav>
                <ul className="inline-flex items-center -space-x-px">
                  {Array.from(
                    { length: totalPages },
                    (_, index) => index + 1
                  ).map((pageNumber) => (
                    <li key={pageNumber}>
                      <button
                        className={`px-3 py-2 leading-tight border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ${
                          currentPage === pageNumber
                            ? "bg-blue-500 text-white"
                            : "bg-white text-gray-500"
                        }`}
                        onClick={() => handlePageChange(pageNumber)}>
                        {pageNumber}
                      </button>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReadyContainers;
