/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import CustomizeReadyContainer from "../Components/Popups/CustomizeReadyContainer";
import AddReadyContainer from "../Components/Popups/AddReadyContainer";
import axios from "axios";
import Lottie from "react-lottie";
import NoDataFound from "../Components/Lottie/NoDataFound.json";
import LoadingTruck from "../Components/Lottie/LoadingTruck.json";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

export default function ReadyContainersPart2({
  products,
  salesOrders,
  notify,
  currentUser,
  getAvailableProducts,
  formatDate,
  sendEmail,
  key,
  customers,
  destination,
  getReadyToShip,
  shippingCo,
  shipmentPrices,
  updateReadyContainers,
  containerTypes,
  availableShipments,
  readyContainers,
  getSaleOrders,
  setReadyContainers,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentContainerIndex, setCurrentContainerIndex] = useState(null);
  const [isSelected, setIsSelected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProd, setSelectedProd] = useState(false);
  const [selectedShip, setSelectedShip] = useState(false);
  const [data, setData] = useState(readyContainers);
  const MyLottieAnimation = () => {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: NoDataFound,
    };

    return <Lottie options={defaultOptions} />;
  };
  const LottieLoading = () => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: LoadingTruck,
    };

    return <Lottie options={defaultOptions} />;
  };
  useEffect(() => {
    setData(readyContainers);
  }, [readyContainers]);

  const [customOp, setCustomOp] = useState({
    Destination: "",
    ContainerType: "",
    Quantity: "",
  });

  useEffect(() => {
    updateReadyContainers();
  }, []);

  function generateRandomOrderNumber() {
    return Math.floor(100000 + Math.random() * 900000).toString();
  }

  const handleSubmitPopup = (
    destination,
    containerType,
    quantity,
    customerId
  ) => {
    var obj = {
      Destination: destination,
      ContainerType: containerType,
      Quantity: quantity,
    };
    setCustomOp(obj);
    const filteredShipments = availableShipments
      ?.filter((shipment) => shipment.Destination === destination)
      ?.map((shipment) => {
        const price = shipmentPrices?.find(
          (price) => price.Destination === shipment.Destination
        )?.Price;
        return {
          ...shipment,
          Price: price || "",
        };
      })
      .sort(
        (a, b) =>
          new Date(b.ScheduleDate) - new Date(a.ScheduleDate) ||
          a.Price - b.Price
      )
      .slice(0, 2);
    if (filteredShipments.length === 0) {
      notify("No shipment available for this destination", 3);
    } else {
      var customOrder = {
        OrderId: null,
        OrderNo: generateRandomOrderNumber(),
        OrderDate: new Date().toISOString().split("T")[0],
        CustomerId: customerId ?? null,
        ProductId: selectedProd?.ProductId,
        Quantity: quantity,
        Destination: destination,
        Price: "",
        ContainerType: containerType,
        AddedBy: currentUser?.UserId,
        AddedAt: formatDate(),
        OrderStatusId: 1,
      };

      setIsLoading(true);
      axios
        .post(`${root}/salesOrders/add`, customOrder)
        .then((response) => {
          getSaleOrders();
          updateReadyContainers();
          notify("Added order successfully", 1);
          setIsLoading(false);
        })
        .catch((error) => {
          notify("Error adding order", 2);
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  const [tooltipContent, setTooltipContent] = useState("N/A");

  const handleTooltip = (customerId) => {
    const customer = customers.find((cust) => cust?.CustomerId === customerId);
    const customerName = customer ? customer.CustomerName : "N/A";
    setTooltipContent(customerName);
  };
  const root = window.location.href.includes("localhost")
    ? "http://localhost:8451"
    : "https://api.ubstrade.com";

  const handleValidateOrders = async (quantity, orderNo, containerNo) => {
    setIsLoading(true);
    const date = new Date();
    const selectedOrder = selectedProd?.salesOrders?.find(
      (order) => order?.OrderId === selectedShip?.OrderId
    );
    var validatedOrderObj = {
      ValidatedOrderId: null,
      ValidateOrderNo: orderNo,
      ContainerTypeId: selectedOrder?.ContainerType,
      ContainerNb: null,
      OrderId: selectedOrder?.OrderId,
      ShipmentId: selectedShip?.ShipmentsAvId,
      ProductId: selectedProd?.ProductId,
      Quantity: quantity,
      ValidationDate: date.toISOString().split("T")[0],
      ValidatedBy: currentUser?.UserId,
      Destination: selectedShip?.Destination,
      CustomerId: customers?.find(
        (c) => c.CustomerId === selectedOrder?.CustomerId
      )?.CustomerId,
      ShipPriceId: shipmentPrices?.find(
        (comp) => comp.ShipmentId === selectedShip?.ShipmentType
      )?.ShipmentId,
      StatusId: 1,
    };

    if (Number(containerNo) > 0) {
      try {
        const promises = [];
        let ogQuantity = Number(quantity);
        for (let i = 0; i < Number(containerNo); i++) {
          let currentQuantity = Math.floor(ogQuantity / Number(containerNo));
          if (i < ogQuantity % Number(containerNo)) {
            currentQuantity++;
          }
          ogQuantity -= currentQuantity;
          validatedOrderObj = {
            ...validatedOrderObj,
            Quantity: currentQuantity.toString(),
          };
          promises.push(
            axios.post(`${root}/readyContainers/AddEdit`, validatedOrderObj, {
              headers: { "Content-Type": "application/json" },
            })
          );
        }
        await Promise.all(promises);
        var emailData = {
          subject: "Ready Container Validated",
          body: `${containerNo} ready containers have been validated successfully`,
          receivers: [
            "mira13.mc@gmail.com, mirac@nicgroup.co, Mariamk@nicgroup.co",
          ],
          host: process.env.REACT_APP_HOST,
          port: process.env.REACT_APP_PORT,
          user: process.env.REACT_APP_EMAIL_ADDRESS,
          password: process.env.REACT_APP_PASSWORD,
        };
        sendEmail(emailData);
        notify("Order validated successfully", 1);
        updateReadyContainers();
        getSaleOrders();
        getAvailableProducts();
        getReadyToShip();
      } catch (error) {
        console.error(error);
        notify("Error validating order", 2);
      } finally {
        setIsLoading(false);
        setIsSelected(false);
      }
    } else {
      notify("Quantity must be greater than zero", 3);
    }
  };

  return (
    <div className="overflow-x-auto" key={key}>
      {isLoading ? (
        <div className="flex flex-col text-lg font-semibold items-center justify-center w-full h-full px-6">
          <div className="h-[20%] w-[25vw] mt-10">
            <LottieLoading />
          </div>
          <span className="-mt-4">Generating ready containers ...</span>
        </div>
      ) : (
        <div className="border-[1.5px] border-gray-700 rounded-xl border-separate">
          <table className="hidden lg:table rounded-xl w-full bg-white shadow-lg overflow-y-scroll max-h-[70vh]">
            <thead className="border-b-[2.5px] border-b-gray-600 ">
              <tr>
                <th
                  className="p-2 w-[18%] border-tl-[0.1px] rounded-tl-xl border-r-2 text-gray-600 text-lg font-semibold border-r-gray-600"
                  style={{ verticalAlign: "middle" }}>
                  Products
                </th>
                <th className=" text-gray-500 font-medium w-[52%] border-r-2 border-r-gray-600">
                  <div className="flex flex-col">
                    <span
                      className="border-b-2 flex items-center justify-center text-lg border-b-gray-600 py-2 text-gray-600 font-semibold"
                      style={{ verticalAlign: "middle" }}>
                      Sales Orders
                    </span>
                    <div className="grid grid-cols-7">
                      <span
                        className="w-[1/7] flex items-center justify-center  min-h-14"
                        style={{ verticalAlign: "middle" }}>
                        Order No
                      </span>
                      <span
                        className="w-[1/7] flex items-center justify-center  min-h-14"
                        style={{ verticalAlign: "middle" }}>
                        Customer
                      </span>
                      <span
                        className="w-[1/7] flex items-center justify-center min-h-14"
                        style={{ verticalAlign: "middle" }}>
                        Quantity Left
                      </span>
                      <span
                        className="w-[1/7] flex items-center justify-center min-h-14"
                        style={{ verticalAlign: "middle" }}>
                        Order Date
                      </span>
                      <span
                        className="w-[1/7] flex items-center justify-center min-h-14"
                        style={{ verticalAlign: "middle" }}>
                        Destination
                      </span>
                      <div
                        className="w-[1/7] flex items-center justify-center min-h-14"
                        style={{ verticalAlign: "middle" }}>
                        <span>Price</span>
                      </div>
                      <span
                        className="w-[1/7] flex items-center justify-center min-h-14"
                        style={{ verticalAlign: "middle" }}>
                        Container Type
                      </span>
                    </div>
                  </div>
                </th>
                <th className=" text-gray-500 font-medium w-[1-0.18-(0.52)] border-tr-[0.1px] rounded-tr-xl">
                  <div className="flex flex-col">
                    <span className="border-b-2 border-tr-[0.1px] rounded-tr-xl border-b-gray-600 py-2 text-lg text-gray-600 font-semibold">
                      Available Shipments
                    </span>
                    <div className="flex">
                      <span
                        className="w-1/4 flex items-center justify-center min-h-14"
                        style={{ verticalAlign: "middle" }}>
                        Shipping Company
                      </span>
                      <span
                        className="w-1/4 flex items-center justify-center min-h-14"
                        style={{ verticalAlign: "middle" }}>
                        Schedule Date
                      </span>
                      <span
                        className="w-1/4 min-h-14 flex items-center justify-center"
                        style={{ verticalAlign: "middle" }}>
                        Price
                      </span>
                      <span
                        className="w-1/4 min-h-14 flex items-center justify-center"
                        style={{ verticalAlign: "middle" }}></span>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {data?.length > 0 ? (
                data?.map((container, index) => {
                  return (
                    <tr
                      key={index}
                      className={` ${index === data?.length - 1 ? "border-b-[1.5px] border-b-gray-300 rounded-b-xl" : "border-b-[2.5px] border-b-gray-600"} text-[17px]`}>
                      <td className="pl-6 border-r-2 border-r-gray-600">
                        <div className="flex flex-col gap-2 my-1.5">
                          <span>
                            <span className="text-gray-500">Name:</span>{" "}
                            {
                              products?.find(
                                (prod) =>
                                  prod?.ProductId === container?.ProductId
                              )?.ProductName
                            }{" "}
                            -
                            {
                              products?.find(
                                (prod) =>
                                  prod?.ProductId === container?.ProductId
                              )?.ProductNameArabic
                            }
                          </span>
                          <span>
                            <span className="text-gray-500">Quantity: </span>
                            {container?.Quantity}
                          </span>
                          <button
                            onClick={() => {
                              setSelectedProd(container);
                              setIsOpen(true);
                              setCurrentContainerIndex(index);
                            }}
                            className="flex items-center justify-center w-24 self-start py-1 rounded-xl bg-blue-500 text-white shadow-md hover:text-blue-600 hover:bg-white transition duration-200 ease-in">
                            Customize
                            {isOpen && currentContainerIndex === index && (
                              <CustomizeReadyContainer
                                notify={notify}
                                salesOrders={salesOrders}
                                customers={customers}
                                containerTypes={containerTypes}
                                destinationList={destination}
                                containerQuantity={container?.Quantity}
                                handleSubmitPopup={handleSubmitPopup}
                                isOpen={isOpen}
                                setIsOpen={setIsOpen}
                              />
                            )}
                          </button>
                        </div>
                      </td>
                      <td className="border-r-2 border-r-gray-600 max-h-full">
                        {container?.salesOrders?.length > 0 ? (
                          container?.salesOrders?.map((order, index) => (
                            <div
                              className={` ${
                                index === container?.salesOrders?.length - 1
                                  ? "border-b-0"
                                  : "border-b-[1.75px]"
                              } border-b-gray-500 ${index % 2 === 0 ? "bg-white" : "bg-blue-50"} ${container?.salesOrders?.length === 1 ? "min-h-[143px] max-h-[143px]" : "min-h-[90px] max-h-[90px]"}`}>
                              <div className={`grid grid-cols-7 ${container?.salesOrders?.length === 1 ? "min-h-[143px] max-h-[143px]" : "min-h-[90px] max-h-[90px]"}`}>
                                <div className="w-[1/7] border-r-[1.5px] border-r-gray-300 p-2 flex items-center justify-center">
                                  SO{order?.OrderNo}
                                </div>
                                <div className="w-[1/7] border-r-[1.5px] border-r-gray-300 p-2 flex items-center justify-center">
                                  <span
                                    className="cuttoff-text"
                                    data-tooltip-id="customerName"
                                    onMouseEnter={() => handleTooltip(order?.CustomerId)}
                                    >
                                    {order?.CustomerId != null
                                      ? customers?.find(
                                          (cust) =>
                                            cust?.CustomerId ===
                                            order?.CustomerId
                                        )?.CustomerName
                                      : "N/A"}
                                  </span>
                                  <ReactTooltip
                                    id="customerName"
                                    place="bottom"
                                    variant="info"
                                    content={tooltipContent}
                                  />
                                </div>
                                <div className="w-[1/7] border-r-[1.5px] border-r-gray-300 p-2 flex items-center justify-center">
                                  {order?.QuantityLeft}
                                </div>
                                <div className="w-[1/7] border-r-[1.5px] border-r-gray-300 p-2 flex items-center justify-center">
                                  {order?.OrderDate}
                                </div>
                                <div className="w-[1/7] border-r-[1.5px] border-r-gray-300 p-2 flex flex-col justify-center">
                                  <span>
                                    {
                                      destination?.find(
                                        (des) =>
                                          des?.DestinationId ===
                                          order?.Destination
                                      )?.Country
                                    }
                                  </span>
                                  <span className="">
                                    {
                                      destination?.find(
                                        (des) =>
                                          des?.DestinationId ===
                                          order?.Destination
                                      )?.Port
                                    }
                                  </span>
                                </div>
                                <div className="w-[1/7] border-r-[1.5px] border-r-gray-300 p-2 flex items-center justify-center">
                                  {order?.Price !== "" ? order?.Price : "N/A"} $
                                </div>
                                <div className="w-[1/7] ml-3 flex items-center justify-center">
                                  {
                                    containerTypes?.find(
                                      (type) =>
                                        type?.ContainerTypeId ===
                                        order?.ContainerType
                                    )?.ContainerTypeName
                                  }
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div className="py-1.5 min-h-14 flex items-center justify-center">
                            No matching orders
                          </div>
                        )}
                      </td>
                      <td className="flex flex-col">
                        {container?.filteredShipments?.length > 0 ? (
                          container?.filteredShipments
                            .map((shipment, index) =>
                              shipment?.ShipmentsAvId != null ? (
                                <div
                                  className={`${
                                    index ===
                                    container?.filteredShipments?.length - 1
                                      ? index % 2 !== 0  ? "border-b-2 border-b-gray-600"
                                      : "border-b-2 border-b-gray-300 border-dashed"
                                      
                                      : index % 2 !== 0
                                      ? "border-b-2 border-b-gray-600"
                                      : "border-b-2 border-b-gray-300 border-dashed"
                                  }
                                  ${Math.floor(index / 2) % 2 === 0 ? "bg-white" : "bg-blue-50"}
                                  flex items-start justify-start w-full ${container?.salesOrders?.length === 1 ? "min-h-[72px] max-h-[72px]" : "min-h-[45px] max-h-[45px]"}`}
                                  key={index}>
                                  <span className="w-1/4 min-h-[2.7rem] flex items-center justify-center">
                                    {
                                      shippingCo?.find(
                                        (cust) =>
                                          cust?.CompanyId ===
                                          shipment?.CompanyId
                                      )?.CompanyName
                                    }
                                  </span>
                                  <span className="w-1/4 min-h-[2.7rem] flex items-center justify-center">
                                    {shipment?.ScheduleDate}
                                  </span>
                                  <span className="w-1/4 min-h-[2.7rem] flex items-center justify-center">
                                    {shipment?.Price === ""
                                      ? "N/A"
                                      : shipment?.Price}
                                    $
                                  </span>
                                  <div className="flex min-h-[2.7rem] items-center">
                                    <button
                                      onClick={() => {
                                        setSelectedProd(container);
                                        setSelectedShip(shipment);
                                        setCurrentContainerIndex(index);
                                        setIsSelected(true);
                                      }}
                                      className="px-3 py-1 rounded-xl bg-blue-500 text-white shadow-md hover:text-blue-600 hover:bg-white transition duration-200 ease-in">
                                      Select
                                      {isSelected &&
                                        currentContainerIndex === index && (
                                          <AddReadyContainer
                                            notify={notify}
                                            products={products}
                                            containerQuantity={
                                              container?.Quantity
                                            }
                                            quantity={container?.Quantity}
                                            customOp={customOp}
                                            destinations={destination}
                                            customers={customers}
                                            shippingCompanies={shippingCo}
                                            shipmentPrices={shipmentPrices}
                                            containers={containerTypes}
                                            selectedShip={selectedShip}
                                            selectedProd={selectedProd}
                                            isOpen={isSelected}
                                            handleValidateOrders={
                                              handleValidateOrders
                                            }
                                            setIsOpen={setIsSelected}
                                          />
                                        )}
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  className={`${
                                    index ===
                                    container?.filteredShipments?.length - 1
                                      ? "border-b-0"
                                      : index % 2 !== 0
                                      ? "border-b-2 border-b-gray-600 border-solid"
                                      : "border-b-2 border-b-gray-300 border-dashed"
                                  }
                                  ${Math.floor(index / 2) % 2 === 0 ? "bg-white" : "bg-blue-50"}
                                   flex items-center ${
                                     container?.salesOrders?.length === 1
                                     ? (index > 0 &&
                                       container?.filteredShipments[index - 1]
                                         ?.OrderId ===
                                         container?.filteredShipments[index]
                                           ?.OrderId &&
                                       container?.filteredShipments[index - 1]
                                         ?.ShipmentsAvId !== null &&
                                       container?.filteredShipments[index]
                                         ?.ShipmentsAvId !== null) ||
                                     (index <
                                       container?.filteredShipments?.length -
                                         1 &&
                                       container?.filteredShipments[index]
                                         ?.OrderId ===
                                         container?.filteredShipments[
                                           index + 1
                                         ]?.OrderId &&
                                       container?.filteredShipments[index]
                                         ?.ShipmentsAvId !== null &&
                                       container?.filteredShipments[index + 1]
                                         ?.ShipmentsAvId !== null)
                                       ? "min-h-[72px]"
                                       : "min-h-[72px]"
                                     : (index > 0 &&
                                       container?.filteredShipments[index - 1]
                                         ?.OrderId ===
                                         container?.filteredShipments[index]
                                           ?.OrderId &&
                                       container?.filteredShipments[index - 1]
                                         ?.ShipmentsAvId !== null &&
                                       container?.filteredShipments[index]
                                         ?.ShipmentsAvId !== null) ||
                                     (index <
                                       container?.filteredShipments?.length -
                                         1 &&
                                       container?.filteredShipments[index]
                                         ?.OrderId ===
                                         container?.filteredShipments[
                                           index + 1
                                         ]?.OrderId &&
                                       container?.filteredShipments[index]
                                         ?.ShipmentsAvId !== null &&
                                       container?.filteredShipments[index + 1]
                                         ?.ShipmentsAvId !== null)
                                       ? "min-h-[2.81rem]"
                                       : "min-h-[2.95rem]"
                                   } justify-center w-full`}
                                  key={index}></div>
                              )
                            )
                        ) : (
                          <div className={`px-6 py-14 flex !items-center !justify-center ${container?.salesOrders?.length === 1 ? "min-h-[72px] max-h-[72px]" : "min-h-[45px] max-h-[45px]"}`}>
                            No matching shipping company
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <td colSpan={3} className="text-center py-4">
                  <div className="h-28 pb-1">
                    <MyLottieAnimation />
                  </div>
                  <span>No ready containers</span>
                </td>
              )}
            </tbody>
          </table>
          <div className="lg:hidden w-full bg-white rounded border-collapse shadow-lg">
            {data?.length > 0 ? (
              data?.map((container, index) => (
                <div
                  key={index}
                  className={`border-b-2 border-b-gray-600 p-4 ${
                    index === data?.length - 1 ? "" : "border-b"
                  }`}>
                  <div className="flex justify-between items-center mb-2">
                    <div className="flex flex-col">
                      <span className="font-medium">
                        Product Name:{" "}
                        {
                          products?.find(
                            (prod) => prod?.ProductId === container?.ProductId
                          )?.ProductName
                        }{" "}
                        -
                        {
                          products?.find(
                            (prod) => prod?.ProductId === container?.ProductId
                          )?.ProductNameArabic
                        }
                      </span>
                      <span className="text-gray-500 mt-2">
                        Quantity:{" "}
                        <span className="font-bold">{container?.Quantity}</span>
                      </span>
                      <button
                        onClick={() => {
                          setSelectedProd(container);
                          setIsOpen(true);
                          setCurrentContainerIndex(index);
                        }}
                        className="flex items-center justify-center my-2 py-1 w-24 rounded-xl bg-blue-500 text-white shadow-md hover:text-blue-600 hover:bg-white transition duration-200 ease-in">
                        Customize
                        {isOpen && currentContainerIndex === index && (
                          <CustomizeReadyContainer
                            notify={notify}
                            salesOrders={salesOrders}
                            customers={customers}
                            containerQuantity={container?.Quantity}
                            containerTypes={containerTypes}
                            destinationList={destination}
                            handleSubmitPopup={handleSubmitPopup}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                          />
                        )}
                      </button>
                    </div>
                  </div>

                  <div className="mb-2">
                    <div className="text-blue-500 font-medium mb-1 underline underline-offset-4">
                      Sales Orders
                    </div>
                    {container?.salesOrders?.length > 0 ? (
                      container?.salesOrders?.map((order, orderIndex) => (
                        <div
                          key={orderIndex}
                          className="flex flex-col justify-center mb-2">
                          <span className="mr-2 ">
                            <span className="text-gray-500">Customer:</span>{" "}
                            {order?.CustomerId != null
                              ? customers?.find(
                                  (cust) =>
                                    cust?.CustomerId === order?.CustomerId
                                )?.CustomerName
                              : "N/A"}
                          </span>
                          <span>
                            <span className="text-gray-500">Quantity Left:</span>{" "}
                            {order?.QuantityLeft}
                          </span>
                          <span className="mr-2">
                            <span className="text-gray-500">Date:</span>{" "}
                            {order?.OrderDate}
                          </span>
                          <div>
                            <span>
                              <span className="text-gray-500">
                                Destination:
                              </span>{" "}
                              {
                                destination?.find(
                                  (des) =>
                                    des?.DestinationId === order?.Destination
                                )?.Country
                              }
                            </span>{" "}
                            /{" "}
                            <span>
                              {
                                destination?.find(
                                  (des) =>
                                    des?.DestinationId === order?.Destination
                                )?.Port
                              }
                            </span>
                          </div>
                          <span>
                            <span className="text-gray-500">Price: </span>
                            <span>
                              {order?.Price !== "" ? order?.Price : "N/A"} $
                            </span>
                          </span>
                          <span>
                            <span className="text-gray-500">
                              Container Type:{" "}
                            </span>
                            <span>
                              {
                                containerTypes?.find(
                                  (type) =>
                                    type?.ContainerTypeId ===
                                    order?.ContainerType
                                )?.ContainerTypeName
                              }
                            </span>
                          </span>
                        </div>
                      ))
                    ) : (
                      <div className="text-gray-500 py-1.5 flex flex-col">
                        No matching orders{" "}
                      </div>
                    )}
                  </div>

                  <div>
                    <div className="text-blue-500 font-medium mb-1 underline underline-offset-4">
                      Available Shipments
                    </div>
                    {container?.filteredShipments?.length > 0 ? (
                      container?.filteredShipments
                        //production remove reverse
                        //?.reverse()
                        ?.map((shipment, shipmentIndex) =>
                          shipment?.ShipmentsAvId != null ? (
                            <div
                              key={shipmentIndex}
                              className="flex flex-col justify-between mb-2">
                              <div className="flex flex-col">
                                <span className="text-gray-500">
                                  Shipment Company:{" "}
                                </span>
                                <span className="mr-2">
                                  {
                                    shippingCo?.find(
                                      (cust) =>
                                        cust?.CompanyId === shipment?.CompanyId
                                    )?.CompanyName
                                  }
                                </span>
                                <span className="text-gray-500">
                                  Schedule Date:{" "}
                                </span>
                                <span>{shipment?.ScheduleDate}</span>
                              </div>
                              <div>
                                <span className="text-gray-500">Price: </span>
                                <span className="mr-2">
                                  {shipment?.Price === ""
                                    ? "N/A"
                                    : shipment?.Price}{" "}
                                  $
                                </span>
                                <button
                                  onClick={() => {
                                    setSelectedProd(container);
                                    setSelectedShip(shipment);
                                    setCurrentContainerIndex(index);
                                    setIsSelected(true);
                                  }}
                                  className="px-3 py-1 rounded-xl bg-blue-500 text-white shadow-md hover:text-blue-600 hover:bg-white transition duration-200 ease-in">
                                  Select
                                </button>
                                {isSelected &&
                                  currentContainerIndex === index && (
                                    <AddReadyContainer
                                      isOpen={isSelected}
                                      products={products}
                                      notify={notify}
                                      destinations={destination}
                                      customers={customers}
                                      shippingCompanies={shippingCo}
                                      shipmentPrices={shipmentPrices}
                                      containerQuantity={container?.Quantity}
                                      quantity={container?.Quantity}
                                      customOp={customOp}
                                      containers={containerTypes}
                                      selectedShip={selectedShip}
                                      selectedProd={selectedProd}
                                      handleValidateOrders={
                                        handleValidateOrders
                                      }
                                      setIsOpen={setIsSelected}
                                    />
                                  )}
                              </div>
                            </div>
                          ) : (
                            <div></div>
                          )
                        )
                    ) : (
                      <span className="text-gray-500">
                        No matching shipping company
                      </span>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <td colSpan={3} className="text-center py-4">
                <div className="h-28 pb-1">
                  <MyLottieAnimation />
                </div>
                <span>No products found</span>
              </td>
            )}
          </div>
        </div>
      )}
      <ReactTooltip />
    </div>
  );
}
