import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import Lottie from 'react-lottie';
import NoDataFound from '../Components/Lottie/NoDataFound.json';
import { Link } from "react-router-dom";
import {
  ArrowBack,
  ArrowForward,
  Edit,
} from "@mui/icons-material";
const AllProducts = ({products, currentUser}) => {
  const perPage = 5;
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };
  
  const MyLottieAnimation = () => {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: NoDataFound,
    };
  
    return (
      <Lottie options={defaultOptions} />
    );
  };

  const offset = currentPage * perPage;
  const currentPageData = products?.slice(offset, offset + perPage);
  return (
    <div className="relative flex flex-col h-full">
          <div className="flex flex-col sm:flex-row relative">
            <h1 className="font-bold sm:mb-8 text-xl">Product Listing</h1>
            {
              currentUser?.RoleId === 1 && (
                <Link to="/add-product" state={{ product: null }}>
                <button className="my-2 sm:my-0 max-w-[7rem] sm:absolute rounded-2xl right-0 flex text-white bg-blue-600 p-2 items-center justify-center shadow-md hover:text-blue-600 hover:bg-white transition duration-200 ease-in">
                  <span>+ Product</span>
                </button>
              </Link>
              )
            }
          </div>
          {/* Table */}
          <div className="overflow-x-auto text-[17px]">
            <table className="w-full bg-white rounded border-collapse shadow-lg">
              <thead className="border-b-2 border-b-gray-200">
                <tr>
                  <th className="w-[20%] text-gray-500 font-medium p-2" style={{ verticalAlign: 'middle' }}>Product Name</th>
                  <th className="w-[20%] text-gray-500 font-medium p-2" style={{ verticalAlign: 'middle' }}>Product Name in Arabic</th>
                  <th className=" text-gray-500 font-medium p-2" style={{ verticalAlign: 'middle' }}>Product Description</th>
                  {
                    currentUser?.RoleId === 1 && (
                      <th className=" text-gray-500 font-medium p-2" style={{ verticalAlign: 'middle' }}></th>
                    )
                  }
                  
                </tr>
              </thead>
              <tbody>
                {currentPageData?.length > 0 
                ? currentPageData?.map((product) => (
                  <tr key={product.ProductId}>
                    <td className="text-left text-blue-500 underline py-2 pl-2 sm:pl-[3%] md:pl-[4%] xl:pl-[5%] 2xl:pl-[6%] 3xl:pl-[7%]">
                      {product.ProductName}
                    </td>
                    <td className="text-right py-2 pr-[8%] md:pr-[4%] lg:pr-[5%] 3xl:pr-[8%]">{product.ProductNameArabic}</td>
                    <td className="text-left p-2 3xl:pl-[12%]">{product.Description}</td>
                    {
                      currentUser?.RoleId === 1 && (
                        <td className="text-left py-2 pr-1 text-blue-400 text-xs hover:cursor-pointer hover:text-blue-700"><Link to='/add-product' state={{ product: product }}><Edit className="h-4 w-4"/> Edit</Link></td>
                      )
                    }
                     </tr>
                )):
                <tr>
          <td colSpan={3} className="text-center py-4">
            <div className="h-28 pb-1"><MyLottieAnimation /></div>
            <span>No products found</span>
          </td>
        </tr>}
              </tbody>
            </table>
          </div>
          {/* Pagination component */}
          <div className="flex justify-center flex-row">
            <ReactPaginate
              previousLabel={<ArrowBack className="p-1 -mt-1" />}
              nextLabel={<ArrowForward className="p-1 -mt-1" />}
              breakLabel={"..."}
              pageCount={Math.ceil(products?.length / perPage)}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName="flex flex-row my-6"
              activeLinkClassName="!bg-white text-blue-500 w-8 h-[1.7rem] flex justify-center items-center rounded-full p-2 mx-1 cursor-pointer"
              pageClassName={
                "bg-gray-500 text-white w-6 h-6 flex justify-center items-center rounded-full p-2 mx-1 cursor-pointer"
              }
              previousClassName={
                "bg-blue-500 text-white rounded-full w-6 h-6 flex justify-center items-center p-2 mx-1 cursor-pointer"
              }
              nextClassName={
                "bg-blue-500 text-white rounded-full w-6 h-6 flex justify-center items-center p-2 mx-1 cursor-pointer"
              }
              //breakClassName={'bg-gray-500 text-white rounded-full w-6 h-6 flex justify-center items-center p-2 mx-1 cursor-pointer'}
              disabledClassName={"opacity-50 cursor-not-allowed"}
            />
          </div>
        </div>
  );
};

export default AllProducts;
