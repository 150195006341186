import React, { useState, useEffect } from "react";
import AddCustomerShippingCo from "../Components/Popups/AddCustomerShippingCo";
import { Email, Phone, Edit } from "@mui/icons-material";
import Lottie from 'react-lottie';
import NoDataFound from '../Components/Lottie/NoDataFound.json';

export default function Customers({ customers, formatDate, currentUser, notify, getCustomers }) {
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState(customers);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [customersPerPage, setCustomersPerPage] = useState(12);

    useEffect(() => {
        setData(customers);
    }, [customers]);

    const indexOfLastCustomer = currentPage * customersPerPage;
    const indexOfFirstCustomer = indexOfLastCustomer - customersPerPage;
    const currentCustomers = data.slice(indexOfFirstCustomer, indexOfLastCustomer);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(()=>{
      if(screenWidth < 1024){
        setCustomersPerPage(6)
      }else{
        setCustomersPerPage(12)
      }
    },[screenWidth])
    
    const MyLottieAnimation = () => {
        const defaultOptions = {
            loop: false,
            autoplay: true,
            animationData: NoDataFound,
        };

        return (
            <Lottie options={defaultOptions} />
        );
    };

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const showPagination = () => {
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(data.length / customersPerPage); i++) {
            pageNumbers.push(i);
        }
        return (
            <div className="flex justify-center mt-4">
                <ul className="flex">
                    {pageNumbers.map(number => (
                        <li key={number} className={`px-3 py-2 ${currentPage === number ? 'bg-blue-600 text-white rounded-full' : 'bg-gray-200 hover:bg-gray-300 rounded-full'}`}>
                            <a href="#" onClick={() => paginate(number)} className="cursor-pointer">
                                {number}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    return (
        <div className="">
            <div className="flex flex-col sm:flex-row relative sm:items-center">
                <h1 className="font-bold text-xl">{data?.length} customers</h1>
                {
                    currentUser?.RoleId === 1 && (
                        <button onClick={() => { setIsOpen(true); setSelectedCustomer(null) }} className="my-2 sm:my-0 max-w-[7rem] sm:absolute rounded-2xl right-0 flex text-white bg-blue-600 p-2 items-center justify-center shadow-md hover:text-blue-600 hover:bg-white transition duration-200 ease-in">
                            <span>+ Customer</span>
                            {isOpen && <AddCustomerShippingCo parentData={customers} currentUser={currentUser} parentComp="Customer" data={selectedCustomer} isOpen={isOpen} setIsOpen={setIsOpen} formatDate={formatDate} notify={notify} getData={getCustomers} />}
                        </button>
                    )
                }
                
            </div>
            <div className="text-[17px] mt-3 grid grid-cols-1 lg:grid-cols-4 gap-3">
                {
                    currentCustomers.length > 0
                        ? currentCustomers.map((customer) => (
                            <div className="bg-white  rounded-lg p-4" key={customer?.CustomerId}>
                                <div className="flex justify-between">
                                    <div className="w-10 h-10 bg-blue-600 rounded-full flex justify-center items-center text-white text-lg font-bold">
                                        {customer?.CustomerName?.substring(0, 1)}
                                    </div>
                                    {
                                        currentUser?.RoleId === 1 && (
                                            <button onClick={() => { setIsOpen(true); setSelectedCustomer(customer) }} className="text-left py-2 pr-1 text-blue-400 text-xs hover:cursor-pointer hover:text-blue-700"><Edit className="h-4 w-4" /> Edit</button>
                                        )
                                    }
                                    {isOpen && <AddCustomerShippingCo parentData={customers} currentUser={currentUser} parentComp="Customer" data={selectedCustomer} isOpen={isOpen} setIsOpen={setIsOpen} formatDate={formatDate} notify={notify} getData={getCustomers} />}
                                </div>
                                <h2 className="text-xl font-bold mt-2">{customer?.CustomerName}</h2>
                                <span className="flex items-center mt-1">
                                    <Email className="mr-2 text-gray-300" />
                                    {customer?.EmailAddress}
                                </span>
                                <span className="flex items-center mt-1">
                                    <Phone className="mr-2 text-gray-300" />
                                    {customer?.PhoneNumber}
                                </span>
                            </div>
                        ))
                        : <div className="text-center col-span-1 lg:col-span-4 py-4 w-full">
                            <div className="h-1/5 pb-1"><MyLottieAnimation /></div>
                            <span>No customers found</span>
                        </div>}
            </div>
            {data.length > customersPerPage && showPagination()}
        </div>
    );
}