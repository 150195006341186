import React, { useState, useEffect } from "react";
import { Home } from "@mui/icons-material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Select from "react-dropdown-select";
import Lottie from "react-lottie";
import axios from "axios";
import LoadingTruck from "../Components/Lottie/LoadingTruck.json";
import CountryList from "country-list-with-dial-code-and-flag";
import CountryFlagSvg from "country-list-with-dial-code-and-flag/dist/flag-svg";
export default function AddDestination({ destinations, getDestination, notify, formatDate, currentUser }) {
  const location = useLocation();
  const { destination } = location.state;
  const [formData, setFormData] = useState({
    DestinationId: destination ? destination?.DestinationId : null,
    Country: destination ? destination?.Country : "",
    Port: destination ? destination?.Port : "",
    StatusId: destination ? destination?.StatusId : 1,
    addedBy: destination ? destination?.addedBy : currentUser?.UserId,
    addedAt: destination ? destination?.addedAt : formatDate(),
  });
  const [isLoading, setIsLoading] = useState(false);
  var countriesList = CountryList.getAll();
  const FlagSvg = ({ svgCode, name }) => {
    return (
      <div
        className={`flex items-center justify-center mb-1 w-20 h-20 border-[3px] ${
          formData.Country == name ? "border-blue-500" : "border-white"
        } rounded-full`}>
        <div className="w-6" dangerouslySetInnerHTML={{ __html: svgCode }} />
      </div>
    );
  };

  const [alteredCountries, setAlteredCountries] = useState(countriesList?.filter(
    (c) => c?.name.toLowerCase() !== "israel"
  ));

  const [searchTerm, setSearchTerm] = useState("");

  const filteredCountries = alteredCountries.filter((country) =>
    country.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const navigate = useNavigate();
  const root = window.location.href.includes("localhost")
    ? "http://localhost:8451"
    : "https://api.ubstrade.com";
  const handleSubmit = async (e) => {
    let isDestFound = false;
    if(destination){
      isDestFound = destinations?.find((p) => {
        return  p?.DestinationId !== formData?.DestinationId
          && p?.Country?.toLowerCase().trim() === formData?.Country?.toLowerCase().trim() 
          && p?.Port?.toLowerCase().trim() === formData?.Port?.toLowerCase().trim()
          && p?.StatusId === formData?.StatusId;
      });
    }else{
      isDestFound = destinations?.find((p) => {
        return p?.Country?.toLowerCase().trim() === formData?.Country?.toLowerCase().trim() 
          && p?.Port?.toLowerCase().trim() === formData?.Port?.toLowerCase().trim()
          && p?.StatusId === formData?.StatusId;
      });
    }
    if(isDestFound){
      notify("This destination already exists",3);
    }else{
      setIsLoading(true);
    axios
      .post(`${root}/destination/add`, formData)
      .then((response) => {
        getDestination();
        if(destination){
          notify("Updated destination successfully", 1);
        }else{
          notify("Added destination successfully", 1);
        }
        setIsLoading(false);
        navigate("/destination");
      })
      .catch((error) => {
        notify("Error adding destination", 2);
        console.log(error);
        setIsLoading(false);
      });
    }
    
  };
  const MyLottieAnimation = () => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: LoadingTruck,
    };

    return <Lottie options={defaultOptions} />;
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  // Calculate the index range for the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  // Get the countries for the current page
  const currentCountries = filteredCountries?.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate the total number of pages
  const totalPages = Math.ceil(filteredCountries?.length / itemsPerPage);

  // Function to generate the page buttons
  const getPageButtons = () => {
    const buttons = [];
    let startPage = 1;
    let endPage = totalPages;

    // Limit the number of page buttons shown
    if (totalPages > 7) {
      if (currentPage <= 3) {
        endPage = 4;
      } else if (currentPage >= totalPages - 3) {
        startPage = totalPages - 3;
      } else {
        startPage = currentPage - 1;
        endPage = currentPage + 1;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          type="button"
          className={`mx-2 w-8 h-8 rounded-full ${
            currentPage === i
              ? "bg-blue-500 text-white"
              : "bg-gray-100 text-gray-400"
          }`}
          onClick={() => handlePageChange(i)}>
          {i}
        </button>
      );
    }

    // Add ellipsis if there are more pages
    if (startPage > 1) {
      buttons.unshift(
        <span key="start-ellipsis" className="mx-2 text-gray-400">
          ...
        </span>
      );
      buttons.unshift(
        <button
          type="button"
          key="1"
          className="mx-2 w-8 h-8 rounded-full bg-gray-100 text-gray-400"
          onClick={() => handlePageChange(1)}>
          1
        </button>
      );
    }

    if (endPage < totalPages) {
      buttons.push(
        <span key="end-ellipsis" className="mx-2 text-gray-400">
          ...
        </span>
      );
      buttons.push(
        <button
          type="button"
          key={totalPages}
          className="mx-2 w-8 h-8 rounded-full bg-gray-100 text-gray-400"
          onClick={() => handlePageChange(totalPages)}>
          {totalPages}
        </button>
      );
    }

    return buttons;
  };

  return (
    <div className="relative flex flex-col h-full">
      <h1 className="flex pb-4 text-xl">
        <Link to="/shipment-price">
          <Home className="text-gray-600 mr-2" />
        </Link>
        <span> / Destination / </span>
        <span className="text-blue-600 ml-2">
          {destination ? "Edit" : "Add"} destination
        </span>
      </h1>
      <form className="flex flex-col w-full text-[17px]" onSubmit={handleSubmit}>
        <div className="flex flex-col lg:flex-row items-center">
          <span className="w-full lg:w-[25%]">Country <span className="text-red-500">*</span></span>
          <div className="w-full lg:w-[40%] ">
            <input
              type="search"
              required
              placeholder="Search for a country"
              className="p-2 w-full border-2 border-gray-100 my-4 rounded-md focus:outline-none focus:border-blue-500"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-x-20 gap-y-4 px-2 justify-center">
              {currentCountries?.map((country) => (
                <div
                  onClick={() =>
                    setFormData({ ...formData, Country: country?.name })
                  }
                  className="flex flex-col items-center">
                  <FlagSvg
                    svgCode={CountryFlagSvg[country?.code]}
                    name={country?.name}
                  />
                  {country?.name}
                </div>
              ))}
            </div>
            {/* Pagination */}
            <div className="flex justify-center mt-6">{getPageButtons()}</div>
          </div>
        </div>
        <div className="mt-5 flex flex-col lg:flex-row items-center">
          <span className="w-full lg:w-[25%]">Port <span className="text-red-500">*</span></span>
          <input
            placeholder="Port"
            required
            defaultValue={destination ? destination?.Port : ""}
            className="w-full resize-none lg:w-[40%] leading-3 border-2 border-gray-300 p-2 my-4 rounded-lg focus:outline-none focus:border-blue-600"
            onChange={(e) =>
              setFormData({ ...formData, Port: e.target.value })
            } />
        </div>
        <div className="mt-5 flex flex-col lg:flex-row lg:items-center">
          <span className="w-full lg:w-[25%]">Status <span className="text-red-500">*</span></span>
          <div>
            <label>Active</label>
            <input
              type="radio"
              className="ml-2 mr-6 checked:bg-blue-500"
              value={1}
              checked={formData.StatusId === 1}
              onClick={(e) =>
                setFormData({ ...formData, StatusId: 1 })
              }
            />
          </div>
          <div>
            <label>Inactive</label>
            <input
              type="radio"
              className="ml-2 mr-6 checked:bg-blue-500"
              value={2}
              checked={formData.StatusId === 2}
              onClick={(e) =>
                setFormData({ ...formData, StatusId: 2 })
              }
            />
          </div>
        </div>
        <div className="w-full mt-5 lg:w-[65%] flex items-center justify-end">
          <Link to="/destination">
            <button
              type="reset"
              disabled={isLoading}
              className="rounded-2xl text-white bg-blue-600 px-4 py-2 shadow-md hover:text-blue-600 hover:bg-white transition duration-200 ease-in">
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            disabled={isLoading}
            className="rounded-2xl text-white bg-blue-600 ml-8 px-4 py-2 shadow-md hover:text-blue-600 hover:bg-white transition duration-200 ease-in">
            {isLoading ? (
              <div className="h-8">
                <MyLottieAnimation />
              </div>
            ) : destination ? (
              "Edit"
            ) : (
              "Add"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
