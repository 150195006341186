import React, { useState, useEffect } from "react";
import { Home } from "@mui/icons-material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Select from "react-dropdown-select";
import Lottie from "react-lottie";
import axios from "axios";
import LoadingTruck from "../Components/Lottie/LoadingTruck.json";
export default function AddShipmentPrice({
  product,
  currentUser,
  shipmentPrices,
  containerTypes,
  shippingCo,
  destination,
  getShipmentPrice,
  notify,
  formatDate,
}) {
  const location = useLocation();
  const { shipmentPrice } = location.state;
  const [formData, setFormData] = useState({
    ShipmentId: shipmentPrice ? shipmentPrice?.ShipmentId : null,
    CompanyId: shipmentPrice ? shipmentPrice?.CompanyId : null,
    Destination: shipmentPrice ? shipmentPrice?.Destination : null,
    Price: shipmentPrice ? shipmentPrice?.Price : "",
    ShipmentType: shipmentPrice ? shipmentPrice?.ShipmentType : null,
    AddedBy: shipmentPrice ? shipmentPrice?.AddedBy : currentUser?.UserId,
    AddedAt: shipmentPrice ? shipmentPrice?.AddedAt : formatDate(),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [alteredDes, setAlteredDes] = useState([]);
  useEffect(() => {
    if (destination) {
      const destinationOptions = destination.map((option) => ({
        DestinationId: option.DestinationId,
        label: `${option.Country} / ${option.Port}`,
      }));
      setAlteredDes(destinationOptions);
    }
  }, [destination]);

  const navigate = useNavigate();
  const root = window.location.href.includes("localhost")
    ? "http://localhost:8451"
    : "https://api.ubstrade.com";
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(formData.CompanyId === null){
      notify("Please select a shipping company", 3);
    } else if(formData.Destination === null){
      notify("Please select a destination", 3);
    }else if(formData.ShipmentType === null){
      notify("Please select a container type", 3);
    }else{
    let isShipPriceFound = false;
    if(shipmentPrice){
      isShipPriceFound = shipmentPrices?.find((p) => {
        return p?.ShipmentId !== shipmentPrice?.ShipmentId
          && p?.CompanyId === formData?.CompanyId 
          && p?.Destination === formData?.Destination 
          && p?.ShipmentType === formData?.ShipmentType ;
      });
    }else{
    isShipPriceFound = shipmentPrices?.find((p) => {
      return p?.CompanyId === formData?.CompanyId 
        && p?.Destination === formData?.Destination
        && p?.ShipmentType === formData?.ShipmentType ;
    });
  }
    if(isShipPriceFound){
      notify("This shipment price already exists",3);
    }else{
     setIsLoading(true);
     axios
       .post(`${root}/shipmentsPrices/add`, formData)
       .then((response) => {
         getShipmentPrice();
         if(shipmentPrice){
          notify("Updated shipment price successfully", 1);
         }else{
          notify("Added shipment price successfully", 1);
         }
         setIsLoading(false);
         navigate("/shipment-price");
       })
       .catch((error) => {
         notify("Error adding shipment price", 2);
         console.log(error);
         setIsLoading(false);
       });
    }}
  };
  const MyLottieAnimation = () => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: LoadingTruck,
    };

    return <Lottie options={defaultOptions} />;
  };

  return (
    <div className="relative flex flex-col h-full">
      <h1 className="flex pb-4 text-xl">
        <Link to="/shipment-price">
          <Home className="text-gray-600 mr-2" />
        </Link>
        <span> / Shipment Price / </span>
        <span className="text-blue-600 ml-2">
          {product ? "Edit" : "Add"} Shipment Price
        </span>
      </h1>
      <form className="flex flex-col w-full text-[17px]" onSubmit={handleSubmit}>
        <div className="flex flex-col lg:flex-row items-center">
          <span className="w-full lg:w-[25%]">Shipping Company <span className="text-red-500">*</span></span>
          <div className="w-full lg:w-[40%] rounded-lg">
            <Select
              labelField="CompanyName"
              valueField="CompanyId"
              multi={false}
              dropdownHeight="160px"
              placeholder={
                shipmentPrice
                  ? shippingCo.find(
                      (company) =>
                        company?.CompanyId === shipmentPrice?.CompanyId
                    )?.CompanyName
                  : "Select a shipping company "
              }
              style={{
                backgroundColor: "#f9fafb",
                border: "2px solid #d1d5db",
                borderRadius: "0.5rem",
                padding: "0.5rem",
                margin: "0.5rem 0",
                boxShadow: "0 0 0 0",
              }}
              options={shippingCo}
              onChange={(e) =>
                setFormData({ ...formData, CompanyId: e[0].CompanyId })
              }
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center">
          <span className="w-full lg:w-[25%]">Destination <span className="text-red-500">*</span></span>
          <div className="w-full lg:w-[40%] rounded-lg my-4">
            <Select
              labelField="label"
              valueField="DestinationId"
              dropdownHeight="160px"
              placeholder={
                shipmentPrice
                  ? alteredDes.find(
                      (des) => des?.DestinationId === shipmentPrice?.Destination
                    )?.label
                  : "Select a destination "
              }
              multi={false}
              style={{
                backgroundColor: "#f9fafb",
                border: "2px solid #d1d5db",
                borderRadius: "0.5rem",
                padding: "0.5rem",
                margin: "0.5rem 0",
                boxShadow: "0 0 0 0",
              }}
              options={alteredDes}
              onChange={(e) =>
                setFormData({ ...formData, Destination: e[0].DestinationId })
              }
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center">
          <span className="w-full lg:w-[25%]">Container Type <span className="text-red-500">*</span></span>
          <div className="w-full lg:w-[40%] rounded-lg">
            <Select
              labelField="ContainerTypeName"
              valueField="ContainerTypeId"
              dropdownHeight="160px"
              multi={false}
              searchable={true}
              placeholder={
                shipmentPrice
                  ? containerTypes.find(
                      (option) =>
                        option.ContainerTypeId === shipmentPrice?.ShipmentType
                    )?.ContainerTypeName
                  : "Select a container type"
              }
              keepSelectedInList={false}
              style={{
                backgroundColor: "#f9fafb",
                border: "2px solid #d1d5db",
                borderRadius: "0.5rem",
                padding: "0.5rem",
                margin: "0.5rem 0",
                boxShadow: "0 0 0 0",
              }}
              options={containerTypes}
              onChange={(e) =>
                setFormData({ ...formData, ShipmentType: e[0].ContainerTypeId })
              }
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center mt-2">
          <span className="w-full lg:w-[25%]">Shipment Price <span className="text-red-500">*</span></span>
          <input
            type="text"
            inputMode="numeric"
            maxLength={4}
            required
            minLength={1}
            placeholder="shipment price"
            defaultValue={shipmentPrice ? shipmentPrice?.Price : ""}
            className="w-full resize-none lg:w-[40%] leading-3 border-2 border-gray-300 p-2 my-4 rounded-lg focus:outline-none focus:border-blue-600"
            onChange={(e)=>setFormData({ ...formData, Price: e.target.value })}
            onKeyDown={(e) => {
              // Check if the user is deleting input
              if (["Delete", "Backspace"].includes(e.key)) {
                setFormData({ ...formData, Price: e.target.value });
              } else if (!/[0-9]/.test(e.key)) {
                // Prevent non-numeric input
                notify("Please enter a valid number", 3);
                e.preventDefault();
              } else {
                setFormData({ ...formData, Price: e.target.value });
              }
            }}
          />
        </div>
        <div className="w-full mt-4 lg:w-[65%] flex items-center justify-end">
          <Link to="/shipment-price">
            <button
              type="reset"
              disabled={isLoading}
              className="rounded-2xl text-white bg-blue-600 px-4 py-2 shadow-md hover:text-blue-600 hover:bg-white transition duration-200 ease-in">
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            disabled={isLoading}
            className="rounded-2xl text-white bg-blue-600 ml-8 px-4 py-2 shadow-md hover:text-blue-600 hover:bg-white transition duration-200 ease-in">
            {isLoading ? (
              <div className="h-8">
                <MyLottieAnimation />
              </div>
            ) : product ? (
              "Edit"
            ) : (
              "Add"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
