import React, { useEffect, useState } from "react";
import { Home } from "@mui/icons-material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Select from "react-dropdown-select";
import Lottie from "react-lottie";
import axios from "axios";
import LoadingTruck from "../Components/Lottie/LoadingTruck.json";
export default function AddShipment({shippingCo, availableShipments, destination, getShipments, notify, formatDate, currentUser }) {
  const location = useLocation();
    const { shipment } = location.state  
  const [formData, setFormData] = useState({
      ShipmentsAvId: shipment ? shipment.ShipmentsAvId : null, 
      CompanyId: shipment ? shipment.CompanyId : null, 
      VesselName: shipment ? shipment.VesselName : '',
      ScheduleDate: shipment ? shipment.ScheduleDate :formatDate(),
      Destination: shipment ? shipment.Destination : null,   
      AddedBy: shipment ? shipment.AddedBy : currentUser?.UserId, 
      AddedAt: shipment ? shipment.AddedAt :formatDate(), 
    });
    const [isLoading, setIsLoading] = useState(false);
    const [alteredDes, setAlteredDes] = useState([]);
    const navigate = useNavigate();
    const root = window.location.href.includes("localhost")
      ? "http://localhost:8451"
      : "https://api.ubstrade.com";
    const handleSubmit = async (e) => {
      e.preventDefault();
    if(formData.CompanyId === null){
      notify("Please select a shipping company", 3);
    }else if(formData.Destination === null){
      notify("Please select a destination", 3);
    }else{
      let isShipmFound = false;
      if(shipment){
        isShipmFound = availableShipments?.find((p) => {
          return p?.ShipmentsAvId !== formData?.ShipmentsAvId 
            && p?.CompanyId === formData?.CompanyId 
            && p?.VesselName?.toLowerCase().trim() === formData?.VesselName?.toLowerCase().trim()
            && p?.Destination === formData?.Destination
            && p?.ScheduleDate?.trim() === formData?.ScheduleDate.trim();
        });
      }else{
        isShipmFound = availableShipments?.find((p) => {
          return p?.CompanyId === formData?.CompanyId 
            && p?.VesselName?.toLowerCase().trim() === formData?.VesselName?.toLowerCase().trim()
            && p?.Destination === formData?.Destination
            && p?.ScheduleDate?.trim() === formData?.ScheduleDate.trim();
        });
      }
      if(isShipmFound){
        notify("This shipment is already available",3);
      }
      else{
      setIsLoading(true);
      axios
      .post(`${root}/shipmentsAvailable/add`,
        formData
      )
        .then((response) => {
          getShipments();
          if(shipment){
            notify("Updated available shipment successfully", 1);
          }else{
            notify("Added available shipment successfully", 1);
          }
          setIsLoading(false);
          navigate("/available-shipment");
        })
        .catch((error) => {
          notify("Error adding available shipment", 2);
          console.log(error);
          setIsLoading(false);
        });
      }
      }
    }
    useEffect(() => {
      if (destination) {
        const destinationOptions = destination.map((option) => ({
          DestinationId: option.DestinationId,
          label: `${option.Country} / ${option.Port}`,
        }));
        setAlteredDes(destinationOptions);
      }
    }, [destination]);
    const MyLottieAnimation = () => {
      const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: LoadingTruck,
      };
    
      return (
        <Lottie options={defaultOptions} />
      );
    };
    return (
    <div className="relative flex flex-col h-full">
      <h1 className="flex pb-4 text-xl">
        <Link to="/available-shipment">
          <Home className="text-gray-600 mr-2" />
        </Link>
        <span> / Shipments / </span>
        <span className="text-blue-600 ml-2">
          {shipment ? "Edit" : "Add"} Available Shipment
        </span>
      </h1>
      <form className="flex flex-col w-full text-[17px]" onSubmit={handleSubmit}>
        <div className="flex flex-col lg:flex-row items-center">
          <span className="w-full lg:w-[25%]">Shipping Company <span className="text-red-500">*</span></span>
          <div className="w-full lg:w-[40%] rounded-lg">
            <Select
              labelField="CompanyName"
              valueField="CompanyId"
              multi={false}
              dropdownHeight="160px"
              placeholder={shipment ? shippingCo.find((company) => company?.CompanyId === shipment?.CompanyId)?.CompanyName : 'Select a shipping company '}
              style={{
                backgroundColor: "#f9fafb",
                border: "2px solid #d1d5db",
                borderRadius: "0.5rem",
                padding: "0.5rem",
                margin: "0.5rem 0",
                boxShadow: "0 0 0 0",
              }}
              options={shippingCo}
              onChange={(e) =>
                setFormData({ ...formData, CompanyId: e[0].CompanyId })
              }
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center">
          <span className="w-full lg:w-[25%]">
            Vessel Name <span className="text-red-500">*</span>
          </span>
          <input
            type="text"
            required
            defaultValue={shipment ? shipment?.VesselName : ""}
            placeholder="Vessel Name"
            className="w-full lg:w-[40%] border-2 border-gray-300 p-2 my-4 rounded-lg focus:outline-none focus:border-blue-600"
            onChange={(e) =>
              setFormData({ ...formData, VesselName: e.target.value })
            }
          />
        </div>
        <div className="flex flex-col lg:flex-row items-center">
          <span className="w-full lg:w-[25%]">
          Scheduled Date <span className="text-red-500">*</span>
          </span>
          <input
            type="date"
            required
            defaultValue={shipment ? shipment?.ScheduleDate : new Date().toISOString().split("T")[0]}
            className="w-full lg:w-[40%] border-2 border-gray-300 p-2 mb-4 mt-2 rounded-lg focus:outline-none focus:border-blue-600"
            onChange={(e) =>
              setFormData({ ...formData, ScheduleDate: e.target.value })
            }
          />
        </div>
        <div className="flex flex-col lg:flex-row items-center">
          <span className="w-full lg:w-[25%]">
            Destination <span className="text-red-500">*</span>
          </span>
          <div className="w-full lg:w-[40%] rounded-lg">
            <Select
              labelField="label"
              placeholder={shipment ? alteredDes.find((des) => des?.DestinationId === shipment?.Destination)?.label : 'Select a destination '}
              valueField="DestinationId"
              dropdownHeight="160px"
              multi={false}
              style={{
                backgroundColor: "#f9fafb",
                border: "2px solid #d1d5db",
                borderRadius: "0.5rem",
                padding: "0.5rem",
                margin: "0.5rem 0",
                boxShadow: "0 0 0 0",
              }}
              options={alteredDes}
              onChange={(e) =>
                setFormData({ ...formData, Destination: e[0].DestinationId })
              }
            />
          </div>
        </div>
        <div className="w-full mt-4 lg:w-[65%] flex items-center justify-end">
          <Link to="/available-shipment">
            <button
              type="reset"
              disabled={isLoading}
              className="rounded-2xl text-white bg-blue-600 px-4 py-2 shadow-md hover:text-blue-600 hover:bg-white transition duration-200 ease-in">
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            disabled={isLoading}
            className="rounded-2xl text-white bg-blue-600 ml-8 px-4 py-2 shadow-md hover:text-blue-600 hover:bg-white transition duration-200 ease-in">
            {isLoading ? <div className="h-8"><MyLottieAnimation /></div> : shipment ? "Edit" : "Add"}
          </button>
        </div>
      </form>
    </div>
  );
}
