import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Home } from "@mui/icons-material";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import axios from "axios";
import LoadingTruck from "../Components/Lottie/LoadingTruck.json";
export default function AddProduct({
  notify,
  getProducts,
  formatDate,
  currentUser,
  products,
}) {
  const location = useLocation();
  const { product } = location.state;
  const [formData, setFormData] = useState({
    ProductId: product ? product.ProductId : null,
    ProductName: product ? product.ProductName : "",
    ProductNameArabic: product ? product.ProductNameArabic : "",
    Description: product ? product.Description : "",
    AddedAt: product ? product.AddedAt : formatDate(),
    AddedBy: product ? product.AddedBy : currentUser?.UserId,
  });
  const [isLoading, setIsLoading] = useState(false);
  const MyLottieAnimation = () => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: LoadingTruck,
    };

    return <Lottie options={defaultOptions} />;
  };
  const navigate = useNavigate();
  const root = window.location.href.includes("localhost")
    ? "http://localhost:8451"
    : "https://api.ubstrade.com";
  const handleSubmit = async (e) => {
    let isProductFound = false;
    if (product) {
      // if editing product check for duplicates other than the one being edited
      isProductFound = products?.find((p) => {
        return (
          (p?.ProductName?.toLowerCase().trim() ===
            formData?.ProductName?.toLowerCase().trim() &&
            p?.ProductId !== formData?.ProductId) ||
          (p?.ProductNameArabic?.toLowerCase().trim() ===
            formData?.ProductNameArabic?.toLowerCase().trim() &&
            p?.ProductId !== formData?.ProductId)
        );
      });
    } else {
      // if adding product check for duplicates
      isProductFound = products?.find((p) => {
        return (
          p?.ProductName?.toLowerCase().trim() ===
            formData?.ProductName?.toLowerCase().trim() ||
          p?.ProductNameArabic?.toLowerCase().trim() ===
            formData?.ProductNameArabic?.toLowerCase().trim()
        );
      });
    }
    if (isProductFound) {
      notify("This product already exists", 3);
    } else {
      setIsLoading(true);
      axios
        .post(`${root}/products/add`, formData)
        .then((response) => {
          getProducts();
          if (product) {
            notify("Updated product successfully", 1);
          } else {
            notify("Added product successfully", 1);
          }
          setIsLoading(false);
          navigate("/products");
        })
        .catch((error) => {
          notify("Error adding product", 2);
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  return (
    <div className="relative flex flex-col h-full">
      <h1 className="flex pb-4 text-xl">
        <Link to="/products">
          <Home className="text-gray-600 mr-2" />
        </Link>
        <span> / Product / </span>
        <span className="text-blue-600 ml-2">
          {product ? "Edit" : "Add"} Product
        </span>
      </h1>
      <form className="flex flex-col w-full text-[17px]" onSubmit={handleSubmit}>
        <div className="flex flex-col lg:flex-row items-center">
          <span className="w-full lg:w-[25%]">Product Name <span className="text-red-500">*</span></span>
          <input
            type="text"
            placeholder="product name"
            required
            defaultValue={product ? product.ProductName : ""}
            className="w-full lg:w-[40%] border-2 border-gray-300 p-2 my-4 rounded-lg focus:outline-none focus:border-blue-600"
            onChange={(e) =>
              setFormData({ ...formData, ProductName: e.target.value })
            }
          />
        </div>
        <div className="flex flex-col lg:flex-row items-center">
          <span className="w-full lg:w-[25%]">Product Name in Arabic <span className="text-red-500">*</span></span>
          <input
            type="text"
            placeholder="product name in arabic"
            required
            defaultValue={product ? product.ProductNameArabic : ""}
            className="w-full lg:w-[40%] border-2 border-gray-300 p-2 my-4 rounded-lg focus:outline-none focus:border-blue-600"
            onChange={(e) =>
              setFormData({
                ...formData,
                ProductNameArabic: e.target.value,
              })
            }
          />
        </div>
        <div className="flex flex-col lg:flex-row items-center">
          <span className="w-full lg:w-[25%]">Product Description <span className="text-red-500">*</span></span>
          <textarea
            rows={6}
            defaultValue={product ? product.Description : ""}
            placeholder="product description"
            required
            className="w-full resize-none lg:w-[40%] leading-3 border-2 border-gray-300 p-2 my-4 rounded-lg focus:outline-none focus:border-blue-600"
            onChange={(e) =>
              setFormData({ ...formData, Description: e.target.value })
            }></textarea>
        </div>
        <div className="w-full mt-4 lg:w-[65%] flex items-center justify-end">
          <Link to="/products" className="">
            <button
              type="button"
              disabled={isLoading}
              className="rounded-2xl text-white bg-blue-600 px-4 py-2 shadow-md hover:text-blue-600 hover:bg-white transition duration-200 ease-in">
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            disabled={isLoading}
            className="rounded-2xl ml-8 text-white bg-blue-600 px-4 py-2 shadow-md hover:text-blue-600 hover:bg-white transition duration-200 ease-in">
            {isLoading ? (
              <div className="h-8">
                <MyLottieAnimation />
              </div>
            ) : product ? (
              "Edit"
            ) : (
              "Add"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
