import React from "react";
import { Link } from "react-router-dom";
import {
  Person,
  AttachMoney,
  Inventory,
  ReceiptLong,
  LocalShipping,
  LocalMall,
  DirectionsBoat,
  Business,
  Map,
  ShoppingCart,
  People,
  Logout,
} from "@mui/icons-material";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";

export default function Navbar({
  activePage,
  setCurrentUser,
  root,
  currentUser,
  notify,
}) {
  const navigate = useNavigate();
  function LogoutFct() {
    var formData = {
      UserId: currentUser?.UserId,
    };
    axios
      .post(`${root}/auth/logout`, formData)
      .then((response) => {
        Cookies.remove("token");
        setCurrentUser(null);
        navigate("/login");
      })
      .catch((error) => {
        notify("Error logging you out", 2);
        console.log(error);
      });
  }

  return (
    <>
      <Sidebar className="hidden mt-16 lg:block fixed bg-white max-h-[100vh] w-[12%] text-base border-r-[1px] border-r-gray-200">
        <Menu className="mt-8 max-h-[75vh] overflow-y-auto relative">
          <MenuItem
            style={{width: "100%"}}
            icon={<ShoppingCart className="text-gray-600 mr-3" />}
            component={
              <Link to="/products">
              </Link>
            }
            className={`${
              activePage === 1 ? "border-l-4 bg-gray-50 border-l-blue-600" : ""
            } flex py-1 items-center text-[14px] text-gray-600  hover:bg-gray-50  hover:border-l-4 hover:border-l-blue-600 hover:cursor-pointer`}>
            Products
          </MenuItem>
          <MenuItem style={{width: "100%"}} icon={<LocalMall className="text-gray-600 mr-3" />} className={`${
                  activePage === 8
                    ? "border-l-4 bg-gray-50 border-l-blue-600"
                    : ""
                } flex py-1 items-center text-[14px] text-gray-600 hover:bg-gray-50  hover:border-l-4 hover:border-l-blue-600 hover:cursor-pointer`}
                 component={<Link to="/available-products">
          </Link>}>
            {" "}
            Available Products{" "}
          </MenuItem>
          <MenuItem style={{width: "100%"}} icon={<ReceiptLong className="text-gray-600 mr-3" />} className={`${
                activePage === 2
                  ? "border-l-4 bg-gray-50 border-l-blue-600"
                  : ""
              } flex py-1 items-center text-[14px] text-gray-600  hover:bg-gray-50  hover:border-l-4 hover:border-l-blue-600 hover:cursor-pointer`}
               component={ <Link to="/order-history"></Link>}>
            {" "}
            Sales Orders{" "}
          </MenuItem>
          <MenuItem style={{width: "100%"}} icon={<Inventory className="text-gray-600 mr-3" />} className={`${
                activePage === 3
                  ? "border-l-4 bg-gray-50 border-l-blue-600"
                  : ""
              } flex py-1 items-center text-[14px] text-gray-600  hover:bg-gray-50  hover:border-l-4 hover:border-l-blue-600 hover:cursor-pointer`}
               component={ <Link to="/generate-ready-containers"></Link>}>
            {" "}
            Ready Containers{" "}
          </MenuItem>
          <MenuItem style={{width: "100%"}} icon={<DirectionsBoat className="text-gray-600 mr-3" />} className={`${
                activePage === 11
                  ? "border-l-4 bg-gray-50 border-l-blue-600"
                  : ""
              } flex py-1 items-center text-[14px] text-gray-600  hover:bg-gray-50  hover:border-l-4 hover:border-l-blue-600 hover:cursor-pointer`}
               component={ <Link to="/ready-containers"></Link>}>
            {" "}
            Ready To be Shipped{" "}
          </MenuItem>
          <MenuItem style={{width: "100%"}} icon={<LocalShipping className="text-gray-600 mr-3" />} className={`${
                activePage === 4
                  ? "border-l-4 bg-gray-50 border-l-blue-600"
                  : ""
              } flex py-1 items-center text-[14px] text-gray-600  hover:bg-gray-50  hover:border-l-4 hover:border-l-blue-600 hover:cursor-pointer`}
               component={ <Link to="/available-shipment"></Link>}>
            {" "}
            Available Shipments{" "}
          </MenuItem>
          <MenuItem style={{width: "100%"}} icon={<AttachMoney className="text-gray-600 mr-3" />} className={`${
                activePage === 5
                  ? "border-l-4 bg-gray-50 border-l-blue-600"
                  : ""
              } flex py-1 items-center text-[14px] text-gray-600  hover:bg-gray-50  hover:border-l-4 hover:border-l-blue-600 hover:cursor-pointer`}
               component={ <Link to="/shipment-price"></Link>}>
            {" "}
            Shipment Price{" "}
          </MenuItem>
          <MenuItem style={{width: "100%"}} icon={<Map className="text-gray-600 mr-3" />} className={`${
                activePage === 9
                  ? "border-l-4 bg-gray-50 border-l-blue-600"
                  : ""
              } flex py-1 items-center text-[14px] text-gray-600  hover:bg-gray-50  hover:border-l-4 hover:border-l-blue-600 hover:cursor-pointer`}
               component={ <Link to="/destination"></Link>}>
            {" "}
            Destinations{" "}
          </MenuItem>
          <MenuItem style={{width: "100%"}} icon={<Person className="text-gray-600 mr-3" />} className={`${
                activePage === 6
                  ? "border-l-4 bg-gray-50 border-l-blue-600"
                  : ""
              } flex py-1 items-center text-[14px] text-gray-600  hover:bg-gray-50  hover:border-l-4 hover:border-l-blue-600 hover:cursor-pointer`}
               component={ <Link to="/customers"></Link>}>
            {" "}
            Customers{" "}
          </MenuItem>
          <MenuItem style={{width: "100%"}} icon={<People className="text-gray-600 mr-3" />} className={`${
                activePage === 7
                  ? "border-l-4 bg-gray-50 border-l-blue-600"
                  : ""
              } flex py-1 items-center text-[14px] text-gray-600  hover:bg-gray-50  hover:border-l-4 hover:border-l-blue-600 hover:cursor-pointer`}
               component={ <Link to="/shipping-companies"></Link>}>
            Shipping Companies
          </MenuItem>
          <MenuItem style={{width: "100%"}} icon={<People className="text-gray-600 mr-3" />} className={`${
                activePage === 10
                  ? "border-l-4 bg-gray-50 border-l-blue-600"
                  : ""
              } flex py-1 items-center text-[14px] text-gray-600  hover:bg-gray-50  hover:border-l-4 hover:border-l-blue-600 hover:cursor-pointer`}
               component={ <Link to="/users"></Link>}>
            Users
          </MenuItem>
        </Menu>
        <div style={{width: "100%"}} className="max-h-[calc(70vh-100px)] overflow-y-auto">
          <button onClick={() => LogoutFct()}>
            <div className="flex items-center absolute bottom-5 w-full pl-5 text-[14px] text-gray-600 py-3 hover:bg-slate-200 hover:text-white hover:cursor-pointer">
              <Logout className="mr-3" />
              Logout
            </div>
          </button>
        </div>
      </Sidebar>
      {/* <div className="hidden mt-16 lg:block fixed bg-white h-full w-[12%] text-base border-r-[1px] border-r-gray-200">
        <ul className="mt-8 max-h-[75vh] overflow-y-auto">
          <Link to="/products">
            <li className="flex flex-col text-[14px] text-gray-600">
              <span
                className={`${
                  activePage === 1
                    ? "border-l-4 bg-gray-50 border-l-blue-600"
                    : ""
                } flex py-4 px-5 items-center mb-2 text-[14px] text-gray-600  hover:bg-gray-50  hover:border-l-4 hover:border-l-blue-600 hover:cursor-pointer`}>
                <ShoppingCart className="text-gray-600 mr-3" />
                Products
              </span>
            </li>
          </Link>
          <Link to="/available-products">
            <li className="flex flex-col text-[14px] text-gray-600">
              <span
                className={`${
                  activePage === 8
                    ? "border-l-4 bg-gray-50 border-l-blue-600"
                    : ""
                } flex py-4 px-5 items-center mb-2 text-[14px] text-gray-600 hover:bg-gray-50  hover:border-l-4 hover:border-l-blue-600 hover:cursor-pointer`}>
                <LocalMall className="text-gray-600 mr-3" />
                Available Products
              </span>
            </li>
          </Link>
          <Link to="/order-history">
            <li
              className={`${
                activePage === 2
                  ? "border-l-4 bg-gray-50 border-l-blue-600"
                  : ""
              } flex py-4 px-5 items-center mb-2   text-[14px] text-gray-600  hover:bg-gray-50  hover:border-l-4 hover:border-l-blue-600 hover:cursor-pointer`}>
              <ReceiptLong className="text-gray-600 mr-3" />
              Sales Orders
            </li>
          </Link>
          <Link to="/generate-ready-containers">
            <li className="flex flex-col text-[14px] text-gray-600">
              <span
                className={`${
                  activePage === 3
                    ? "border-l-4 bg-gray-50 border-l-blue-600"
                    : ""
                } flex py-4 px-5 items-center mb-2   text-[14px] text-gray-600  hover:bg-gray-50  hover:border-l-4 hover:border-l-blue-600 hover:cursor-pointer`}>
                <Inventory className="text-gray-600 mr-3" />
                Ready Containers
              </span>
            </li>
          </Link>
          <Link to="/ready-containers">
            <li className="flex flex-col text-[14px] text-gray-600">
              <span
                className={`${
                  activePage === 11
                    ? "border-l-4 bg-gray-50 border-l-blue-600"
                    : ""
                } flex py-4 px-5 items-center mb-2   text-[14px] text-gray-600  hover:bg-gray-50  hover:border-l-4 hover:border-l-blue-600 hover:cursor-pointer`}>
                <DirectionsBoat className="text-gray-600 mr-3" />
                Ready To be Shipped
              </span>
            </li>
          </Link>
          <Link to="/available-shipment">
            <li
              className={`${
                activePage === 4
                  ? "border-l-4 bg-gray-50 border-l-blue-600"
                  : ""
              } flex py-4 px-5 items-center mb-2   text-[14px] text-gray-600  hover:bg-gray-50  hover:border-l-4 hover:border-l-blue-600 hover:cursor-pointer`}>
              <LocalShipping className="text-gray-600 mr-3" />
              Available Shipments
            </li>
          </Link>
          <Link to="/shipment-price">
            <li
              className={`${
                activePage === 5
                  ? "border-l-4 bg-gray-50 border-l-blue-600"
                  : ""
              } flex py-4 px-5 items-center mb-2   text-[14px] text-gray-600  hover:bg-gray-50  hover:border-l-4 hover:border-l-blue-600 hover:cursor-pointer`}>
              <AttachMoney className="text-gray-600 mr-3" />
              Shipment Price
            </li>
          </Link>
          <Link to="/destination">
            <li
              className={`${
                activePage === 9
                  ? "border-l-4 bg-gray-50 border-l-blue-600"
                  : ""
              } flex py-4 px-5 items-center mb-2   text-[14px] text-gray-600  hover:bg-gray-50  hover:border-l-4 hover:border-l-blue-600 hover:cursor-pointer`}>
              <Map className="text-gray-600 mr-3" />
              Destinations
            </li>
          </Link>
          <Link to="/customers">
            <li
              className={`${
                activePage === 6
                  ? "border-l-4 bg-gray-50 border-l-blue-600"
                  : ""
              } flex py-4 px-5 items-center mb-2   text-[14px] text-gray-600  hover:bg-gray-50  hover:border-l-4 hover:border-l-blue-600 hover:cursor-pointer`}>
              <Person className="text-gray-600 mr-3" />
              Customers
            </li>
          </Link>
          <Link to="/shipping-companies">
            <li
              className={`${
                activePage === 7
                  ? "border-l-4 bg-gray-50 border-l-blue-600"
                  : ""
              } flex py-4 px-5 items-center mb-2   text-[14px] text-gray-600  hover:bg-gray-50  hover:border-l-4 hover:border-l-blue-600 hover:cursor-pointer`}>
              <Business className="text-gray-600 mr-3" />
              Shipping Companies
            </li>
          </Link>
          <Link to="/users">
            <li
              className={`${
                activePage === 10
                  ? "border-l-4 bg-gray-50 border-l-blue-600"
                  : ""
              } flex py-4 px-5 items-center mb-2   text-[14px] text-gray-600  hover:bg-gray-50  hover:border-l-4 hover:border-l-blue-600 hover:cursor-pointer`}>
              <People className="text-gray-600 mr-3" />
              Users
            </li>
          </Link>
        </ul>
        <div className="max-h-[calc(70vh-100px)] overflow-y-auto">
        <button onClick={() => LogoutFct()}>
          <div className="flex absolute bottom-20 items-center w-full pl-8 text-[14px] text-gray-600 py-3 hover:bg-slate-200 hover:text-white hover:cursor-pointer">
            <Logout className="mr-3" />
            Logout
          </div>
        </button>
        </div>
      </div> */}
    </>
  );
}
