/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Login from "./Pages/Login";
import AllProducts from "./Pages/AllProducts";
import AllAvailableProducts from "./Pages/AllAvailableProducts";
import OrderHistory from "./Pages/OrderHistory";
import { ToastContainer, toast } from "react-toastify";
import ReadyContainersPart2 from "./Pages/ReadyContainersPart2";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AddProduct from "./Pages/AddProduct";
import NotFound from "./Pages/NotFound";
import Lottie from "react-lottie";
import LoadingTruck from "./Components/Lottie/LoadingTruck.json";
import axios from "axios";
import AllShipmentPrice from "./Pages/AllShipmentPrice";
import AddShipmentPrice from "./Pages/AddShipmentPrice";
import ReadyContainers from "./Pages/ReadyContainers";
import ReadyContainersList from "./Pages/ReadyContainersList";
import AllUsers from "./Pages/AllUsers";
import Navbar from "./Components/Navbar/Navbar";
import Header from "./Components/Header/Header";
import MobileNavbar from "./Components/Navbar/MobileNavbar";
import Customers from "./Pages/Customers";
import AddShipment from "./Pages/AddShipment";
import AddUser from "./Pages/AddUser";
import Shipments from "./Pages/Shipments";
import ShippingCompanies from "./Pages/ShippingCompanies";
import AllDestination from "./Pages/AllDestinations";
import AddDestination from "./Pages/AddDestination";
import AddOrder from "./Pages/AddOrder";
import "react-toastify/dist/ReactToastify.css";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";

export default function MainComponent() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const location = useLocation();
  const [activePage, setActivePage] = useState(1);
  const [customers, setCustomers] = useState([]);
  const [salesOrders, setSalesOrders] = useState([]);
  const [shipmentPrices, setShipmentPrices] = useState([]);
  const [shippingCo, setShippingCo] = useState([]);
  const [products, setProducts] = useState([]);
  const [readyContainers, setReadyContainers] = useState([]);
  const [availableProducts, setAvailableProducts] = useState([]);
  const [availableShipments, setAvailableShipments] = useState([]);
  const [destination, setDestination] = useState([]);
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [containerTypes, setContainerTypes] = useState([]);
  const [readyToShip, setReadyToShip] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [Token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const root = window.location.href.includes("localhost")
    ? "http://localhost:8451"
    : "https://api.ubstrade.com";

  function updateReadyContainers() {
    axios
      .get(`${root}/readyContainers/update`)
      .then((response) => {
      setReadyContainers(response.data);

      })
      .catch((error) => {
        setReadyContainers([]);
        console.log(error);
      });
  }
  
  const urls = [
    "products",
    "availableProducts",
    "customers",
    "salesOrders",
    "shipmentsAvailable",
    "shipmentsPrices",
    "containers",
    "shippingCompanies",
    "destination",
    "readyContainers",
    "roles"
  ];

  function getProducts() {
    axios
      .get(`${root}/products/all`)
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        setProducts([]);
        console.log(error);
      });
  }

  function getShipments() {
    axios
      .get(`${root}/shipmentsAvailable/all`)
      .then((response) => {
        setAvailableShipments(response.data);
      })
      .catch((error) => {
        setAvailableShipments([]);
        console.log(error);
      });
  }

  function getCustomers() {
    axios
      .get(`${root}/customers/all`)
      .then((response) => {
        setCustomers(response.data);
      })
      .catch((error) => {
        setCustomers([]);
        console.log(error);
      });
  }

  function getUsers() {
    axios
      .get(`${root}/auth/users`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        setUsers([]);
        console.log(error);
      });
  }

  function getShippingCompanies() {
    axios
      .get(`${root}/shippingCompanies/all`)
      .then((response) => {
        setShippingCo(response.data);
      })
      .catch((error) => {
        setShippingCo([]);
        console.log(error);
      });
  }

  function getShipmentPrice() {
    axios
      .get(`${root}/shipmentsPrices/all`)
      .then((response) => {
        setShipmentPrices(response.data);
      })
      .catch((error) => {
        setShipmentPrices([]);
        console.log(error);
      });
  }

  function getAvailableProducts() {
    axios
      .get(`${root}/availableProducts/all`)
      .then((response) => {
        setAvailableProducts(response.data);
      })
      .catch((error) => {
        setAvailableProducts([]);
        console.log(error);
      });
  }

  function getSaleOrders() {
    axios
      .get(`${root}/salesOrders/all`)
      .then((response) => {
        setSalesOrders(response.data);
      })
      .catch((error) => {
        setSalesOrders([]);
        console.log(error);
      });
  }

  function getDestination() {
    axios
      .get(`${root}/destination/all`)
      .then((response) => {
        setDestination(response.data);
      })
      .catch((error) => {
        setDestination([]);
        console.log(error);
      });
  }

  function getReadyToShip() {
    axios
      .get(`${root}/readyContainers/all`)
      .then((response) => {
        setReadyToShip(response.data);
      })
      .catch((error) => {
        setReadyToShip([]);
        console.log(error);
      });
  }

  function getCurrentUser(){
    const uid = Cookies.get('uid').toString();
    if(uid){
      const data = {
        UserId: CryptoJS.AES.decrypt(uid, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8)
      }
      axios
        .post(`${root}/auth/current`, data)
        .then((response) => {
          setCurrentUser(response.data[0]);
          navigate('/products');
        })
        .catch((error) => {
          setCurrentUser(null);
          console.log(error);
        });
    }else{
      setCurrentUser(null)
    }
  }

  async function fetchData() {
    const data = await Promise.all(
      urls.map((url) =>
        axios.get(`${root}/${url}/all`).then((response) => ({
          [url]: response.data,
        }))
      )
    );
    setProducts(data[0]?.products);
    setAvailableProducts(data[1]?.availableProducts);
    setCustomers(data[2]?.customers);
    setSalesOrders(data[3]?.salesOrders);
    setAvailableShipments(data[4]?.shipmentsAvailable);
    setShipmentPrices(data[5]?.shipmentsPrices);
    setContainerTypes(data[6]?.containers);
    setShippingCo(data[7]?.shippingCompanies);
    setDestination(data[8]?.destination);
    setReadyToShip(data[9]?.readyContainers);
    setRoles(data[10]?.roles)
    setIsLoadingData(false);
  }

  function formatDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
            let tokenExpired = Cookies.get('token');
            let uid = Cookies.get('uid');
            setIsLoading(true);
            setToken(tokenExpired);
            if (tokenExpired && uid) {
              getCurrentUser();
            } else {
              // Token has expired, handle the expiration
              Cookies.remove('token');
              Cookies.remove('uid');
              navigate('/login');
            }
  }, []);

  useEffect(() => {
    if(Token && currentUser){
      setIsLoading(true);
      getUsers();
      fetchData();
      updateReadyContainers();
    }
  }, [Token, currentUser]);

  function sendEmail(formData){
    axios
      .post(`${root}/auth/send-email`, formData)
      .then((response) => {
      })
      .catch((error) => {
        console.log(error.response);
      });
}

  const [isNotFoundRoute, setIsNotFoundRoute] = useState(
    location.pathname === "*"
  );
  const registeredRoutes = [
    "/",
    "/customers",
    "/products",
    "/add-order",
    "/add-product",
    "/add-shipment",
    "/order-history",
    "/available-shipment",
    "/add-ready-containers",
    "/generate-ready-containers",
    "/destination",
    "/add-destination",
    "/ready-containers",
    "/available-products",
    "/shipping-companies",
    "/shipment-price",
    "/add-shipment-price",
    "/users",
    "/add-user",
  ];
  useEffect(() => {
    // Extract the current route from the location pathname
    const currentRoute = location.pathname.split("/");
    // Set the active state based on the current route
    if (currentRoute[1].includes("product")) {
      setActivePage(1);
    } else if (currentRoute[1].includes("order-history")) {
      setActivePage(2);
    } else if (currentRoute[1] === "generate-ready-containers") {
      setActivePage(3);
    }
    if (currentRoute[1].includes("available-shipment")) {
      setActivePage(4);
    }
    if (currentRoute[1].includes("shipment-price")) {
      setActivePage(5);
    } else if (currentRoute[1].includes("customers")) {
      setActivePage(6);
    } else if (currentRoute[1].includes("shipping-companies")) {
      setActivePage(7);
    } else if (currentRoute[1].includes("available-products")) {
      setActivePage(8);
    } else if(currentRoute[1].includes("destination")){
      setActivePage(9);
    }else if(currentRoute[1].includes("users")){
      setActivePage(10);
    }else if (currentRoute[1] === "ready-containers") {
      setActivePage(11);
    }
    //setActivePage(currentRoute);
  }, [location.pathname]);
  useEffect(() => {
    const currentRoute = window.location.pathname.split("/")[1];
    const isRouteRegistered = registeredRoutes.includes(`/${currentRoute}`);
    setIsNotFoundRoute(!isRouteRegistered);
  }, [window.location.pathname]);

  const LottieLoading = () => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: LoadingTruck,
    };

    return <Lottie options={defaultOptions} />;
  };

  useEffect(()=>{
    if(Token && currentUser && !isLoadingData){
      setIsLoading(false);
    }
  },[Token, isLoadingData, currentUser])

  const notify = (msg, type) => {
    if(type === 1){
      toast.success(msg, {
        position: "bottom-right"
      });
    }else if(type === 2){
      toast.error(msg, {
        position: "bottom-right"
      });
    }else{
      toast.warn(msg, {
        position: "bottom-right"
      });
    }
    
  };

  return (
    <div className="h-screen w-full">
      <ToastContainer
      />
      {Token ? (
        isLoading ? (
          <div className="flex flex-col items-center justify-center w-full h-full px-6">
            <div className="w-full h-1/3">
              <LottieLoading />
            </div>
            <span>Please hold while we fetch your data...</span>
          </div>
        ) : (
          <div className="relative bg-white flex flex-col w-full">
            {isMobileMenuOpen && !isNotFoundRoute ? (
              <MobileNavbar
                setCurrentUser={setCurrentUser}
                setIsMobileMenuOpen={setIsMobileMenuOpen}
                activePage={activePage}
                currentUser={currentUser}
                root={root}
                notify={notify}
              />
            ) : null}

            {/*Header*/}
            {!isNotFoundRoute && (
              <Header
                setIsMobileMenuOpen={setIsMobileMenuOpen}
                currentUser={currentUser}
                isMobileMenuOpen={isMobileMenuOpen}
              />
            )}
            {/* Sidebar */}
            <div
              className={
                isNotFoundRoute ? "" : "flex relative w-full h-screen"
              }>
              {!isNotFoundRoute && (
                <Navbar setCurrentUser={setCurrentUser} activePage={activePage} currentUser={currentUser}
                root={root} notify={notify} />
              )}
              <div
                className={
                  isNotFoundRoute
                    ? "bg-white"
                    : "h-full pt-16 bg-zinc-200 w-full overflow-y-scroll"
                }>
                <div className={isNotFoundRoute ? "" : "p-4 px-8 "}>
                  <Routes>
                    <Route
                      path="/customers"
                      element={<Customers currentUser={currentUser} customers={customers} getCustomers={getCustomers} notify={notify} formatDate={formatDate}/>}
                    />
                    <Route
                      path="/available-shipment"
                      element={
                        <Shipments
                          availableShipments={availableShipments}
                          shippingCo={shippingCo}
                          currentUser={currentUser}
                          destination={destination}
                          getShipments={getShipments} notify={notify}
                        />
                      }
                    />
                    <Route
                      path="/add-shipment"
                      element={
                        <AddShipment
                          shippingCo={shippingCo}
                          availableShipments={availableShipments}
                          formatDate={formatDate}
                          destination={destination}
                          currentUser={currentUser}
                          getShipments={getShipments} 
                          notify={notify}

                        />
                      }
                    />
                    <Route
                      path="/generate-ready-containers"
                      element={
                        <ReadyContainersPart2
                          notify={notify}
                          getAvailableProducts={getAvailableProducts}
                          sendEmail={sendEmail}
                          setReadyContainers={setReadyContainers}
                          getReadyToShip={getReadyToShip}
                          key={JSON.stringify(readyContainers)}
                          updateReadyContainers={updateReadyContainers}
                          readyContainers={readyContainers}
                          availableShipments={availableShipments}
                          products={products}
                          currentUser={currentUser}
                          getSaleOrders={getSaleOrders}
                          formatDate={formatDate}
                          containerTypes={containerTypes}
                          destination={destination}
                          salesOrders={salesOrders}
                          customers={customers}
                          shipmentPrices={shipmentPrices}
                          shippingCo={shippingCo}
                        />
                      }
                    />
                    <Route
                      path="/add-ready-containers"
                      element={
                        <ReadyContainers
                          readyToShip={readyToShip}
                          products={products}
                          setReadyContainers={setReadyContainers}
                          readyContainers={readyContainers}
                          salesOrders={salesOrders}
                          customers={customers}
                          shipmentPrices={shipmentPrices}
                          shippingCo={shippingCo}
                          containerTypes={containerTypes}
                        />
                      }
                    />
                    <Route
                      path="/ready-containers"
                      element={
                        <ReadyContainersList
                          readyToShip={readyToShip}
                          products={products}
                          orders={salesOrders}
                          formatDate={formatDate}
                          customers={customers}
                          availableShipments={availableShipments}
                          shipmentPrice={shipmentPrices}
                          shipments={shippingCo}
                          destination={destination}
                          containers={containerTypes}
                        />
                      }
                    />
                    <Route
                      path="/available-products"
                      element={
                        <AllAvailableProducts
                          availableProducts={availableProducts}
                          products={products}
                          currentUser={currentUser}
                          formatDate={formatDate}
                          getAvailableProducts={getAvailableProducts} notify={notify}
                        />
                      }
                    />
                    <Route
                      path="/add-order"
                      element={
                        <AddOrder
                          destination={destination}
                          sendEmail={sendEmail}
                          formatDate={formatDate}
                          customers={customers}
                          currentUser={currentUser}
                          salesOrders={salesOrders}
                          products={products}
                          containerTypes={containerTypes}
                          getSaleOrders={getSaleOrders} 
                          notify={notify}
                        />
                      }
                    />
                    <Route
                      path="/shipping-companies"
                      element={
                        <ShippingCompanies currentUser={currentUser} shippingCompanies={shippingCo} getShippingCompanies={getShippingCompanies} notify={notify} formatDate={formatDate}/>
                      }
                    />
                    <Route
                      path="/products"
                      element={<AllProducts products={products} currentUser={currentUser} getProducts={getProducts} notify={notify}/>}
                    />
                    <Route path="/add-product" element={<AddProduct products={products} currentUser={currentUser} formatDate={formatDate} getProducts={getProducts} notify={notify}/>} />
                    <Route
                      path="/order-history"
                      element={
                        <OrderHistory
                          root={root}
                          currentUser={currentUser}
                          getSaleOrders={getSaleOrders}
                          notify={notify}
                          destination={destination}
                          containerTypes={containerTypes}
                          salesOrders={salesOrders}
                          products={products}
                          customers={customers}
                        />
                      }
                    />
                    <Route
                      path="/shipment-price"
                      element={
                        <AllShipmentPrice
                          getShipmentPrice={getShipmentPrice}
                          currentUser={currentUser}
                          notify={notify}
                          shippingCo={shippingCo}
                          containerTypes={containerTypes}
                          customers={customers}
                          destination={destination}
                          shipmentPrices={shipmentPrices}
                        />
                      }
                    />
                    <Route
                      path="/add-shipment-price"
                      element={
                        <AddShipmentPrice
                          notify={notify}
                          formatDate={formatDate}
                          shipmentPrices={shipmentPrices}
                          currentUser={currentUser}
                          getShipmentPrice={getShipmentPrice}
                          containerTypes={containerTypes}
                          shippingCo={shippingCo}
                          destination={destination}
                        />
                        }
                        />
                        <Route
                      path="/destination"
                      element={
                        <AllDestination
                          getDestination={getDestination}
                          currentUser={currentUser}
                          notify={notify}
                          destination={destination}
                        />
                      }
                    />
                    <Route
                      path="/add-destination"
                      element={
                        <AddDestination
                          notify={notify}
                          destinations={destination}
                          currentUser={currentUser}
                          formatDate={formatDate}
                          getDestination={getDestination}
                        />
                      }
                    />
                    <Route
                      path="/users"
                      element={
                        <AllUsers root={root} currentUser={currentUser} notify={notify} users={users} roles={roles} getUsers={getUsers} />
                      }
                    />
                    <Route
                      path="/add-user"
                      element={
                        <AddUser
                          notify={notify}
                          users={users}
                          roles={roles}
                          currentUser={currentUser}
                          formatDate={formatDate}
                          getUsers={getUsers}
                        />
                      }
                    />
                    <Route
                      path="/login"
                      element={
                        <Login root={root} setIsLoadingParent={setIsLoading} setToken={setToken} setCurrentUser={setCurrentUser} />
                      }
                    />
                    <Route path="*" element={<NotFound />} replace />
                  </Routes>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <Login root={root} setIsLoadingParent={setIsLoading} setToken={setToken} setCurrentUser={setCurrentUser} />
      )}
    </div>
  );
}
