import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Home } from "@mui/icons-material";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import Select from "react-dropdown-select";
import axios from "axios";
import LoadingTruck from "../Components/Lottie/LoadingTruck.json";
export default function AddUser({ notify, getUsers, formatDate, roles }) {
    const location = useLocation();
    const { user } = location.state
    const [formData, setFormData] = useState({
        UserId: user ? user.UserId : null, 
        FirstName: user ? user.FirstName : '', 
        LastName: user ? user.LastName : '',
        Address: user ? user.Address : '',
        PhoneNo: user ? user.PhoneNo : '',  
        RoleId: user ? user.RoleId : null,  
        Email: user ? user.Email : '', 
        DoB: user ? user.DoB :formatDate(),
        StatusId: user ? user.StatusId : 1, 
  });
  const [isLoading, setIsLoading] = useState(false);
  const MyLottieAnimation = () => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: LoadingTruck,
    };
  
    return (
      <Lottie options={defaultOptions} />
    );
  };
  const navigate = useNavigate();
  const root = window.location.href.includes("localhost")
    ? "http://localhost:8451"
    : "https://api.ubstrade.com";
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(formData.RoleId === null){
      notify("Please select the user's role", 3);
    }else{
    setIsLoading(true);
    axios
    .post(`${root}/auth/user/add`,
      formData
    )
      .then((response) => {
        getUsers();
        notify("Added user successfully", 1);
        setIsLoading(false);
        navigate("/users");
        
      })
      .catch((error) => {
        notify("Error user product", 2);
        console.log(error);
        setIsLoading(false);
      });
    }
  }

  return (
<div className="relative flex flex-col h-full">
          <h1 className="flex pb-4 text-xl">
            <Link to="/users">
              <Home className="text-gray-600 mr-2" />
            </Link>
            <span> / User / </span>
            <span className="text-blue-600 ml-2">
              {user ? "Edit" : "Add"} User
            </span>
          </h1>
          <form className="flex flex-col w-full text-[17px]" onSubmit={handleSubmit}>
            <div className="flex flex-col lg:flex-row lg:items-center">
              <span className="w-full lg:w-[18%]">
                First Name <span className="text-red-500">*</span>
              </span>
              <input
                type="text"
                required
                placeholder="First name"
                defaultValue={user ? user.FirstName : ""}
                className="w-full md:w-[45%] lg:w-[35%] border-2 border-gray-300 p-2 my-4 rounded-lg focus:outline-none focus:border-blue-600"
                onChange={(e) =>
                  setFormData({ ...formData, FirstName: e.target.value})
                }
              />
            </div>
            <div className="flex flex-col lg:flex-row lg:items-center">
              <span className="w-full lg:w-[18%]">
                Last Name <span className="text-red-500">*</span>
              </span>
              <input
                type="text"
                placeholder="Last name"
                required
                defaultValue={user ? user.LastName : ""}
                className="w-full md:w-[45%] lg:w-[35%] border-2 border-gray-300 p-2 my-4 rounded-lg focus:outline-none focus:border-blue-600"
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    LastName: e.target.value,
                  })
                }
              />
            </div>
            <div className="flex flex-col lg:flex-row lg:items-center">
              <span className="w-full lg:w-[18%]">
                Email <span className="text-red-500">*</span>
              </span>
              <input
                defaultValue={user ? user.Email : ""}
                placeholder="Email"
                required
                className="w-full resize-none md:w-[45%] lg:w-[35%] leading-3 border-2 border-gray-300 p-2 my-4 rounded-lg focus:outline-none focus:border-blue-600"
                onChange={(e) =>
                  setFormData({ ...formData, Email: e.target.value })
                } />
            </div>
            <div className="flex flex-col lg:flex-row lg:items-center my-2">
          <span className="w-full lg:w-[18%]">User Role <span className="text-red-500">*</span></span>
          <div className="w-full md:w-[45%] lg:w-[35%] rounded-lg">
            <Select
              labelField="RoleName"
              valueField="RoleId"
              multi={false}
              dropdownHeight="160px"
              placeholder={user ? roles?.find((option) => option.RoleId === user?.RoleId)?.RoleName : "User's role"}
              style={{
                backgroundColor: "#f9fafb",
                border: "2px solid #d1d5db",
                borderRadius: "0.5rem",
                padding: "0.5rem",
                margin: "0.5rem 0",
                boxShadow: "0 0 0 0",
              }}
              options={roles}
              onChange={(e) =>
                setFormData({ ...formData, RoleId: e[0].RoleId })
              }
            />
          </div>
        </div>
            <div className="flex flex-col lg:flex-row lg:items-center">
              <span className="w-full lg:w-[18%]">
                Phone Number
              </span>
              <input
                defaultValue={user ? user.PhoneNo : ""}
                placeholder="Phone number"
                className="w-full resize-none md:w-[45%] lg:w-[35%] leading-3 border-2 border-gray-300 p-2 my-4 rounded-lg focus:outline-none focus:border-blue-600"
                onChange={(e) =>
                  setFormData({ ...formData, PhoneNo: e.target.value })
                } />
            </div>
            <div className="flex flex-col lg:flex-row lg:items-center">
              <span className="w-full lg:w-[18%]">
                Date of birth
              </span>
              <input
                type="date"
                defaultValue={user ? user.DoB : ""}
                placeholder="Date of birth"
                className="w-full resize-none md:w-[45%] lg:w-[35%] leading-3 border-2 border-gray-300 p-2 my-4 rounded-lg focus:outline-none focus:border-blue-600"
                onChange={(e) =>
                  setFormData({ ...formData, DoB: e.target.value })
                } />
            </div>
            <div className="flex flex-col lg:flex-row lg:items-center">
              <span className="w-full lg:w-[18%]">
                Address
              </span>
              <input
                defaultValue={user ? user.Address : ""}
                placeholder="Address"
                className="w-full resize-none md:w-[45%] lg:w-[35%] leading-3 border-2 border-gray-300 p-2 my-4 rounded-lg focus:outline-none focus:border-blue-600"
                onChange={(e) =>
                  setFormData({ ...formData, Address: e.target.value })
                } />
            </div>
            <div className="flex flex-col lg:flex-row lg:items-center mt-2">
          <span className="w-full lg:w-[18%]">Status <span className="text-red-500">*</span></span>
          <div>
            <label>Active</label>
            <input
              type="radio"
              className="ml-2 mr-6 checked:bg-blue-500"
              value={1}
              checked={formData.StatusId === 1}
              onClick={(e) =>
                setFormData({ ...formData, StatusId: 1 })
              }
            />
          </div>
          <div>
            <label>Inactive</label>
            <input
              type="radio"
              className="ml-2 mr-6 checked:bg-blue-500"
              value={2}
              checked={formData.StatusId === 2}
              onClick={(e) =>
                setFormData({ ...formData, StatusId: 2 })
              }
            />
          </div>
        </div>
            <div className="w-full md:w-[45%] lg:w-[53%] mt-5 flex items-center justify-end">
              <Link to="/users" className="">
                <button
                  type="reset"
                  disabled={isLoading}
                  className="rounded-2xl text-white bg-blue-600 px-4 py-2 shadow-md hover:text-blue-600 hover:bg-white transition duration-200 ease-in">
                  Cancel
                </button>
              </Link>
              <button
                type="submit"
                disabled={isLoading}
                className="rounded-2xl ml-8 text-white bg-blue-600 px-4 py-2 shadow-md hover:text-blue-600 hover:bg-white transition duration-200 ease-in">
                {isLoading ? <div className="h-8"><MyLottieAnimation /></div> : user ? "Edit" : "Add"}
              </button>
            </div>
          </form>
        </div>
  );
}
