import React, { useState, useEffect } from "react";
import { Home } from "@mui/icons-material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Select from "react-dropdown-select";
import Lottie from "react-lottie";
import axios from "axios";
import LoadingTruck from "../Components/Lottie/LoadingTruck.json";

export default function AddOrder({
  customers,
  sendEmail,
  salesOrders,
  products,
  containerTypes,
  currentUser,
  destination,
  getSaleOrders,
  notify,
  formatDate,
}) {
  const location = useLocation();
  const { order } = location.state;
  const [formData, setFormData] = useState({
    OrderId: order ? order?.OrderId : null,
    OrderNo: order ? order?.OrderNo : "",
    OrderDate: order
      ? order?.OrderDate
      : new Date().toISOString().split("T")[0],
    CustomerId: order ? order?.CustomerId : null,
    ProductId: order ? order?.ProductId : null,
    Quantity: order ? order?.Quantity : "",
    QuantityLeft: order ? order?.Quantity : "",
    Destination: order ? order?.Destination : null,
    Price: order ? order?.Price : "",
    ContainerType: order ? order?.ContainerType : null,
    AddedBy: order ? order?.AddedBy : currentUser?.UserId,
    AddedAt: order ? order?.AddedAt : formatDate(),
    OrderStatusId: order ? order?.OrderStatusId : 1,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [alteredDes, setAlteredDes] = useState([]);

  const navigate = useNavigate();
  const root = window.location.href.includes("localhost")
    ? "http://localhost:8451"
    : "https://api.ubstrade.com";

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.ProductId === null) {
      notify("Please select a product", 3);
    } else if(formData.CustomerId === null) {
      notify("Please select a customer", 3);
    }else if(formData.Destination === null) {
      notify("Please select a destination", 3);
    }else if(formData.ContainerType === null) {
      notify("Please select a container type", 3);
    }
    else {
      let isOrderFound = false;
      if (order) {
        salesOrders?.find((p) => {
          return (
            p?.OrderId !== formData?.OrderId &&
            p?.OrderNo?.toLowerCase().trim() ===
              formData?.OrderNo?.toLowerCase().trim()
          );
        });
      } else {
        salesOrders?.find((p) => {
          return (
            p?.OrderNo?.toLowerCase().trim() ===
            formData?.OrderNo?.toLowerCase().trim()
          );
        });
      }

      if (isOrderFound) {
        notify("This order already exists", 3);
      } else {
        setIsLoading(true);
        axios
          .post(`${root}/salesOrders/add`, formData)
          .then((response) => {
            var emailData = {
              subject: "Order Added",
              body: `Order No ${formData?.OrderNo} was successfully added`,
              receivers: [
                "mira13.mc@gmail.com, mirac@nicgroup.co, Mariamk@nicgroup.co",
              ],
              host: process.env.REACT_APP_HOST,
              port: process.env.REACT_APP_PORT,
              user: process.env.REACT_APP_EMAIL_ADDRESS,
              password: process.env.REACT_APP_PASSWORD,
            };
            sendEmail(emailData);
            getSaleOrders();
            if (order) {
              notify("Updated order successfully", 1);
            } else {
              notify("Added order successfully", 1);
            }
            setIsLoading(false);
            navigate("/order-history");
          })
          .catch((error) => {
            notify("Error adding order", 2);
            console.log(error);
            setIsLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    if (destination) {
      const destinationOptions = destination.map((option) => ({
        DestinationId: option.DestinationId,
        label: `${option.Country} / ${option.Port}`,
      }));
      setAlteredDes(destinationOptions);
    }
  }, [destination]);

  const [alteredProd, setAlteredProd] = useState([]);

  useEffect(() => {
    if (products) {
      const destinationOptions = products.map((option) => ({
        ProductId: option?.ProductId,
        label: `${option?.ProductName} / ${option?.ProductNameArabic}`,
      }));
      setAlteredProd(destinationOptions);
    }
  }, [products]);

  const MyLottieAnimation = () => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: LoadingTruck,
    };

    return <Lottie options={defaultOptions} />;
  };
  return (
    <div className="relative flex flex-col h-full">
      <h1 className="flex pb-4 text-xl">
        <Link to="/order-history">
          <Home className="text-gray-600 mr-2" />
        </Link>
        <span> / Orders / </span>
        <span className="text-blue-600 ml-2">
          {order ? "Edit" : "Add"} Order
        </span>
      </h1>
      <form
        className="flex flex-col w-full text-[17px]"
        onSubmit={handleSubmit}>
        <div className="flex flex-col lg:flex-row items-center">
          <span className="w-full lg:w-[25%]">
            Order Number <span className="text-red-500">*</span>
          </span>
          <input
            type="text"
            required
            defaultValue={order ? order?.OrderNo : ""}
            placeholder="Order Number"
            className="w-full lg:w-[40%] border-2 border-gray-300 p-2 my-4 rounded-lg focus:outline-none focus:border-blue-600"
            onChange={(e) =>
              setFormData({ ...formData, OrderNo: e.target.value })
            }
          />
        </div>
        <div className="flex flex-col lg:flex-row items-center">
          <span className="w-full lg:w-[25%]">
            Order Date <span className="text-red-500">*</span>
          </span>
          <input
            type="date"
            required
            defaultValue={
              order ? order.OrderDate : new Date().toISOString().split("T")[0]
            }
            className="w-full lg:w-[40%] border-2 border-gray-300 p-2 my-4 rounded-lg focus:outline-none focus:border-blue-600"
            onChange={(e) =>
              setFormData({ ...formData, OrderDate: e.target.value })
            }
          />
        </div>
        <div className="flex flex-col lg:flex-row items-center mt-2">
          <span className="w-full lg:w-[25%]">
            Select the customer's name <span className="text-red-500">*</span>
          </span>
          <div className="w-full lg:w-[40%] rounded-lg">
            <Select
              labelField="CustomerName"
              valueField="CustomerId"
              multi={false}
              dropdownHeight="160px"
              placeholder={
                order
                  ? customers.find(
                      (cust) => cust?.CustomerId === order?.CustomerId
                    )?.CustomerName
                  : "Select a customer "
              }
              style={{
                backgroundColor: "#f9fafb",
                border: "2px solid #d1d5db",
                borderRadius: "0.5rem",
                padding: "0.5rem",
                margin: "0.5rem 0",
                boxShadow: "0 0 0 0",
              }}
              options={customers}
              onChange={(e) =>
                setFormData({ ...formData, CustomerId: e[0].CustomerId })
              }
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center mt-4">
          <span className="w-full lg:w-[25%]">
            Select the products <span className="text-red-500">*</span>
          </span>
          <div className="w-full lg:w-[40%] rounded-lg">
            <Select
              labelField="label"
              valueField="ProductId"
              multi={false}
              dropdownHeight="160px"
              placeholder={
                order
                  ? alteredProd.find(
                      (prod) => prod?.ProductId === order?.ProductId
                    )?.label
                  : "Select a product "
              }
              style={{
                backgroundColor: "#f9fafb",
                border: "2px solid #d1d5db",
                borderRadius: "0.5rem",
                padding: "0.5rem",
                margin: "0.5rem 0",
                boxShadow: "0 0 0 0",
              }}
              options={alteredProd}
              onChange={(e) =>
                setFormData({ ...formData, ProductId: e[0].ProductId })
              }
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center mt-2">
          <span className="w-full lg:w-[25%]">
            Quantity <span className="text-red-500">*</span>
          </span>
          <input
            type="text"
            placeholder="Quantity"
            required
            defaultValue={order ? order?.Quantity : ""}
            className="w-full lg:w-[40%] border-2 border-gray-300 p-2 my-4 rounded-lg focus:outline-none focus:border-blue-600"
            onChange={(e) =>
              setFormData({
                ...formData,
                Quantity: e.target.value,
                QuantityLeft: e.target.value,
              })
            }
          />
        </div>
        <div className="flex flex-col lg:flex-row items-center">
          <span className="w-full lg:w-[25%]">
            Price <span className="text-red-500">*</span>
          </span>
          <input
            type="text"
            required
            placeholder="Price"
            defaultValue={order ? order?.Price : ""}
            className="w-full lg:w-[40%] border-2 border-gray-300 p-2 my-4 rounded-lg focus:outline-none focus:border-blue-600"
            onChange={(e) =>
              setFormData({ ...formData, Price: e.target.value })
            }
          />
        </div>
        <div className="flex flex-col lg:flex-row items-center mt-2">
          <span className="w-full lg:w-[25%]">
            Destination <span className="text-red-500">*</span>
          </span>
          <div className="w-full lg:w-[40%] rounded-lg">
            <Select
              labelField="label"
              valueField="DestinationId"
              dropdownHeight="160px"
              multi={false}
              placeholder={
                order
                  ? alteredDes.find(
                      (des) => des?.DestinationId === order?.Destination
                    )?.label
                  : "Select a destination "
              }
              style={{
                backgroundColor: "#f9fafb",
                border: "2px solid #d1d5db",
                borderRadius: "0.5rem",
                padding: "0.5rem",
                margin: "0.5rem 0",
                boxShadow: "0 0 0 0",
              }}
              options={alteredDes}
              onChange={(e) =>
                setFormData({ ...formData, Destination: e[0].DestinationId })
              }
            />
          </div>
        </div>
        <div className="flex flex-col lg:flex-row items-center mt-4">
          <span className="w-full lg:w-[25%]">
            Select the container type <span className="text-red-500">*</span>
          </span>
          <div className="w-full lg:w-[40%] rounded-lg">
            <Select
              labelField="ContainerTypeName"
              valueField="ContainerTypeId"
              dropdownHeight="160px"
              multi={false}
              placeholder={
                order
                  ? containerTypes.find(
                      (option) =>
                        option.ContainerTypeId === order?.ContainerType
                    )?.ContainerTypeName
                  : "Select a container type"
              }
              style={{
                backgroundColor: "#f9fafb",
                border: "2px solid #d1d5db",
                borderRadius: "0.5rem",
                padding: "0.5rem",
                margin: "0.5rem 0",
                boxShadow: "0 0 0 0",
              }}
              options={containerTypes}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  ContainerType: e[0].ContainerTypeId,
                })
              }
            />
          </div>
        </div>
        <div className="w-full mt-4 lg:w-[65%] flex items-center justify-end">
          <Link to="/order-history">
            <button
              type="reset"
              disabled={isLoading}
              className="rounded-2xl text-white bg-blue-600 px-4 py-2 shadow-md hover:text-blue-600 hover:bg-white transition duration-200 ease-in">
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            disabled={isLoading}
            className="rounded-2xl text-white bg-blue-600 ml-8 px-4 py-2 shadow-md hover:text-blue-600 hover:bg-white transition duration-200 ease-in">
            {isLoading ? (
              <div className="h-8">
                <MyLottieAnimation />
              </div>
            ) : order ? (
              "Edit"
            ) : (
              "Add"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
