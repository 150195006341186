import React, { useState, useEffect, useRef } from "react";
import Popup from "reactjs-popup";
import Lottie from "react-lottie";
import { ArrowBack } from "@mui/icons-material";
import LoadingTruck from "../../Components/Lottie/LoadingTruck.json";
const AddReadyContainer = ({
  isOpen,
  setIsOpen,
  containerQuantity,
  customOp,
  shippingCompanies,
  shipmentPrices,
  products,
  destinations,
  containers,
  customers,
  selectedShip,
  selectedProd,
  notify,
  handleValidateOrders,
}) => {
  const [containerNo, setContainerNo] = useState(null);
  const [quantity, setQuantity] = useState("");
  const [orderNo, setOrderNo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showSummary, setShowSummary] = useState(false);

  function generateRandomOrderNumber() {
    return Math.floor(100000 + Math.random() * 900000).toString();
  }

  useEffect(()=>{
    if(selectedShip && selectedProd){
      setSelectedOrder(selectedProd?.salesOrders?.find((order) => order?.OrderId === selectedShip?.OrderId ))
    }
  },[selectedShip, selectedProd])

  const handleShowSummary = (e) => {
    e.preventDefault();
    if (parseInt(quantity) === 0) {
      setIsLoading(false);
      notify("Quantity must be greater than zero", 3);
    } else if (parseInt(quantity) > parseInt(containerQuantity)) {
      setIsLoading(false);
      notify(`Quantity can't be greater than ${containerQuantity}`, 3);
    } else {
      setOrderNo(generateRandomOrderNumber());
      setShowSummary(true);
    }
  };

  const handleSubmit = () => {
    handleValidateOrders(quantity, orderNo, containerNo);
  };

  const MyLottieAnimation = () => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: LoadingTruck,
    };

    return <Lottie options={defaultOptions} />;
  };

  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popupRef.current &&
        !event.target.matches("input") &&
        !event.target.closest('.react-dropdown-select') &&
        !event.target.closest('#submit') &&
        !event.target.closest('#back') &&
        !event.target.closest('#popup')
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, setIsOpen, popupRef]);
  
  return (
    <div
      style={{ display: isOpen ? "block" : "none" }}
      className="popup-overlay bg-[#1F293A66]">
      <Popup
        modal
        ref={popupRef}
        open={isOpen}
        >
        {() => (
          <div id="popup" className="bg-white h-[60vh] min-w-[30vw] overflow-y-scroll p-6 rounded-lg shadow-lg">
            {showSummary ? (
              <>
                <button id="back" onClick={()=>setShowSummary(false)} className="text-blue-500 hover:text-blue-700" ><ArrowBack />{" "} Back</button>
                <h2 className="text-lg font-bold my-2">
                  Ready Container Summary
                </h2>
                <div className="mb-4 text-[17px] flex flex-col gap-y-4">
                  <p className="font-bold text-gray-500">Order #<span>{orderNo}</span></p>
                  <p><span className="text-gray-500 font-semibold">Product:</span><span>{" "}{products?.find((p) => p.ProductId === selectedProd?.ProductId)?.ProductName} / {products?.find((p) => p.ProductId === selectedProd?.ProductId)?.ProductNameArabic}</span></p>
                  <p><span className="text-gray-500 font-semibold">Quantity:</span><span>{" "}{quantity} kg</span></p>
                  <p><span className="text-gray-500 font-semibold">Customer:</span><span>{" "}{customers?.find((c) => c.CustomerId === selectedOrder?.CustomerId)?.CustomerName ?? 'N/A'}</span></p>
                  <p><span className="text-gray-500 font-semibold">Number of Containers:</span><span>{" "}{containerNo}</span></p>
                  <p><span className="text-gray-500 font-semibold">Container Type:</span><span>{" "}{containers?.find((c) => c.ContainerTypeId === selectedOrder?.ContainerType)?.ContainerTypeName}</span></p>
                  <p><span className="text-gray-500 font-semibold">Sale Order No:</span><span>{" "}{selectedOrder?.OrderNo ?? "N/A"}</span></p>
                  <p><span className="text-gray-500 font-semibold">Shipping Company:</span><span>{" "}{shippingCompanies?.find((comp) => comp.CompanyId === selectedShip?.CompanyId)?.CompanyName}</span></p>
                  <p><span className="text-gray-500 font-semibold">Shipment Price:</span><span>{" "}{shipmentPrices?.find((comp) => comp.ShipmentId === selectedShip?.ShipmentType)?.Price}</span></p>
                  <p><span className="text-gray-500 font-semibold">Destination:</span><span>{" "}{destinations?.find((des) => des.DestinationId === selectedOrder?.Destination)?.Country} / {destinations?.find((des) => des.DestinationId === selectedOrder?.Destination)?.Port}</span></p>
                </div>

                <div className="flex justify-end">
                <button
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-4"
                    onClick={() => {
                      setIsOpen(false);
                      setIsLoading(false);
                    }}>
                    Cancel
                  </button>
                  <button
                    id="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    onClick={handleSubmit}>
                    {isLoading ? (
                      <div className="w-8">
                        <MyLottieAnimation />
                      </div>
                    ) : (
                      <span>Submit</span>
                    )}
                  </button>
                </div>
              </>
            ) : (
              <form onSubmit={handleShowSummary}>
                <h2 className="text-lg font-bold mb-4">
                  Select Quantity and Number of containers
                </h2>
                <div className="mb-4">
                  <label
                    htmlFor="quantity"
                    className="block text-gray-700 font-bold mb-2">
                    Quantity: <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="quantity"
                    required
                    placeholder="quantity"
                    className="border-2 border-gray-300 p-2 rounded-lg w-full"
                    defaultValue={quantity}
                    onChange={(e)=>
                        setQuantity(e.target.value) 
                    }
                    onKeyDown={(e)=>{
                      if (e.key === "Backspace") {
                        } else if (!/[0-9]/.test(e.key)) {
                          // Prevent non-numeric input
                          notify(
                            "Please enter a valid number",
                            3
                          );
                          e.preventDefault();
                        }
                    }}
                  />
                </div>
                <div className="mb-4">
                  <label
                    htmlFor="containerNo"
                    className="block text-gray-700 font-bold mb-2">
                    How many containers?: <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    id="containerNo"
                    required
                    placeholder="number of container"
                    className="border-2 border-gray-300 p-2 rounded-lg w-full"
                    defaultValue={containerNo}
                    onKeyDown={(e) => {
                      // Check if the user is deleting input
                      if (["Delete", "Backspace"].includes(e.key)) {
                      } else if (/[1-3]/.test(e.key)) {
                      } else {
                        notify(
                          "Please enter a valid number no greater than 3",
                          3
                        );
                        e.preventDefault();
                      }
                    }}
                    onChange={(e)=>setContainerNo(e.target.value)}
                  />
                </div>
                <div className="flex justify-end">
                <button
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-4"
                    onClick={() => {
                      setIsOpen(false);
                      setIsLoading(false);
                    }}>
                    Cancel
                  </button>
                  <button
                    id="submit"
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                    >
                    {isLoading ? (
                      <div className="w-8">
                        <MyLottieAnimation />
                      </div>
                    ) : (
                      <span>View Summary</span>
                    )}
                  </button>
                </div>
              </form>
            )}
          </div>
        )}
      </Popup>
    </div>
  );
};

export default AddReadyContainer;
