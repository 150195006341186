/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import Popup from "reactjs-popup";
import Select from "react-dropdown-select";
const CustomizeReadyContainer = ({
  isOpen,
  setIsOpen,
  destinationList,
  salesOrders,
  containerTypes,
  containerQuantity,
  notify,
  customers,
  handleSubmitPopup,
}) => {
  const [destination, setDestination] = useState("");
  const [containerType, setContainerType] = useState("");
  const [quantity, setQuantity] = useState("");
  const [customerId, setCustomerId] = useState(null);
  const [alteredDes, setAlteredDes] = useState([]);

  useEffect(() => {
    if (destinationList) {
      const destinationOptions = destinationList.map((option) => ({
        DestinationId: option.DestinationId,
        label: `${option.Country} / ${option.Port}`,
      }));
      setAlteredDes(destinationOptions);
    }
  }, [destinationList]);

  const handleSubmit = () => {
    if (parseInt(quantity) === 0) {
      notify("Quantity must be greater than zero", 3);
    } else if (parseInt(quantity) > parseInt(containerQuantity)) {
      notify(`Quantity can't be greater than ${containerQuantity}`, 3);
    } else {
      let isOrderFound = false;
      if (customerId !== null) {
        isOrderFound = salesOrders?.find((p) => {
          return (
            p?.ContainerType === containerType &&
            p?.Destination === destination &&
            p?.Quantity === quantity
          );
        });
      } else {
        isOrderFound = salesOrders?.find((p) => {
          return (
            p?.ContainerType === containerType &&
            p?.Destination === destination &&
            p?.CustomerId === customerId &&
            p?.Quantity === quantity
          );
        });
      }
      if (isOrderFound) {
        notify("This order already exists", 3);
      } else {
        handleSubmitPopup(destination, containerType, quantity, customerId);
        handleClose();
      }
    }
  };

  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popupRef.current &&
        !event.target.matches("input") &&
        !event.target.closest(".react-dropdown-select") &&
        !event.target.closest("#submit") &&
        !event.target.closest("#popup")

        // !event.target.matches("button") &&
        // !event.target.tagName.toLowerCase() === 'button'
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, setIsOpen, popupRef]);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="popup-overlay bg-[#1F293A77]">
      <Popup ref={popupRef} modal open={isOpen}>
        {() => (
          <form
            id="popup"
            onSubmit={handleSubmit}
            className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-lg font-bold mb-4">
              Select Customer, destination, container type and quantity
            </h2>
            <div className="mb-4">
              <label
                htmlFor="customer"
                className="block text-gray-700 font-bold mb-2">
                Customer:
              </label>
              <Select
                labelField="CustomerName"
                valueField="CustomerId"
                dropdownHeight="160px"
                multi={false}
                placeholder="Select a customer"
                style={{
                  backgroundColor: "#f9fafb",
                  border: "2px solid #d1d5db",
                  borderRadius: "0.5rem",
                  padding: "0.5rem",
                  margin: "0.5rem 0",
                  boxShadow: "0 0 0 0",
                }}
                options={customers}
                onChange={(e) => setCustomerId(e[0].CustomerId)}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="destination"
                className="block text-gray-700 font-bold mb-2">
                Destination: <span className="text-red-500">*</span>
              </label>
              <Select
                labelField="label"
                valueField="DestinationId"
                dropdownHeight="160px"
                multi={false}
                required
                placeholder="Select a destination"
                style={{
                  backgroundColor: "#f9fafb",
                  border: "2px solid #d1d5db",
                  borderRadius: "0.5rem",
                  padding: "0.5rem",
                  margin: "0.5rem 0",
                  boxShadow: "0 0 0 0",
                }}
                options={alteredDes}
                onChange={(e) => setDestination(e[0].DestinationId)}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="containerType"
                className="block text-gray-700 font-bold mb-2">
                Container Type: <span className="text-red-500">*</span>
              </label>
              <Select
                labelField="ContainerTypeName"
                valueField="ContainerTypeId"
                required
                dropdownHeight="160px"
                multi={false}
                placeholder="Select a container"
                style={{
                  backgroundColor: "#f9fafb",
                  border: "2px solid #d1d5db",
                  borderRadius: "0.5rem",
                  padding: "0.5rem",
                  margin: "0.5rem 0",
                  boxShadow: "0 0 0 0",
                }}
                options={containerTypes}
                onChange={(e) => setContainerType(e[0].ContainerTypeId)}
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="quantity"
                className="block text-gray-700 font-bold mb-2">
                Quantity: <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="quantity"
                required
                placeholder="Enter quantity"
                className="border-2 border-gray-300 p-2 rounded-lg w-full"
                defaultValue={quantity}
                onKeyDown={(e) => {
                  // Check if the user is deleting input
                  if (["Delete", "Backspace"].includes(e.key)) {
                    setQuantity(e.target.value);
                  } else if (!/[0-9]/.test(e.key)) {
                    // Prevent non-numeric input
                    notify("Please enter a valid number", 3);
                    e.preventDefault();
                  } else {
                    setQuantity(e.target.value);
                  }
                }}
                onChange={(e) => setQuantity(e.target.value)}
              />
            </div>
            <div className="flex justify-end mt-4">
              <button
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-4"
                onClick={() => setIsOpen(false)}>
                Cancel
              </button>
              <button
                id="submit"
                type="submit"
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Submit
              </button>
            </div>
          </form>
        )}
      </Popup>
    </div>
  );
};

export default CustomizeReadyContainer;
