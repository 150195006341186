/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import Popup from "reactjs-popup";
import { Close } from "@mui/icons-material";
import Lottie from 'react-lottie';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Select from "react-dropdown-select";
import LoadingTruck from '../../Components/Lottie/LoadingTruck.json';
export default function AddAvailableProduct({
    selectedProduct,
    products,
    isOpen,
    currentUser,
    availableProducts,
    getAvailableProducts, 
    notify,
    formatDate,
    setIsOpen,
}) {
  const [formData, setFormData] = useState({
    AvailableProductId: selectedProduct ? selectedProduct.AvailableProductId : null,
    ProductId: selectedProduct ? selectedProduct.ProductId : null,
    Quantity: selectedProduct ? selectedProduct.Quantity : '',
    AddedAt: selectedProduct ? selectedProduct.AddedAt : formatDate(), 
    AddedBy: selectedProduct ? selectedProduct.AddedBy : currentUser?.UserId,
    ModifiedAt: formatDate()
  });
  const [isLoading, setIsLoading] = useState(false);
  const [alteredProd, setAlteredProd] = useState([]);

  useEffect(() => {
    if (products) {
      const destinationOptions = products.map((option) => ({
        ProductId: option?.ProductId,
        label: `${option?.ProductName} / ${option?.ProductNameArabic}`,
      }));
      setAlteredProd(destinationOptions);
    }
  }, [products]);
  const MyLottieAnimation = () => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: LoadingTruck,
    };
  
    return (
      <Lottie options={defaultOptions} />
    );
  };


  const navigate = useNavigate();
  const root = window.location.href.includes("localhost")
    ? "http://localhost:8451"
    : "https://api.ubstrade.com";
  const handleSubmit = async (e) => {
    e.preventDefault();
    if(formData.ProductId === null){
      notify("Please select a product", 3);
    }else{
      let isFound = false;
    if(selectedProduct){
      isFound =  availableProducts?.find((p) => {
        return p?.AvailableProductId !== formData?.AvailableProductId
        && p?.ProductId === formData?.ProductId;
      });
    }else{
      isFound = availableProducts?.find((p) => {
        return p?.ProductId === formData?.ProductId;
      });
    }

    if(isFound){
      notify("This product is already available",3);
    }else{
     setIsLoading(true);
     axios
      .post(`${root}/availableProducts/add`,
        formData
      )
        .then((response) => {
          getAvailableProducts();
        if(selectedProduct) {
            notify("Updated product successfully", 1);
          }else{
            notify("Added product successfully", 1);
          }
          setIsLoading(false);
          navigate("/available-products");
          setIsOpen(false);
        })
        .catch((error) => {
          notify("Error adding product", 2);
          console.log(error);
          setIsLoading(false);
        });
    }
    }
    
  }

  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popupRef.current &&
        !event.target.matches("input") &&
        !event.target.closest('.react-dropdown-select') &&
        !event.target.closest('#submitBtn') &&
        !event.target.closest('#popup')
      ) {
        console.log("outside");
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, popupRef]);

  return (
    <div style={{ display: isOpen ? 'inline-block' : 'none' }} className="popup-overlay bg-[#1F293A30]">
      <Popup modal open={isOpen} ref={popupRef} >
        {(close) => (
          <form id="popup" onSubmit={handleSubmit} className="relative p-5 w-full lg:w-[25vw] bg-white flex flex-col border-2 border-blue-100 rounded-lg">
            <Close
              className="absolute right-0 top-1 mr-4 mt-2 hover:cursor-pointer"
              disabled={isLoading}
              onClick={() => {setIsOpen(false);}}
            />
            <div className="w-full">
          <span className="w-full">Select the product <span className="text-red-500">*</span></span>
          <div className="w-full rounded-lg">
            <Select
              labelField="label"
              valueField="ProductId"
              multi={false}
              searchable={true}
              keepSelectedInList={false}
              dropdownHeight="160px"
              placeholder={selectedProduct ? alteredProd.find((prod) => prod?.ProductId === selectedProduct?.ProductId)?.label : 'Select a product '}
              style={{
                backgroundColor: "#f9fafb",
                border: "2px solid #d1d5db",
                borderRadius: "0.5rem",
                padding: "0.5rem",
                margin: "0.5rem 0",
                boxShadow: "0 0 0 0",
              }}
              options={alteredProd}
              onChange={(e) =>
                setFormData({ ...formData, ProductId: e[0].ProductId})
              }
            />
          </div>
        </div>
            <span className="w-full mt-4">
              Quantity <span className="text-red-500">*</span>
            </span>
            <input
              type="text"
              required
              defaultValue={selectedProduct ? selectedProduct.Quantity : ""}
              placeholder="Product quantity"
              className="w-full border-2 border-gray-300 p-2 my-4 rounded-lg focus:outline-none focus:border-blue-600"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  Quantity: e.target.value,
                })
              }
            />
            <button id="submitBtn" type="submit" disabled={isLoading} className="place-self-end w-14 rounded-2xl right-0 flex text-white bg-blue-600 p-2 items-center justify-center shadow-md hover:bg-blue-900 transition duration-200 ease-in">
              {isLoading ? <MyLottieAnimation /> : <span>{selectedProduct ? 'Edit' : 'Add'}</span>}
            </button>
          </form>
        )}
      </Popup>
    </div>
  );
}
