import React, { useState, useEffect } from 'react';
import logo from '../../assets/images/Logo.png';

import {
  Menu
} from "@mui/icons-material";
export default function Header({isMobileMenuOpen, setIsMobileMenuOpen, currentUser}) {
  const [isHidden, setIsHidden] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsHidden(window.innerWidth >= 1024 ? true : false);
    };

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Check initial screen width
    handleResize();

    // Clean up the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div className='w-full h-16 bg-white flex shadow-md z-10 shadow-gray fixed top-0 items-center'>
    <Menu className='ml-8' style={{ display: isHidden ? "none" : "block" }} onClick={() => {console.log('isMobileMenuOpen',isMobileMenuOpen);setIsMobileMenuOpen(!isMobileMenuOpen)}}/>
    <img src={logo} className='w-8 h-6 ml-4 mr-1' alt='USB Trade'/>
    <span className='font-medium'>UBS Trade</span>
    <div className='hidden lg:flex absolute right-10 items-center w-[10.7%] pl-8 text-xs text-gray-600 py-3'>
    <div className="w-10 h-10 mx-2 rounded-full bg-blue-100 flex items-center justify-center text-base">{currentUser?.FirstName?.substring(0,1)}{currentUser?.LastName?.substring(0,1)}</div>
    {currentUser?.FirstName}
        </div>
    </div>
  );
}
