import React, { useState, useEffect, useRef } from "react";
import Popup from "reactjs-popup";
import { Close } from "@mui/icons-material";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import LoadingTruck from "../../Components/Lottie/LoadingTruck.json";
export default function AddCustomerShippingCo({
  parentComp,
  parentData,
  isOpen,
  data,
  currentUser,
  setIsOpen,
  formatDate,
  notify,
  getData,
}) {
  const [formData, setFormData] = useState({
    [parentComp === "Customer" ? "CustomerId" : "CompanyId"]: data
      ? parentComp === "Customer"
        ? data.CustomerId
        : data.CompanyId
      : null,
    [parentComp === "Customer" ? "CustomerName" : "CompanyName"]: data
      ? parentComp === "Customer"
        ? data.CustomerName
        : data.CompanyName
      : null,
    EmailAddress: data ? data.EmailAddress : null,
    PhoneNumber: data ? data.PhoneNumber : null,
    AddedBy: data ? data.AddedBy : currentUser?.UserId,
    AddedAt: data ? data.AddedAt : formatDate(),
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const root = window.location.href.includes("localhost")
    ? "http://localhost:8451"
    : "https://api.ubstrade.com";
  var url = parentComp === "Customer" ? "customers" : "shippingCompanies";
  const handleSubmit = async (e) => {
    let isFound = false;
    if(parentComp === "Customer"){
      if(data){
        isFound = parentData?.find((p) => {
          return p?.CustomerId !== data?.CustomerId 
          && p?.CustomerName?.toLowerCase().trim() === formData?.CustomerName?.toLowerCase().trim();
        });
      }else{
        isFound = parentData?.find((p) => {
          return p?.CustomerName?.toLowerCase().trim() === formData?.CustomerName?.toLowerCase().trim();
        });
      }
      
    }else{
      if(data){
        isFound = parentData?.find((p) => {
          return p?.CompanyId !== data?.CompanyId 
          && p?.CompanyName?.toLowerCase().trim() === formData?.CompanyName?.toLowerCase().trim();
        });
      }else{
        isFound = parentData?.find((p) => {
          return p?.CompanyName?.toLowerCase().trim() === formData?.CompanyName?.toLowerCase().trim();
        });
      }
    }
    if(isFound){
      notify(`This ${parentComp === "Customer" ? 'Customer' : 'Shipping Company'} already exists`,3);
    }
    else{
       setIsLoading(true);
     axios
       .post(`${root}/${url}/add`, formData)
       .then((response) => {
         getData();
         setIsLoading(false);
         if (parentComp === "Customer") {
           if(data){
            notify("Updated customer successfully", 1);
           }else{
            notify("Added customer successfully", 1);
           }
           navigate("/customers");
         } else {
           if(data){
            notify("Updated shipping company successfully", 1);
           }else{
            notify("Added shipping company successfully", 1);
           }
           navigate("/shipping-companies");
       }
         setIsOpen(false);
       })
       .catch((error) => {
         if (parentComp === "Customer") {
           notify("Error adding customer", 2);
         } else {
           notify("Error adding shipping company", 2);
         }
         console.log(error);
         setIsLoading(false);
       });
    }
    
  };
  const MyLottieAnimation = () => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: LoadingTruck,
    };

    return <Lottie options={defaultOptions} />;
  };

  const popupRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        popupRef.current &&
        !event.target.matches("input") &&
        !event.target.closest('.react-dropdown-select') &&
        !event.target.closest('#submit') &&
        !event.target.closest('#popup')
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, setIsOpen, popupRef]);

  return (
    <div
      style={{ display: isOpen ? "inline-block" : "none" }}
      className="popup-overlay bg-[#1F293A30]">
      <Popup ref={popupRef} modal open={isOpen}>
        {(close) => (
          <form id="popup" onSubmit={handleSubmit} className="relative p-5 w-full lg:w-[25vw] bg-white flex flex-col border-2 border-blue-100 rounded-lg">
            <Close
              disabled={isLoading}
              className="absolute right-0 top-1 mr-4 mt-2 hover:cursor-pointer"
              onClick={() => setIsOpen(false)}
            />
            <span className="mt-4">
              {parentComp === "Customer"
                ? "Customer Name"
                : "Shipping Company Name"}
                {" "}
                <span className="text-red-500">*</span>
            </span>
            <input
              type="text"
              required
              placeholder={
                parentComp === "Customer"
                  ? "Customer Name"
                  : "Shipping Company Name"
              }
              defaultValue={
                data
                  ? parentComp === "Customer"
                    ? data.CustomerName
                    : data.CompanyName
                  : null
              }
              className="w-full border-2 border-gray-300 p-2 my-4 rounded-lg focus:outline-none focus:border-blue-600"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  [parentComp === "Customer" ? "CustomerName" : "CompanyName"]:
                    e.target.value,
                })
              }
            />
            <span className="w-full">
              {parentComp === "Customer"
                ? "Customer Email"
                : "Shipping Company Email"}
                {" "}
                <span className="text-red-500">*</span>
            </span>
            <input
              type="text"
              required
              defaultValue={
                data
                  ? parentComp === "Customer"
                    ? data.EmailAddress
                    : data.EmailAddress
                  : null
              }
              placeholder={
                parentComp === "Customer"
                  ? "Customer Email"
                  : "Shipping Company Email"
              }
              className="w-full border-2 border-gray-300 p-2 my-4 rounded-lg focus:outline-none focus:border-blue-600"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  EmailAddress: e.target.value,
                })
              }
            />
            <span className="w-full">
              {parentComp === "Customer"
                ? "Customer Phone Number"
                : "Shipping Company Phone Number"}
                {" "}<span className="text-red-500">*</span>
            </span>
            <input
              type="text"
              required
              defaultValue={
                data
                  ? parentComp === "Customer"
                    ? data.PhoneNumber
                    : data.PhoneNumber
                  : null
              }
              placeholder={
                parentComp === "Customer"
                  ? "Customer Phone Number"
                  : "Shipping Company Phone Number"
              }
              className="w-full border-2 border-gray-300 p-2 my-4 rounded-lg focus:outline-none focus:border-blue-600"
              onChange={(e) =>
                setFormData({
                  ...formData,
                  PhoneNumber: e.target.value,
                })
              }
            />
            <button
              type="submit"
              id="submit"
              disabled={isLoading}
              className="place-self-end w-14 rounded-2xl right-0 flex text-white bg-blue-600 p-2 items-center justify-center shadow-md hover:bg-blue-900 transition duration-200 ease-in">
              {isLoading ? <MyLottieAnimation /> : <span>Add</span>}
            </button>
          </form>
        )}
      </Popup>
    </div>
  );
}
