import React from "react";
import { useNavigate } from "react-router-dom";
import { Logout, Close } from "@mui/icons-material";
import Cookies from "js-cookie";
import axios from "axios";

export default function Navbar({
  setIsMobileMenuOpen,
  activePage,
  setCurrentUser,
  currentUser,
  root,
  notify,
}) {
  const navigate = useNavigate();
  function navigateToPage(page) {
    setIsMobileMenuOpen(false);
    navigate(page);
  }

  function LogoutFct() {
    var formData ={
      UserId: currentUser?.UserId,
    }
    axios
      .post(`${root}/auth/logout`,
        formData
      )
      .then((response) => {
        Cookies.remove("token");
        setCurrentUser(null);
        navigate("/login");
      })
      .catch((error) => {
        notify("Error logging you out", 2);
        console.log(error);
      });
  }

  return (
    <div className="absolute z-20 h-[100vh] scroll-smooth bg-black bg-opacity-90 block py-5 lg:hidden w-[55%] text-base border-r-[1px] border-r-gray-200">
      <Close
        className="ml-8 text-white"
        onClick={() => setIsMobileMenuOpen(false)}
      />
      <ul className="max-h-[75vh] overflow-y-auto">
        <li
          onClick={() => navigateToPage("/products")}
          className="flex flex-col text-[14px] text-gray-600">
          <span
            className={`${
              activePage === 1
                ? "text-yellow-400 border-[1px] border-yellow-400 w-[68%] my-4 p-3 mx-8"
                : "text-white py-6 px-8"
            } flex items-center mb-4 text-[14px]`}>
            Products
          </span>
        </li>
        <li
          onClick={() => navigateToPage("/available-products")}
          className="flex flex-col text-[14px] text-gray-600">
          <span
            className={`${
              activePage === 8
                ? "text-yellow-400 border-[1px] border-yellow-400 w-[68%] my-4 p-3 mx-8"
                : "text-white py-6 px-8"
            } flex items-center mb-4 text-[14px]`}>
            Available Products
          </span>
        </li>
        <li
          onClick={() => navigateToPage("/order-history")}
          className={`${
            activePage === 2
              ? "text-yellow-400 border-[1px] border-yellow-400 w-[68%] my-4 p-3 mx-8"
              : "text-white py-6 px-8"
          } flex items-center mb-4 text-[14px]`}>
          Sales Orders
        </li>
        <li
          onClick={() => navigateToPage("/generate-ready-containers")}
          className={`${
            activePage === 3
              ? "text-yellow-400 border-[1px] border-yellow-400 w-[68%] my-4 p-3 mx-8"
              : "text-white py-6 px-8"
          } flex items-center mb-4 text-[14px]`}>
          Ready Containers
        </li>
        <li
          onClick={() => navigateToPage("/ready-containers")}
          className={`${
            activePage === 11
              ? "text-yellow-400 border-[1px] border-yellow-400 w-[68%] my-4 p-3 mx-8"
              : "text-white py-6 px-8"
          } flex items-center mb-4 text-[14px]`}>
          Ready To be Shipped
        </li>
        <li
          onClick={() => navigateToPage("/available-shipment")}
          className={`${
            activePage === 4
              ? "text-yellow-400 border-[1px] border-yellow-400 w-[68%] my-4 p-3 mx-8"
              : "text-white py-6 px-8"
          } flex items-center mb-4 text-[14px]`}>
          Available Shipments
        </li>
        <li
          onClick={() => navigateToPage("/shipment-price")}
          className={`${
            activePage === 5
              ? "text-yellow-400 border-[1px] border-yellow-400 w-[68%] my-4 p-3 mx-8"
              : "text-white py-6 px-8"
          } flex items-center mb-4 text-[14px]`}>
          Shipment Price
        </li>
        <li
          onClick={() => navigateToPage("/destination")}
          className={`${
            activePage === 9
              ? "text-yellow-400 border-[1px] border-yellow-400 w-[68%] my-4 p-3 mx-8"
              : "text-white py-6 px-8"
          } flex items-center mb-4 text-[14px]`}>
          Destinations
        </li>
        <li
          onClick={() => navigateToPage("/customers")}
          className={`${
            activePage === 6
              ? "text-yellow-400 border-[1px] border-yellow-400 w-[68%] my-4 p-3 mx-8"
              : "text-white py-6 px-8"
          } flex items-center mb-4 text-[14px]`}>
          Customers
        </li>
        <li
          onClick={() => navigateToPage("/shipping-companies")}
          className={`${
            activePage === 7
              ? "text-yellow-400 border-[1px] border-yellow-400 w-[68%] my-4 p-3 mx-8"
              : "text-white py-6 px-8"
          } flex items-center mb-4 text-[14px]`}>
          Shipping Companies
        </li>
        <li
          onClick={() => navigateToPage("/users")}
          className={`${
            activePage === 10
              ? "text-yellow-400 border-[1px] border-yellow-400 w-[68%] my-4 p-3 mx-8"
              : "text-white py-6 px-8"
          } flex items-center mb-4 text-[14px]`}>
          Users
        </li>
      </ul>
      <div className="max-h-[calc(70vh-100px)] overflow-y-auto">
        <button onClick={() => LogoutFct()}>
          <div className="flex absolute bottom-10 items-center w-[10.7%] pl-8 text-[14px] text-white py-3 hover:cursor-pointer">
            <Logout className="mr-2" />
            Logout
          </div>
        </button>
      </div>
    </div>
  );
}
