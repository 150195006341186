/* eslint-disable eqeqeq */
import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import Lottie from "react-lottie";
import NoDataFound from "../Components/Lottie/NoDataFound.json";

const ReadyContainersList = ({
  products,
  formatDate,
  containers,
  customers,
  shipments,
  orders,
  readyToShip,
  shipmentPrice,
  availableShipments,
  destination,
}) => {
  const perPage = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const offset = currentPage * perPage;
  const [data, setData] = useState(readyToShip);

  useEffect(() => {
    if (readyToShip) {
      setData(readyToShip);
    }
  }, [readyToShip]);
  const MyLottieAnimation = () => {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: NoDataFound,
    };

    return <Lottie options={defaultOptions} />;
  };

  const currentPageData = data?.slice(offset, offset + perPage);
  return (
    <div className="relative flex flex-col h-full">
      <div className="flex relative items-center">
        <h1 className="font-bold mb-6 text-xl">Ready To Ship</h1>
      </div>
      {/* Table */}
      <div className="overflow-x-auto text-[17px]">
        <table className="hidden sm:table w-full bg-white rounded border-collapse shadow-lg">
          <thead className="border-b-2 border-b-gray-200 hidden sm:table-header-group">
            <tr>
              <th
                className="w-1/12 text-gray-500 font-medium p-2"
                style={{ verticalAlign: "middle" }}>
                Validated Order No
              </th>
              <th
                className="w-1/12 text-gray-500 font-medium p-2"
                style={{ verticalAlign: "middle" }}>
                Order No
              </th>
              <th
                className="w-1/12 text-gray-500 font-medium p-2"
                style={{ verticalAlign: "middle" }}>
                Container No
              </th>
              <th
                className="w-1/12 text-gray-500 font-medium p-2"
                style={{ verticalAlign: "middle" }}>
                Customer Name
              </th>
              <th
                className="w-1/12 text-gray-500 font-medium p-2"
                style={{ verticalAlign: "middle" }}>
                Product Name
              </th>
              <th
                className="w-1/12 text-gray-500 font-medium p-2"
                style={{ verticalAlign: "middle" }}>
                Quantity (kg)
              </th>
              <th
                className="w-1/12 text-gray-500 font-medium p-2"
                style={{ verticalAlign: "middle" }}>
                Destination
              </th>
              <th
                className="w-1/12 text-gray-500 font-medium p-2"
                style={{ verticalAlign: "middle" }}>
                Container Type
              </th>
              <th
                className="w-1/12 text-gray-500 font-medium p-2"
                style={{ verticalAlign: "middle" }}>
                Shipment Company
              </th>
              <th
                className="w-1/12 text-gray-500 font-medium p-2"
                style={{ verticalAlign: "middle" }}>
                Shipment Date
              </th>
              <th
                className="w-1/12 text-gray-500 font-medium p-2"
                style={{ verticalAlign: "middle" }}>
                Shipment Price
              </th>
              <th
                className="w-1/12 text-gray-500 font-medium p-2"
                style={{ verticalAlign: "middle" }}>
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {currentPageData?.length > 0 ? (
              currentPageData?.map((readyItem) => (
                <tr key={readyItem.ValidatedOrderId} className="border-b">
                  <td className="w-1/12 py-2 block sm:table-cell pl-[2%]">
                    VO{readyItem?.ValidateOrderNo}
                  </td>
                  <td className="w-1/12 py-2 block sm:table-cell text-center">
                    SO
                    {
                      orders?.find(
                        (order) => order?.OrderId === readyItem?.OrderId
                      )?.OrderNo
                    }
                  </td>
                  <td className="w-1/12 py-2 block sm:table-cell pl-[3%]">
                    {readyItem?.ContainerNb ?? "N/A"}
                  </td>
                  <td className="w-1/12 py-2 block sm:table-cell pl-[1%]">
                    {
                      customers?.find(
                        (c) => c?.CustomerId == readyItem?.CustomerId
                      )?.CustomerName
                    }
                  </td>
                  <td className="w-1/12 flex flex-col py-2 pl-[1%] sm:table-cell">
                    <span>
                      {
                        products?.find(
                          (p) => p?.ProductId === readyItem?.ProductId
                        )?.ProductName
                      }
                    </span>{" "}
                    <span>
                      {
                        products?.find(
                          (p) => p?.ProductId === readyItem?.ProductId
                        )?.ProductNameArabic
                      }
                    </span>
                  </td>
                  <td className="w-1/12 py-2 block pl-[3%] sm:table-cell">
                    {readyItem?.Quantity}
                  </td>
                  <td className="w-1/12 py-2 block pl-[1%] sm:table-cell">
                    <div>
                      {
                        destination?.find(
                          (des) => des?.DestinationId === readyItem?.Destination
                        )?.Country
                      }
                    </div>
                    <div>
                      {
                        destination?.find(
                          (des) => des?.DestinationId === readyItem?.Destination
                        )?.Port
                      }
                    </div>
                  </td>
                  <td className="w-1/12 pl-[2%] py-2 block sm:table-cell">
                    {
                      containers?.find(
                        (price) =>
                          price?.ContainerTypeId === readyItem?.ContainerTypeId
                      )
                      ? containers?.find(
                          (price) =>
                            price?.ContainerTypeId ===
                            readyItem?.ContainerTypeId
                        )?.ContainerTypeName
                      : "N/A" 
                    }
                  </td>
                  <td className="w-1/12 py-2 block pl-[1.5%] sm:table-cell">
                    {(() => {
                      const av = availableShipments?.find(
                        (sh) => sh?.ShipmentsAvId === readyItem?.ShipmentId
                      )?.CompanyId;
                      const company = shipments?.find(
                        (ship) => ship?.CompanyId === av
                      );
                      return company?.CompanyName;
                    })()}
                  </td>
                  <td className="w-1/12 py-2 block sm:table-cell">
                    {formatDate(
                      availableShipments?.find(
                        (sh) => sh?.ShipmentsAvId === readyItem?.ShipmentId
                      )?.ScheduleDate
                    )}
                  </td>
                  <td className="w-1/12 py-2 block pl-[2.5%] sm:table-cell">
                    {
                      shipmentPrice?.find(
                        (sh) => sh?.ShipmentId === readyItem?.ShipPriceId
                      )?.Price
                    }
                  </td>

                  <td className="w-1/12 py-2 pr-[0.5%] block sm:table-cell">
                    {readyItem?.StatusId === 1 ? (
                      <div className="flex w-[5.5rem] text-green-700 bg-green-100 rounded-full items-center justify-center">
                        <div className="bg-green-500 rounded-full w-2 h-2"></div>{" "}
                        <span className="ml-1.5">Active</span>
                      </div>
                    ) : (
                      <div className="flex w-[5.5rem] text-red-700 bg-red-100 rounded-full items-center justify-center">
                        <div className="bg-red-500 rounded-full w-2 h-2"></div>{" "}
                        <span className="ml-1.5 text-center">Inactive</span>
                      </div>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={12} className="text-center py-4">
                  <div className="h-28 pb-1">
                    <MyLottieAnimation />
                  </div>
                  <span>No ready containers</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <table className="sm:hidden w-full bg-white rounded border-collapse shadow-lg">
          <tbody>
            {currentPageData?.length > 0 ? (
              currentPageData?.map((readyItem) => (
                <tr key={readyItem.ValidatedOrderId} className="border-b-[3px] border-slate-300">
                  <tr className="border-b-2 border-dashed">
                    <td className="w-1/2 py-2 font-bold pl-4  text-blue-500">
                      {" "}
                      Validated Order No
                    </td>
                    <td>VO{readyItem?.ValidateOrderNo}</td>
                  </tr>
                  <tr className="border-b-2 border-dashed">
                    <td className="w-1/2 py-2 font-bold pl-4 "> Sale Order No</td>
                    <td>
                      SO
                      {
                        orders?.find(
                          (order) => order?.OrderId === readyItem?.OrderId
                        )?.OrderNo
                      }
                    </td>
                  </tr>
                  <tr className="border-b-2 border-dashed">
                    <td className="w-1/2 py-2 font-bold pl-4 "> Container No</td>
                    <td>
                      <td>{readyItem?.ContainerNb ?? "N/A"}</td>
                    </td>
                  </tr>
                  <tr className="border-b-2 border-dashed">
                    <td className="w-1/2 py-2 font-bold pl-4 "> Customer</td>
                    <td>
                      {
                        customers?.find(
                          (c) => c?.CustomerId == readyItem?.CustomerId
                        )?.CustomerName
                      }
                    </td>
                  </tr>
                  <tr className="border-b-2 border-dashed">
                    <td className="w-1/2 py-2 font-bold pl-4 "> Product</td>
                    <td>
                      <span>
                        {
                          products?.find(
                            (p) => p?.ProductId === readyItem?.ProductId
                          )?.ProductName
                        }
                      </span>{" "}
                      <span>
                        {
                          products?.find(
                            (p) => p?.ProductId === readyItem?.ProductId
                          )?.ProductNameArabic
                        }
                      </span>
                    </td>
                  </tr>
                  <tr className="border-b-2 border-dashed">
                    <td className="w-1/2 py-2 font-bold pl-4 "> Quantity</td>
                    <td>{readyItem?.Quantity}</td>
                  </tr>
                  <tr className="border-b-2 border-dashed">
                    <td className="w-1/2 py-2 font-bold pl-4 "> Destination</td>
                    <td>
                      <div>
                        {
                          destination?.find(
                            (des) =>
                              des?.DestinationId === readyItem?.Destination
                          )?.Country
                        }
                      </div>
                      <div>
                        {
                          destination?.find(
                            (des) =>
                              des?.DestinationId === readyItem?.Destination
                          )?.Port
                        }
                      </div>
                    </td>
                  </tr>
                  <tr className="border-b-2 border-dashed">
                    <td className="w-1/2 py-2 font-bold pl-4 "> Container Type </td>
                    <td>
                      {
                        containers?.find(
                          (c) =>
                            c?.ContainerTypeId ===
                            readyItem?.ContainerTypeId
                        )
                        ? containers?.find(
                          (c) =>
                            c?.ContainerTypeId ===
                            readyItem?.ContainerTypeId
                        )?.ContainerTypeName 
                        : "N/A"
                      }
                    </td>
                  </tr>
                  <tr className="border-b-2 border-dashed">
                    <td className="w-1/2 py-2 font-bold pl-4 ">
                      {" "}
                      Shipment Company{" "}
                    </td>
                    <td>
                      {(() => {
                        const av = availableShipments?.find(
                          (sh) => sh?.ShipmentsAvId === readyItem?.ShipmentId
                        )?.CompanyId;
                        const company = shipments?.find(
                          (ship) => ship?.CompanyId === av
                        );
                        return company?.CompanyName;
                      })()}
                    </td>
                  </tr>
                  <tr className="border-b-2 border-dashed">
                    <td className="w-1/2 py-2 font-bold pl-4 "> Shipment Date</td>
                    <td>
                      {formatDate(
                        availableShipments?.find(
                          (sh) => sh?.ShipmentsAvId === readyItem?.ShipmentId
                        )?.ScheduleDate
                      )}
                    </td>
                  </tr>
                  <tr className="border-b-2 border-dashed">
                    <td className="w-1/2 py-2 font-bold pl-4 "> Shipment Price</td>
                    <td>
                      {
                        shipmentPrice?.find(
                          (sh) => sh?.ShipmentId === readyItem?.ShipPriceId
                        )?.Price
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="w-1/2 py-2 font-bold pl-4 "> Status</td>
                    <td>
                      {readyItem?.StatusId === 1 ? (
                        <div className="flex w-[5.5rem] text-green-700 bg-green-100 rounded-full items-center justify-center">
                          <div className="bg-green-500 rounded-full w-2 h-2"></div>{" "}
                          <span className="ml-1.5">Active</span>
                        </div>
                      ) : (
                        <div className="flex w-[5.5rem] text-red-700 bg-red-100 rounded-full items-center justify-center">
                          <div className="bg-red-500 rounded-full w-2 h-2"></div>{" "}
                          <span className="ml-1.5 text-center">Inactive</span>
                        </div>
                      )}
                    </td>
                  </tr>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={12} className="text-center py-4">
                  <div className="h-28 pb-1">
                    <MyLottieAnimation />
                  </div>
                  <span>No ready containers</span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {/* Pagination component */}
      <div className="flex justify-center flex-row">
        <ReactPaginate
          previousLabel={<ArrowBack className="p-1 -mt-1" />}
          nextLabel={<ArrowForward className="p-1 -mt-1" />}
          breakLabel={"..."}
          pageCount={Math.ceil(data?.length / perPage)}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName="flex flex-row my-6"
          activeLinkClassName="!bg-white text-blue-500 w-8 h-[1.7rem] flex justify-center items-center rounded-full p-2 mx-1 cursor-pointer"
          pageClassName={
            "bg-gray-500 text-white w-6 h-6 flex justify-center items-center rounded-full p-2 mx-1 cursor-pointer"
          }
          previousClassName={
            "bg-blue-500 text-white rounded-full w-6 h-6 flex justify-center items-center p-2 mx-1 cursor-pointer"
          }
          nextClassName={
            "bg-blue-500 text-white rounded-full w-6 h-6 flex justify-center items-center p-2 mx-1 cursor-pointer"
          }
          //breakClassName={'bg-gray-500 text-white rounded-full w-6 h-6 flex justify-center items-center p-2 mx-1 cursor-pointer'}
          disabledClassName={"opacity-50 cursor-not-allowed"}
        />
      </div>
    </div>
  );
};

export default ReadyContainersList;
