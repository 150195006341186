import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import CountryList from "country-list-with-dial-code-and-flag";
import CountryFlagSvg from "country-list-with-dial-code-and-flag/dist/flag-svg";

import { ArrowBack, ArrowForward, Edit, CheckCircle,
    CancelRounded, } from "@mui/icons-material";
import Lottie from "react-lottie";
import NoDataFound from "../Components/Lottie/NoDataFound.json";
const AllDestinations = ({ getDestination, notify, destination, currentUser }) => {
  const perPage = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const offset = currentPage * perPage;
  const [data, setData] = useState(destination);
  var countriesList = CountryList.getAll();

  useEffect(() => {
    setData(destination);
  }, [destination]);

  const MyLottieAnimation = () => {
    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData: NoDataFound,
    };

    return <Lottie options={defaultOptions} />;
  };

  const FlagSvg = ({ svgCode }) => {
    return (
      <div>
        <div className="w-6 mr-2" dangerouslySetInnerHTML={{ __html: svgCode }} />
      </div>
    );
  };

  const currentPageData = data?.slice(offset, offset + perPage);
  return (
    <div className="relative flex flex-col h-full">
      <div className="flex flex-col sm:flex-row relative">
        <h1 className="font-bold sm:mb-8 text-xl">Destinations</h1>
        {
          currentUser?.RoleId === 1 && (
            <Link to="/add-destination" state={{ destination: null }}>
              <button className="my-2 sm:my-0 max-w-[7rem] sm:absolute rounded-2xl right-0 top-0 flex text-base text-white bg-blue-600 p-2 items-center justify-center shadow-md hover:text-blue-600 hover:bg-white transition duration-200 ease-in">
                <span>+ Destination</span>
              </button>
            </Link>
          )
        }
        
      </div>
      {/* Table */}
      <div className="overflow-x-auto text-[17px]">
        <table className="w-full bg-white rounded border-collapse shadow-lg">
          <thead className="border-b-2 border-b-gray-200">
            <tr>
              <th
                className=" text-gray-500 font-medium p-2"
                style={{ verticalAlign: "middle" }}>
                Country
              </th>
              <th
                className=" text-gray-500 font-medium p-2"
                style={{ verticalAlign: "middle" }}>
                Port
              </th>
              <th
                className=" text-gray-500 font-medium p-2"
                style={{ verticalAlign: "middle" }}>
                Status
              </th>
              {
                currentUser?.RoleId === 1 && (
                  <th className="p-2"></th>
                )
              }
              
            </tr>
          </thead>
          <tbody>
            {currentPageData?.length > 0 ? (
              currentPageData?.map((dest) => (
                <tr key={dest.ShipmentId}>
                  <td className="py-2 pl-[3%] sm:pl-[8%] md:pl-[9.8%]">
                    <div className="flex flex-col sm:flex-row items-center">
                    <FlagSvg
                      svgCode={
                        CountryFlagSvg[
                          countriesList?.find(
                            (c) =>
                              c?.name?.toLowerCase() ===
                              dest?.Country?.toLowerCase()
                          )?.code
                        ]
                      }
                    />
                    {dest?.Country}
                    </div>
                  </td>
                  <td className="py-2 pl-[12%] sm:pl-[14%] md:pl-[15%] xl:pl-[16%] 2xl:pl-[17.4%]">{dest?.Port}</td>
                  <td className="py-2 pl-[1%]">{dest?.StatusId === 1 ? (
                <div className="flex items-center py-1 justify-center">
                  <CheckCircle className="text-green-500" /> <span className="ml-1.5 text-center">Active</span>
                </div>
              ) : (
                <div className="flex items-center py-1 pl-2 justify-center">
                  <CancelRounded className="text-red-500" /> <span className="ml-1.5 text-center">Inactive</span>
                </div>
              )}</td>
                  {currentUser?.RoleId === 1 && (
                    <td className="text-left py-2 pl-[4%] text-blue-400 text-xs hover:cursor-pointer hover:text-blue-700">
                    <Link
                      to="/add-destination"
                      state={{ destination: dest }}>
                      <Edit className="h-4 w-4" /> Edit
                    </Link>
                  </td>
                  )}
                  
                </tr>
              ))
            ) : (
              <td colSpan={5} className="text-center py-4">
                <div className="h-28 pb-1">
                  <MyLottieAnimation />
                </div>
                <span>No Destinations found</span>
              </td>
            )}
          </tbody>
        </table>
      </div>
      {/* Pagination component */}
      <div className="flex justify-center flex-row">
        <ReactPaginate
          previousLabel={<ArrowBack className="p-1 -mt-1" />}
          nextLabel={<ArrowForward className="p-1 -mt-1" />}
          breakLabel={"..."}
          pageCount={Math.ceil(data?.length / perPage)}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName="flex flex-row my-6"
          activeLinkClassName="!bg-white text-blue-500 w-8 h-[1.7rem] flex justify-center items-center rounded-full p-2 mx-1 cursor-pointer"
          pageClassName={
            "bg-gray-500 text-white w-6 h-6 flex justify-center items-center rounded-full p-2 mx-1 cursor-pointer"
          }
          previousClassName={
            "bg-blue-500 text-white rounded-full w-6 h-6 flex justify-center items-center p-2 mx-1 cursor-pointer"
          }
          nextClassName={
            "bg-blue-500 text-white rounded-full w-6 h-6 flex justify-center items-center p-2 mx-1 cursor-pointer"
          }
          //breakClassName={'bg-gray-500 text-white rounded-full w-6 h-6 flex justify-center items-center p-2 mx-1 cursor-pointer'}
          disabledClassName={"opacity-50 cursor-not-allowed"}
        />
      </div>
    </div>
  );
};

export default AllDestinations;
